import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { ClientsModel } from '../models/client.model';
import { Guid } from 'guid-typescript';
import { StaffClientModel } from 'src/app/common/models/staffclient.model';
import { StaffModel } from 'src/app/staff/model/staff.model';
import { ActivityService } from 'src/app/services/activity.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/Client';
    staffclienUrl: string = environment.apiUrl + '/StaffClient';
    referralUrl: string = environment.apiUrl + '/Referalls';

    constructor(
        httpClient: HttpClient,
        private messageService: MessageService,
        private activityService: ActivityService
    ) {
        this.http = httpClient;
    }

    getAllClients(): Observable<HttpResponse<ClientsModel[]>> {
        const url = this.clientUrl + '/GetAll';
        return this.http.get<ClientsModel[]>(url, {
            observe: 'response',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting all clients',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public uploadReferralAttachment(formData: FormData): Observable<any> {
        const url = this.referralUrl + '/Upload';
        return this.http.post<any>(url, formData).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error uploading referral attachment',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public getReferralAttachment(reportId: any): Observable<any> {
        const url = this.referralUrl + `/GetAttachment?reportId=${reportId}`;
        return this.http.post<FormData>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting referral attachment',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    // public uploadAvatarAttachment(formData: any, clientId: any): Observable<any> {
    //     const url = this.referralUrl + `/Upload/?id=${clientId}`;
    //     let options = {
    //         headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    //     };
    //     return this.http.post<any>(url, formData).pipe(
    //         catchError((error) => {
    //             this.activityService.logError({
    //                 message: error.error?.message || error.message || 'Error uploading avatar',
    //                 stack: error.error?.stackTrace || error.stack,
    //                 status: error.status,
    //                 statusText: error.statusText,
    //                 url,
    //                 method: 'POST',
    //                 page: 'ClientService'
    //             });
    //             throw error;
    //         })
    //     );
    // }

    public deleteReferralAttachment(reportId: any): Observable<any> {
        const url = this.referralUrl + `/DeleteClientReport?reportId=${reportId}`;
        return this.http.post<any>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deleting referral attachment',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public getAvatar(clientId: any): Observable<any> {
        const url = this.referralUrl + `/GetReferralAttachment?id=${clientId}`;
        return this.http.post<FormData>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting avatar',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public AddClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        const url = this.clientUrl + '/AddClient';
        return this.http.post<ClientsModel[]>(url, ClientsModel, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error adding client',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    assignStaffToClient(staffClient: StaffClientModel): Observable<any> {
        const url = this.staffclienUrl + '/AddStaffClient';
        return this.http.post<ClientsModel>(url, staffClient, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error assigning staff to client',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    getAllStaffByClientId(id: any): Observable<HttpResponse<StaffModel[]>> {
        const url = this.staffclienUrl + '/GetStaffClientById?id=' + id;
        return this.http.get<StaffModel[]>(url, {
            observe: 'response',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting staff by client ID',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    getAllClientByStaffId(id: any): Observable<HttpResponse<ClientsModel[]>> {
        const url = this.staffclienUrl + '/GetClientStaffById?id=' + id;
        return this.http.get<ClientsModel[]>(url, {
            observe: 'response',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting clients by staff ID',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    deleteStaffFromClient(model: StaffClientModel): Observable<StaffModel[]> {
        const url = this.staffclienUrl + '/DeleteStaffClient';
        return this.http.post<StaffModel[]>(url, model, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deleting staff from client',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public updateClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        const url = this.clientUrl + '/UpdateClient?id=' + ClientsModel.clientId;
        return this.http.post<ClientsModel[]>(url, ClientsModel, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error updating client',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public inviteClientToPortal(model: ClientsModel): Observable<ClientsModel> {
        const url = this.clientUrl + '/InviteClientToPortal';
        return this.http.post<ClientsModel>(url, model, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error inviting client to portal',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    public deactivateClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        const url = `${this.clientUrl}/DeactivateClient?id=${ClientsModel.clientId}`;
        return this.http.delete<ClientsModel[]>(url, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deactivating client',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'DELETE',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    getClientById(id: any): Observable<ClientsModel> {
        const url = `${this.clientUrl}/GetClient?id=${id}`;
        return this.http.get<ClientsModel>(url).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || `Error getting client by ID: ${id}`,
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    convertSFDTToMarkdown(content: string): Observable<string> {
        const url = `${environment.apiUrl}/SituationalAssessment/ExportAsMarkdown`;
        return this.http.post<string>(url, { Content: content }, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error converting SFDT to Markdown',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }

    saveDocumentContent(content: string, reportId: string): Observable<any> {
        const url = `${environment.apiUrl}/SituationalAssessment/SaveDocument`;
        return this.http.post(url, {
            reportId: reportId,
            content: content,
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error saving document content',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ClientService'
                });
                throw error;
            })
        );
    }
}
