import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Observable, Observer, Subject, take } from 'rxjs';
import { CustomValidators } from 'src/app/helpers/customvalidators';
import { VendorModel } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { CommonModule } from '@angular/common';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { MaskitoDirective } from '@maskito/angular';

@Component({
    selector: 'app-assessment-company-info',
    template: `
        <div class="company-form-container">
            <nz-page-header class="site-page-header" [nzTitle]="title">
            </nz-page-header>

            <nz-layout class="form-layout">
                <nz-content>
                    <div [ngClass]="{'mobile-view': isMobileView}" class="content-container">
                        <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Company Name</nz-form-label>
                                <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="companyErrorTpl">
                                    <input nz-input formControlName="companyName" placeholder="Enter company name" />
                                    <ng-template #companyErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">Company name is required</ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Contact Name</nz-form-label>
                                <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="contactErrorTpl">
                                    <input nz-input formControlName="contactName" placeholder="Enter contact name" />
                                    <ng-template #contactErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">Contact name is required</ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Phone</nz-form-label>
                                <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="phoneErrorTpl">
                                    <input
                                        nz-input
                                        formControlName="phone"
                                        placeholder="Enter phone number"
                                        [maskito]="phoneMask"
                                        [maskitoElement]="elementPredicate" />
                                    <ng-template #phoneErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">Phone number is required</ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Email</nz-form-label>
                                <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="emailErrorTpl">
                                    <input nz-input formControlName="email" placeholder="Enter email address" />
                                    <ng-template #emailErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">Email is required</ng-container>
                                        <ng-container *ngIf="control.hasError('email')">Please enter a valid email address</ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <div class="form-actions" [ngClass]="{'mobile-actions': isMobileView}">
                                <button nz-button (click)="resetForm($event)">Reset</button>
                                <button nz-button nzType="primary" type="submit" [disabled]="!validateForm.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </nz-content>
            </nz-layout>
        </div>
    `,
    styles: [`
        .company-form-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .form-layout {
            height: 100%;
            background: transparent;
        }

        .content-container {
            padding: 24px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .mobile-view {
            padding: 16px;
        }

        .form-actions {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            margin-top: 24px;
        }

        .mobile-actions {
            flex-direction: column;
            gap: 12px;

            button {
                width: 100%;
            }
        }

        :host ::ng-deep {
            .ant-form-item {
                margin-bottom: 24px;
            }

            @media (max-width: 1366.98px) {
                .ant-form-item {
                    margin-bottom: 16px;
                }

                .ant-form-item-label {
                    padding-bottom: 4px;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzGridModule,
        NzPageHeaderModule,
        NzLayoutModule,
        MaskitoDirective
    ]
})
export class AssessmentCompanyFormComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    isMobileView = false;

    readonly phoneMask: MaskitoOptions = {
        mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    };

    readonly elementPredicate: MaskitoElementPredicate = (el) => el as HTMLInputElement;

    constructor(private service: AssessmentService) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
        this.checkScreenSize();
        window.addEventListener('resize', () => this.checkScreenSize());
    }

    private checkScreenSize() {
        this.isMobileView = window.innerWidth <= 1366;
    }

    ngOnInit(): void {
        this.title = 'Company Information';
        this.validateForm = new FormGroup({
            companyName: new FormControl('', Validators.required),
            contactName: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required),
            email: new FormControl('', [Validators.required, Validators.email])
        });

        if (this.model?.companyInformationModel) {
            this.patchFormValues();
        }
    }

    patchFormValues() {
        this.validateForm.patchValue({
            companyName: this.model.companyInformationModel.companyName,
            contactName: this.model.companyInformationModel.contactName,
            phone: this.model.companyInformationModel.primaryPhone,
            email: this.model.companyInformationModel.email
        });
        this.validateForm.updateValueAndValidity();
    }

    submitForm(): void {
        if (this.validateForm.valid) {
            this.onAddClientReferral();
        }
    }

    resetForm(e: MouseEvent): void {
        e.preventDefault();
        this.validateForm.reset();
        for (const key in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(key)) {
                this.validateForm.controls[key].markAsPristine();
                this.validateForm.controls[key].updateValueAndValidity();
            }
        }
    }

    onAddClientReferral() {
        this.model = this.validateForm.value;
        this.assessmentOutput.emit({ form: this.model });
    }

    next() {
        this.model.companyInformationModel = this.validateForm.value;
    }
}
