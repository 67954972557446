import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of, from, throwError, tap } from 'rxjs';
import { map, take, catchError, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Forgotpass, Login, Resetpass } from '../models/login';
import { AuthenticatedResponse, StringResponse } from '../models/authenticatedresponse';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { VendorService } from 'src/app/vendorList/services/vendor.service';
import firebase from 'firebase/compat/app';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    private isLoading: boolean = false;
    loadingScreenObserver: Subject<boolean> = new Subject<boolean>();
    private env: any;
    _undefined: undefined;

    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService,
        private vendorService: VendorService
    ) {
        console.log('AccountService constructor');
        // Initialize with null instead of empty string
        this.userSubject = new BehaviorSubject<User | null>(null);
        this.user = this.userSubject.asObservable();

        // Check for existing session on service initialization
        const userData = localStorage.getItem('USER');
        if (userData && userData !== '""') {
            try {
                const user = JSON.parse(userData);
                this.userSubject.next(user);
            } catch (error) {
                console.error('Error parsing user data:', error);
                this.handleInvalidSession();
            }
        }
        this.env = environment;
        this.loadingScreenObserver.subscribe((value) => (this.isLoading = value));
    }

    private handleInvalidSession(): void {
        console.log('🔴 AccountService - handleInvalidSession');
        this.userSubject.next(null);
        localStorage.clear();
        this.router.navigate(['/account/login']);
    }

    public get userValue() {
        return this.userSubject.value;
    }

    isNullUndefined(value): boolean {
        return value === null || value === undefined;
    }

    private loginWithSql(login: Login): Observable<User> {
        return this.http
            .post<AuthenticatedResponse>(`${environment.apiUrl}/auth/login`, {
                email: login.email,
                password: login.password,
                isClientPortal: false,
            })
            .pipe(
                map((response: AuthenticatedResponse) => {
                    const user = new User();
                    user.id = response?.staffId || response?.clientId || '';
                    user.email = response?.email || '';
                    user.firstName = response?.firstName || '';
                    user.lastName = response?.lastName || '';
                    user.role = response?.role || '';
                    user.accessToken = response?.accessToken || '';
                    user.refreshToken = response?.refreshToken || '';
                    user.lastLogin = response?.lastLogin || new Date().toISOString();
                    user.firebaseUid = ''; // Empty for SQL auth

                    // Optional fields
                    user.vendorName = response?.vendorName;
                    user.staffId = response?.staffId;
                    user.staffNumber = response?.staffNumber;
                    user.profileImage = response?.profileImage;
                    return user;
                })
            );
    }

    login(login: Login, useSqlAuth: boolean = false): Observable<User> {
        console.log('🟢 AccountService - login use sql auth: ', useSqlAuth);
        return (useSqlAuth ? this.loginWithSql(login) : this.authService.login(login.email, login.password, true))
            .pipe(
                map(user => {
                    localStorage.clear(); // Clear any existing data first
                    this.storeUserData(user);
                    this.userSubject.next(user);
                    return user;
                }),
                catchError(error => {
                    console.error('Login error:', error);
                    this.handleInvalidSession();
                    return throwError(() => error);
                })
            );
    }

    private storeUserData(user: User): void {
        if (!user) return;

        this.authService.storeUserData(user);
    }

    // Profile Management
    updateProfile(profileData: any): Observable<User> {
        return this.http.put<User>(`${environment.apiUrl}/staff/profile`, profileData).pipe(
            tap(updatedUser => {
                const currentUser = this.authService.currentUser;
                if (currentUser) {
                    const updatedUserData = { ...currentUser, ...updatedUser };
                    this.authService.storeUserData(updatedUserData);
                }
            })
        );
    }

    updateProfileImage(imageFile: File): Observable<string> {
        const formData = new FormData();
        formData.append('image', imageFile);

        return this.http.post<{imageUrl: string}>(`${environment.apiUrl}/staff/profile-image`, formData)
            .pipe(
                map(response => response.imageUrl),
                tap(imageUrl => {
                    const currentUser = this.authService.currentUser;
                    if (currentUser) {
                        const updatedUser = { ...currentUser, profileImage: imageUrl };
                        this.authService.storeUserData(updatedUser);
                    }
                })
            );
    }

    // User Preferences
    getUserPreferences(): Observable<any> {
        return this.http.get(`${environment.apiUrl}/staff/preferences`);
    }

    updateUserPreferences(preferences: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/staff/preferences`, preferences);
    }

    // Additional Profile Operations
    updatePassword(currentPassword: string, newPassword: string): Observable<void> {
        return this.http.put<void>(`${environment.apiUrl}/staff/password`, {
            currentPassword,
            newPassword
        });
    }

    updateNotificationSettings(settings: any): Observable<any> {
        return this.http.put(`${environment.apiUrl}/staff/notifications/settings`, settings);
    }

    getProfileDetails(): Observable<User> {
        return this.http.get<User>(`${environment.apiUrl}/staff/profile`);
    }
}
