import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tooltipList',
    standalone: false
})
export class TooltipListPipe implements PipeTransform {
    transform(lines: string[]): string {
        let list: string = '';

        lines.forEach((line) => {
            list += '• ' + line + '\r\n';
        });

        return list;
    }
}
