import { Component, Input, OnInit, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';

@Component({
    selector: 'app-archive-confirmation-modal',
    template: `
        <div class="archive-modal">
            <div class="modal-header">
                <h2>Archive Activity Logs</h2>
            </div>

            <div class="modal-content">
                <p class="warning-text">
                    Warning: This action will permanently archive all activity logs from the following period:
                </p>

                <div class="date-range-info">
                    <p><strong>From:</strong> {{ getFromDate() | date : 'medium' }}</p>
                    <p><strong>To:</strong> {{ getCurrentDate() | date : 'medium' }}</p>
                    <p><strong>Period:</strong> {{ getPeriodDescription() }}</p>
                </div>

                <div class="days-selector">
                    <nz-select
                        [(ngModel)]="selectedDays"
                        class="days-select"
                        [nzDisabled]="isArchiving"
                        (ngModelChange)="onPeriodChange()"
                        nzPlaceHolder="Select period"
                    >
                        <nz-option [nzValue]="3" nzLabel="Past 3 days"></nz-option>
                        <nz-option [nzValue]="7" nzLabel="Past 7 days"></nz-option>
                        <nz-option [nzValue]="30" nzLabel="Past 30 days"></nz-option>
                        <nz-option [nzValue]="90" nzLabel="Past 90 days"></nz-option>
                        <nz-option [nzValue]="180" nzLabel="Past 180 days"></nz-option>
                        <nz-option [nzValue]="365" nzLabel="Past 365 days"></nz-option>
                        <nz-option [nzValue]="0" nzLabel="Archive All"></nz-option>
                    </nz-select>
                </div>

                <div *ngIf="isArchiving" class="archiving-status">
                    <nz-spin nzSimple></nz-spin>
                    <span>Archiving logs, please wait...</span>
                </div>
            </div>

            <div class="modal-footer">
                <button nz-button (click)="onCancel()" [disabled]="isArchiving">Cancel</button>
                <button
                    nz-button
                    nzType="primary"
                    (click)="onConfirm()"
                    [disabled]="isArchiving || selectedDays === undefined || !hasActivityLogs"
                    nzDanger
                >
                    <span>Archive Logs</span>
                </button>
            </div>
        </div>
    `,
    styles: [
        `
            .archive-modal {
                padding: 24px;
            }

            .modal-header {
                margin-bottom: 20px;
            }

            .modal-header h2 {
                margin: 0;
                color: #2c3e50;
            }

            .warning-text {
                color: #ff4444;
                margin-bottom: 20px;
                font-weight: 500;
            }

            .days-selector {
                margin: 20px 0;
            }

            .days-select {
                width: 100%;
            }

            .modal-footer {
                margin-top: 24px;
                display: flex;
                gap: 8px;
                justify-content: flex-end;
            }

            .archiving-status {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-top: 16px;
                color: #1890ff;
            }

            .date-range-info {
                margin: 16px 0;
                padding: 12px;
                background-color: #f6f6f6;
                border-radius: 4px;
            }
            .date-range-info p {
                margin: 4px 0;
            }
        `,
    ],
    standalone: true,
    imports: [CommonModule, FormsModule, NzButtonModule, NzSelectModule, NzIconModule, NzSpinModule],
})
export class ArchiveConfirmationModalComponent implements OnInit {
    @Input() earliestLogDate: Date | null = null;
    @Input() hasActivityLogs: boolean = false;

    selectedDays: number = 30;
    isArchiving: boolean = false;

    constructor(
        private modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) public data: { earliestLogDate: Date | null; hasActivityLogs: boolean }
    ) {}

    ngOnInit() {
        // Initialize from modal data
        this.earliestLogDate = this.data?.earliestLogDate || null;
        this.hasActivityLogs = this.data?.hasActivityLogs || false;
    }

    getCurrentDate(): Date {
        return new Date();
    }

    getFromDate(): Date {
        if (this.selectedDays === 0 && this.earliestLogDate) {
            return new Date(this.earliestLogDate); // Use the actual earliest date
        }

        const now = new Date();
        return new Date(now.getTime() - this.selectedDays * 24 * 60 * 60 * 1000);
    }

    getPeriodDescription(): string {
        if (this.selectedDays === 0) {
            if (this.earliestLogDate) {
                const days = Math.ceil(
                    (new Date().getTime() - this.earliestLogDate.getTime()) / (1000 * 60 * 60 * 24)
                );
                return `All activity logs (${days} days of history)`;
            }
            return 'All activity logs';
        }
        return `Past ${this.selectedDays} days`;
    }

    onPeriodChange(): void {
        this.isArchiving = false;
    }

    onCancel(): void {
        this.modal.close();
    }

    onConfirm(): void {
        this.isArchiving = true;
        this.modal.close({ days: this.selectedDays, isArchiving: true });
    }
}
