import { Injectable } from '@angular/core';

export interface BrowserInfo {
    browser: string;
    version: string;
    os: string;
    device: string;
    screenResolution: string;
    userAgent: string;
}

@Injectable({
    providedIn: 'root'
})
export class BrowserDetectionService {
    getBrowserInfo(): BrowserInfo {
        const ua = navigator.userAgent;
        const browserInfo: BrowserInfo = {
            browser: this.detectBrowser(ua),
            version: this.detectBrowserVersion(ua),
            os: this.detectOS(ua),
            device: this.detectDevice(ua),
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            userAgent: ua
        };
        return browserInfo;
    }

    private detectBrowser(ua: string): string {
        if (ua.includes('Firefox/')) return 'Firefox';
        if (ua.includes('Edg/')) return 'Edge';
        if (ua.includes('Chrome/')) return 'Chrome';
        if (ua.includes('Safari/') && !ua.includes('Chrome/')) return 'Safari';
        if (ua.includes('OPR/') || ua.includes('Opera/')) return 'Opera';
        if (ua.includes('MSIE ') || ua.includes('Trident/')) return 'Internet Explorer';
        return 'Unknown';
    }

    private detectBrowserVersion(ua: string): string {
        const browser = this.detectBrowser(ua);
        let version = 'Unknown';

        switch (browser) {
            case 'Firefox':
                version = ua.match(/Firefox\/([0-9.]+)/)?.[1] || version;
                break;
            case 'Edge':
                version = ua.match(/Edg\/([0-9.]+)/)?.[1] || version;
                break;
            case 'Chrome':
                version = ua.match(/Chrome\/([0-9.]+)/)?.[1] || version;
                break;
            case 'Safari':
                version = ua.match(/Version\/([0-9.]+)/)?.[1] || version;
                break;
            case 'Opera':
                version = ua.match(/OPR\/([0-9.]+)/)?.[1] ||
                         ua.match(/Opera\/([0-9.]+)/)?.[1] || version;
                break;
            case 'Internet Explorer':
                version = ua.match(/MSIE ([0-9.]+)/)?.[1] ||
                         ua.match(/rv:([0-9.]+)/)?.[1] || version;
                break;
        }
        return version;
    }

    private detectOS(ua: string): string {
        if (ua.includes('Windows NT 10.0')) return 'Windows 11/10';
        if (ua.includes('Windows NT 6.3')) return 'Windows 8.1';
        if (ua.includes('Windows NT 6.2')) return 'Windows 8';
        if (ua.includes('Windows NT 6.1')) return 'Windows 7';
        if (ua.includes('Windows NT 6.0')) return 'Windows Vista';
        if (ua.includes('Windows NT 5.1')) return 'Windows XP';
        if (ua.includes('Mac OS X')) return 'macOS';
        if (ua.includes('Linux')) return 'Linux';
        if (ua.includes('Android')) return 'Android';
        if (ua.includes('like Mac OS X')) return 'iOS';
        return 'Unknown';
    }

    private detectDevice(ua: string): string {
        if (ua.includes('Mobile')) {
            if (ua.includes('iPhone')) return 'iPhone';
            if (ua.includes('iPad')) return 'iPad';
            if (ua.includes('Android')) return 'Android Mobile';
            return 'Mobile Device';
        }
        if (ua.includes('Tablet')) return 'Tablet';
        return 'Desktop';
    }
}
