import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageDataOptions } from 'ng-zorro-antd/message';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    // Default options to ensure messages appear on top
    private messageOptions: NzMessageDataOptions = {
        nzDuration: 3000,
        nzAnimate: true,
        nzPauseOnHover: true,
    };

    constructor(private nzMessageService: NzMessageService) {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = `
            .ant-message {
                position: fixed;
                top: 54px;
                left: 0;
                right: 0;
                z-index: 600000 !important;
            }
        `;
        document.head.appendChild(styleElement);
    }

    add(message: string) {
        this.nzMessageService.info(message, this.messageOptions);
    }

    success(message: string) {
        this.nzMessageService.success(message, this.messageOptions);
    }

    error(message: string) {
        this.nzMessageService.error(message, this.messageOptions);
    }

    warning(message: string) {
        this.nzMessageService.warning(message, this.messageOptions);
    }
}
