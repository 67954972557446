import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { AccountService } from '../login/service/account.service';
import { AuthService } from '../login/service/auth.service';
import { AlertService } from '../alerts/alert.service';
import { ActivityService } from '../services/activity.service';
import { BrowserDetectionService } from '../services/browser-detection.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const accountService = inject(AccountService);
  const authService = inject(AuthService);
  const alertService = inject(AlertService);
  const activityService = inject(ActivityService);
  const browserDetection = inject(BrowserDetectionService);
  const router = inject(Router);

  const extractErrorMessage = (error: any): string => {
    if (!error) return 'An unknown error occurred';

    // Handle string errors
    if (typeof error === 'string') return error;

    // Handle error objects
    if (typeof error === 'object') {
      // Check for detailed error response
      if (error.error) {
        if (typeof error.error === 'string') {
          try {
            const parsed = JSON.parse(error.error);
            return parsed.message || parsed.error || error.error;
          } catch {
            return error.error;
          }
        }
        if (typeof error.error === 'object') {
          return error.error.message ||
                 error.error.error ||
                 error.error.detail ||
                 JSON.stringify(error.error);
        }
      }

      // Check common error properties
      if (error.message) return error.message;
      if (error.detail) return error.detail;

      // Handle validation errors
      if (error.errors) {
        if (Array.isArray(error.errors)) {
          return error.errors.join(', ');
        }
        if (typeof error.errors === 'object') {
          return Object.entries(error.errors)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('; ');
        }
      }

      // Last resort: stringify the error
      try {
        return JSON.stringify(error);
      } catch {
        return error.toString();
      }
    }

    return 'An unexpected error occurred';
  };

  const extractStackTrace = (error: any): string => {
    if (!error) return '';

    const traces: string[] = [];

    // Get the main stack trace
    if (error.stack) traces.push(error.stack);
    if (error.error?.stack) traces.push(error.error.stack);
    if (error.error?.stackTrace) traces.push(error.error.stackTrace);

    // Get inner exception stack traces
    if (error.error?.innerException) {
      const inner = error.error.innerException;
      if (inner.stack) traces.push(`Inner Exception: ${inner.stack}`);
      if (inner.stackTrace) traces.push(`Inner Exception: ${inner.stackTrace}`);
    }

    return traces.join('\n');
  };

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      console.log('ErrorInterceptor', err);

      if (!req.url.startsWith(environment.apiUrl)) {
        return throwError(() => err);
      }

      if (req.url.includes('/Token/Refresh')) {
        return throwError(() => err);
      }

      if (err.status === 401) {
        return throwError(() => err);
      }

      if (err.status === 403) {
        // Only show permission error in development
        if (!environment.production) {
          alertService.error('You do not have permission to access this resource');
        }
        return throwError(() => err);
      }

      const errorDetails = {
        message: extractErrorMessage(err),
        status: err.status,
        statusText: err.statusText,
        url: req.url,
        method: req.method,
        timestamp: new Date().toISOString(),
        stackTrace: extractStackTrace(err),
        browser: browserDetection.getBrowserInfo(),
        page: router.url,
        requestBody: req.body,
        requestHeaders: Array.from(req.headers.keys()).reduce((acc, key) => {
          acc[key] = req.headers.getAll(key);
          return acc;
        }, {} as any),
        responseBody: err.error,
        errorType: err.name,
        errorCode: err.error?.code || err.error?.errorCode,
        innerException: err.error?.innerException ? {
          message: extractErrorMessage(err.error.innerException),
          stack: err.error.innerException.stack || err.error.innerException.stackTrace
        } : null
      };

      // Log the error with full details (in both dev and prod)
      activityService.logError({
        message: errorDetails.message,
        stack: errorDetails.stackTrace,
        status: errorDetails.status,
        statusText: errorDetails.statusText,
        url: errorDetails.url,
        method: errorDetails.method,
        browser: errorDetails.browser,
        page: errorDetails.page,
        details: {
          requestBody: errorDetails.requestBody,
          requestHeaders: errorDetails.requestHeaders,
          responseBody: errorDetails.responseBody,
          errorType: errorDetails.errorType,
          errorCode: errorDetails.errorCode,
          innerException: errorDetails.innerException
        }
      });

      // Only show error message popup in development environment
      if (!environment.production) {
        alertService.error(errorDetails.message);
      }

      // Return error with full details for debugging
      return throwError(() => errorDetails);
    })
  );
};
