export enum AppointmentStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Rejected = 'Rejected',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}

// Numeric status values matching C# enum
export enum ApiStatus {
    Pending = 0,
    Accepted = 1,
    Rejected = 2,
    Cancelled = 3,
    Completed = 4
}

export const convertToApiStatus = (status: AppointmentStatus): ApiStatus => {
    switch (status) {
        case AppointmentStatus.Pending:
            return ApiStatus.Pending;
        case AppointmentStatus.Accepted:
            return ApiStatus.Accepted;
        case AppointmentStatus.Rejected:
            return ApiStatus.Rejected;
        case AppointmentStatus.Cancelled:
            return ApiStatus.Cancelled;
        case AppointmentStatus.Completed:
            return ApiStatus.Completed;
        default:
            return ApiStatus.Pending;
    }
};

export const convertFromApiStatus = (status: ApiStatus): AppointmentStatus => {
    switch (status) {
        case ApiStatus.Pending:
            return AppointmentStatus.Pending;
        case ApiStatus.Accepted:
            return AppointmentStatus.Accepted;
        case ApiStatus.Rejected:
            return AppointmentStatus.Rejected;
        case ApiStatus.Cancelled:
            return AppointmentStatus.Cancelled;
        case ApiStatus.Completed:
            return AppointmentStatus.Completed;
        default:
            return AppointmentStatus.Pending;
    }
};

export interface AppointmentAvailabilityModel {
    id?: string;
    staffId: string;
    dayOfWeek: number;
    startTime: string;
    endTime: string;
    isRecurring: boolean;
    specificDate?: string;
    isActive?: boolean;
    blockoutDates?: string[];
    availabilityPattern?: {
        pattern: string;
        interval: number;
        endDate?: string;
        daysOfWeek?: number[];
    };
}

export interface AppointmentNotification {
    message: string;
    id: string;
    appointmentId: string;
    notificationType: string;
    notificationStatus: string;
    sentDate?: Date;
    recipientId: string;
    recipientType: string;
    notificationContent: string;
}

export interface AppointmentRecurrenceModel {
    id?: string;
    parentAppointmentId?: string;
    recurrencePattern?: string;
    recurrenceInterval?: number;
    startDate?: Date;
    endDate?: Date;
    daysOfWeek?: string;
    isActive?: boolean;
}

export interface AppointmentModel {
    id?: string;
    staffId?: string;
    clientId?: string;
    subject?: string;
    startTime?: string;
    endTime?: string;
    priority?: string;
    description?: string;
    location?: string;
    notes?: string;
    booked?: boolean;
    createdDate?: string | null;
    modifiedDate?: string | null;
    appointmentDay?: string;
    categoryColor?: string;
    isNewFlag?: boolean;
    isCalendarAppointment?: boolean | null;
    status?: AppointmentStatus;
    requestNotes?: string;
    staffNotes?: string;
    isRecurring?: boolean;
    recurrencePattern?: AppointmentRecurrenceModel;
    notifications?: AppointmentNotification[];
    lastModifiedBy?: string;
    evaluationResults?: string;
    notificationSent?: boolean;
    recurringAppointmentId?: string;
    cancellationReason?: string;
    rescheduledFromAppointmentId?: string;

    // Helper method to get the numeric status for API calls
    toApiModel?: () => any;
}

export const createDefaultAppointment = (data?: Partial<AppointmentModel>): AppointmentModel => {
    const appointment: AppointmentModel = {
        staffId: '',
        clientId: '',
        subject: '',
        startTime: '',
        endTime: '',
        priority: '',
        description: '',
        location: '',
        notes: '',
        booked: false,
        createdDate: null,
        modifiedDate: null,
        appointmentDay: '',
        categoryColor: '#16586a',
        isNewFlag: true,
        isCalendarAppointment: null,
        status: AppointmentStatus.Pending,
        isRecurring: false,
        notifications: [],
        notificationSent: false,
        ...data,

        // Add toApiModel method
        toApiModel: function() {
            const apiModel = { ...this };
            if (this.status) {
                apiModel.status = convertToApiStatus(this.status);
            }
            delete apiModel.toApiModel; // Remove the function before sending
            return apiModel;
        }
    };

    return appointment;
};

export interface AppointmentAvailabilityResponse {
    isAvailable: boolean;
    conflictReason?: string;
    availableSlots?: {
        startTime: string;
        endTime: string;
        isRecurring?: boolean;
        dayOfWeek?: number;
    }[];
    blockoutDates?: string[];
    nextAvailableSlot?: {
        startTime: string;
        endTime: string;
        dayOfWeek: number;
    };
}

export interface AppointmentAvailabilityRequest {
    staffId: string;
    startTime: string;
    endTime: string;
    appointmentId?: string;  // Optional - used when checking availability for updates
    isRecurring?: boolean;
    recurrencePattern?: {
        pattern: string;
        interval: number;
        endDate?: string;
        daysOfWeek?: number[];
    };
}
