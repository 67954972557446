import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTagModule } from 'ng-zorro-antd/tag';

@Component({
    selector: 'app-location-drawer',
    standalone: true,
    imports: [
        CommonModule,
        NzIconModule,
        NzDrawerModule,
        NzDescriptionsModule,
        NzDividerModule,
        NzTagModule
    ],
    template: `
        <nz-drawer
            [nzVisible]="visible"
            [nzHeight]="'80%'"
            nzPlacement="bottom"
            [nzTitle]="titleTpl"
            (nzOnClose)="onClose()"
            [nzFooter]="footerTpl"
        >
            <ng-template #titleTpl>
                <div class="drawer-title">
                    <span>Location Information</span>
                    <nz-tag [nzColor]="'blue'">
                        <i nz-icon nzType="environment"></i>
                        {{ location?.city || 'Unknown Location' }}
                    </nz-tag>
                </div>
            </ng-template>

            <div *nzDrawerContent class="drawer-content">
                <nz-descriptions [nzColumn]="1" nzBordered>
                    <nz-descriptions-item nzTitle="IP Address">
                        <i nz-icon nzType="laptop"></i>
                        {{ ipAddress || 'Unknown' }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="City">
                        <i nz-icon nzType="environment"></i>
                        {{ location?.city || 'Unknown' }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Region">
                        <i nz-icon nzType="global"></i>
                        {{ location?.region || 'Unknown' }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Country">
                        <i nz-icon nzType="flag"></i>
                        {{ location?.country || 'Unknown' }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="Coordinates">
                        <i nz-icon nzType="compass"></i>
                        {{ location?.coordinates || 'Unknown' }}
                    </nz-descriptions-item>

                    <nz-descriptions-item nzTitle="ISP">
                        <i nz-icon nzType="cloud"></i>
                        {{ location?.isp || 'Unknown' }}
                    </nz-descriptions-item>
                </nz-descriptions>

                <nz-divider></nz-divider>

                <div class="info-section">
                    <h4>Additional Information</h4>
                    <p class="info-text">
                        This location data is collected when specific actions are performed in the system.
                        It helps maintain security and audit trails for important operations.
                    </p>
                </div>
            </div>

            <ng-template #footerTpl>
                <div class="drawer-footer">
                    <button nz-button nzType="primary" (click)="onClose()">
                        <i nz-icon nzType="check"></i>
                        Close
                    </button>
                </div>
            </ng-template>
        </nz-drawer>
    `,
    styles: [`
        .drawer-title {
            display: flex;
            align-items: center;
            gap: 12px;
        }

        .drawer-content {
            padding: 24px;
        }

        nz-descriptions-item {
            i {
                margin-right: 8px;
                color: #1890ff;
            }
        }

        .info-section {
            background: #f8f9fa;
            padding: 16px;
            border-radius: 8px;
            margin-top: 24px;

            h4 {
                color: #1890ff;
                margin-bottom: 12px;
            }

            .info-text {
                color: #595959;
                font-size: 14px;
                line-height: 1.5;
            }
        }

        .drawer-footer {
            text-align: right;
        }

        :host ::ng-deep {
            .ant-descriptions-item-label {
                width: 120px;
            }
        }
    `]
})
export class LocationDrawerComponent {
    @Input() location: any;
    @Input() ipAddress: string;
    @Input() visible: boolean = false;

    onClose(): void {
        this.visible = false;
    }
}