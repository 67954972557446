import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Observable, Observer, Subject, take } from 'rxjs';
import { VendorModel } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule } from '@angular/common';
import { MaskitoElementPredicate, MaskitoOptions } from '@maskito/core';
import { MaskitoDirective } from '@maskito/angular';
var _ = require('lodash');

@Component({
    selector: 'app-assessment-location-info',
    template: `
        <div class="location-form-container">
            <nz-page-header class="site-page-header" [nzTitle]="title">
            </nz-page-header>

            <nz-layout class="form-layout">
                <nz-content>
                    <div [ngClass]="{'mobile-view': isMobileView}" class="content-container">
                        <form nz-form [formGroup]="frmSignup">
                            <div formArrayName="validationForm">
                                <div *ngFor="let ctrl of getForm.controls; let i = index" [formGroupName]="i">
                                    <nz-divider *ngIf="showBreakBar && i > 0"></nz-divider>

                                    <div class="location-header">
                                        <h3>Location {{i + 1}}</h3>
                                        <button *ngIf="getForm.controls.length > 1"
                                                nz-button
                                                nzType="text"
                                                nzDanger
                                                (click)="removeField(i, $event)">
                                            <span nz-icon nzType="delete" nzTheme="outline"></span>
                                        </button>
                                    </div>

                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Address</nz-form-label>
                                        <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="addressErrorTpl">
                                            <input nz-input formControlName="address1" placeholder="Street address" />
                                            <ng-template #addressErrorTpl let-control>
                                                <ng-container *ngIf="control.hasError('required')">Address is required</ng-container>
                                            </ng-template>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="isMobileView ? 24 : 6">Suite/Apt</nz-form-label>
                                        <nz-form-control [nzSpan]="isMobileView ? 24 : 16">
                                            <input nz-input formControlName="address2" placeholder="Apartment, suite, unit, etc." />
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>City</nz-form-label>
                                        <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="cityErrorTpl">
                                            <input nz-input formControlName="city" placeholder="City" />
                                            <ng-template #cityErrorTpl let-control>
                                                <ng-container *ngIf="control.hasError('required')">City is required</ng-container>
                                            </ng-template>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>State</nz-form-label>
                                        <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="stateErrorTpl">
                                            <input nz-input formControlName="state" placeholder="State" />
                                            <ng-template #stateErrorTpl let-control>
                                                <ng-container *ngIf="control.hasError('required')">State is required</ng-container>
                                            </ng-template>
                                        </nz-form-control>
                                    </nz-form-item>

                                    <nz-form-item>
                                        <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>ZIP Code</nz-form-label>
                                        <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="zipErrorTpl">
                                            <input
                                                nz-input
                                                formControlName="zip"
                                                placeholder="ZIP Code"
                                                [maskito]="zipMask"
                                                [maskitoElement]="elementPredicate" />
                                            <ng-template #zipErrorTpl let-control>
                                                <ng-container *ngIf="control.hasError('required')">ZIP Code is required</ng-container>
                                            </ng-template>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>

                            <div class="form-actions" [ngClass]="{'mobile-actions': isMobileView}">
                                <button nz-button (click)="addField($event)">
                                    <span nz-icon nzType="plus" nzTheme="outline"></span>
                                    Add Location
                                </button>
                                <button nz-button nzType="primary" (click)="next()">Save</button>
                            </div>
                        </form>
                    </div>
                </nz-content>
            </nz-layout>
        </div>
    `,
    styles: [`
        .location-form-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .form-layout {
            height: 100%;
            background: transparent;
        }

        .content-container {
            padding: 24px;
            max-width: 1200px;
            margin: 0 auto;
            overflow-y: auto;
            max-height: calc(100vh - 120px);
        }

        .mobile-view {
            padding: 16px;
        }

        .location-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;

            h3 {
                margin: 0;
            }
        }

        .form-actions {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            margin-top: 24px;
            padding-bottom: 24px;
        }

        .mobile-actions {
            flex-direction: column;
            gap: 12px;

            button {
                width: 100%;
            }
        }

        :host ::ng-deep {
            .ant-form-item {
                margin-bottom: 24px;
            }

            .ant-divider {
                margin: 32px 0;
            }

            @media (max-width: 1366.98px) {
                .ant-form-item {
                    margin-bottom: 16px;
                }

                .ant-form-item-label {
                    padding-bottom: 4px;
                }

                .ant-divider {
                    margin: 24px 0;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzGridModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzIconModule,
        NzDividerModule,
        MaskitoDirective
    ]
})
export class AssessmentLocationFormComponent implements OnInit {
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    isMobileView = false;
    showBreakBar: boolean = false;

    readonly zipMask: MaskitoOptions = {
        mask: [/\d/, /\d/, /\d/, /\d/, /\d/]
    };

    readonly elementPredicate: MaskitoElementPredicate = (el) => el as HTMLInputElement;

    frmSignup: FormGroup = this.fb.group({
        validationForm: this.fb.array([]),
    });

    constructor(
        private fb: FormBuilder,
        private service: AssessmentService
    ) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
        const formArry = this.createLocationForm();
        this.frmSignup.setControl('validationForm', formArry);
        this.checkScreenSize();
        window.addEventListener('resize', () => this.checkScreenSize());
    }

    private checkScreenSize() {
        this.isMobileView = window.innerWidth <= 1366;
    }

    ngOnInit(): void {
        this.title = 'Location Information';
        if (this.model) {
            this.patchFormValues();
        }
        if (this.getForm.length > 1) {
            this.showBreakBar = true;
        }
    }

    createLocationForm(): FormArray {
        return new FormArray([
            new FormGroup({
                address1: new FormControl('', Validators.required),
                address2: new FormControl(''),
                city: new FormControl('', Validators.required),
                state: new FormControl('', Validators.required),
                zip: new FormControl('', Validators.required),
            }),
        ]);
    }

    createSignupForm(): FormGroup {
        return this.fb.group({
            address1: ['', Validators.required],
            address2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
        });
    }

    addField(e?: MouseEvent): void {
        if (e) {
            e.preventDefault();
        }
        const formArry = this.createSignupForm();
        let valForm = this.getForm;
        valForm.push(formArry);
        this.frmSignup = this.fb.group({
            validationForm: this.fb.array([]),
        });
        this.frmSignup.setControl('validationForm', valForm);
        this.showBreakBar = true;
    }

    removeField(i: number, e: MouseEvent): void {
        e.preventDefault();
        let valForm = this.getForm;
        if (valForm.length > 1) {
            this.frmSignup = this.fb.group({
                validationForm: this.fb.array([]),
            });
            const index = i;
            valForm.controls.splice(index, 1);
            this.frmSignup.setControl('validationForm', valForm);
            if (valForm.controls.length == 1) {
                this.showBreakBar = false;
            }
        }
    }

    get getForm(): FormArray {
        return this.frmSignup.get('validationForm') as FormArray;
    }

    patchFormValues() {
        let patchForm = this.getForm;
        let valForm = new FormArray([]);
        this.model.locationInformationModels.forEach((location) => {
            patchForm.controls.forEach((f: FormGroup) => {
                Object.keys(f.controls).forEach((control: string) => {
                    try {
                        f.controls[control].setValue(location[control]);
                    } catch (error) {
                        console.log(error);
                    }
                });
                var deep = _.cloneDeep(f);
                valForm.push(deep);
            });
        });
        this.frmSignup = this.fb.group({
            validationForm: this.fb.array([]),
        });
        this.frmSignup.setControl('validationForm', valForm);
        this.frmSignup.updateValueAndValidity();
    }

    next() {
        this.model.locationInformationModels = this.getForm.controls.map((x) => x.value);
    }
}
