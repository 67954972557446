import {
    AfterViewInit,
    Compiler,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { enableRipple } from '@syncfusion/ej2-base';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Subscription, fromEvent, merge, Subject, takeUntil } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccountService } from './login/service/account.service';
import { AuthService } from './login/service/auth.service';
import { IdleTimerService } from './common/idle-timer.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ClientService } from './client/services/client.services';
import { OverlayService } from './shared/services/overlay.service';
import { environment } from 'src/environments/environment';

enableRipple(true);
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [ClientService],
    standalone: false
})
export class AppComponent implements OnDestroy, AfterViewInit, OnInit {
    [x: string]: any;
    title = 'Cornerstone';
    showHead: boolean = false;
    private _idleSubscription: Subscription | null = null;
    private _activitySubscription: Subscription | null = null;
    private _timeoutSubscription: Subscription | null = null;
    private _overlaySubscription: Subscription | null = null;
    isOverlayVisible = false;
    overlayZIndex = 1000;
    routes: any[];
    private _accountSubscription: Subscription | null = null;
    public urlValue: String;
    private warningModal: any = null;
    private safariVHEventListener: (() => void) | null = null;

    private routerSubscription: Subscription | null = null;
    private authLogoutSubscription: Subscription | null = null;

    private readonly destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private compiler: Compiler,
        private injector: Injector,
        private accountService: AccountService,
        private authService: AuthService,
        private idleTimer: IdleTimerService,
        private modal: NzModalService,
        public overlayService: OverlayService
    ) {
        console.log('🟢 [1] AppComponent - Constructor initialized');

        this.authService.loginSuccess$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            console.log('🟢 [2] AppComponent - Login success detected');
            this.showHead = true;
            console.log('🟢 [3] AppComponent - Setting up subscriptions');
            this.setupSubscriptions();
            console.log('🟢 [4] AppComponent - Starting idle timer');
            this.idleTimer.startTimer();
        });

        // Let's add debug logging here
        console.log('🟢 Setting up logout subscription');
        this.authService.logout$.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            console.log('🔴 AppComponent - Logout detected - cleaning up');
            console.log('🔴 Current stack trace:', new Error().stack);
            this.showHead = false;
            this.destroySubscriptions();
            this.idleTimer.stopTimer();
        });
    }

    ngOnInit() {

        if (this.isSafari()) {
            this.setupSafariViewportFix();
        }
    }

    private setupSubscriptions() {
        console.log('🟢 [5] AppComponent - Setting up all subscriptions');
        this.destroySubscriptions();

        this._idleSubscription = this.idleTimer.idle$.subscribe((isIdle) => {
            console.log('🟢 [6] AppComponent - Idle state changed:', isIdle);
            if (isIdle) {
                this.showIdleWarning();
            }
        });

        // Setup timeout subscription
        this._timeoutSubscription = this.idleTimer.timeout$.subscribe(() => {
            console.log('Session timeout detected');
            this.closeWarningModal();
           // this.authService.logout().subscribe();
        });

        // Setup activity monitoring
        this.setupActivityMonitoring();

        // Setup overlay subscriptions
        this.setupOverlaySubscriptions();

        // Setup router subscription
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                console.log('Navigation completed to:', event.url);
                this.urlValue = event.url;

            }
        });
    }

    private setupOverlaySubscriptions() {
        // Only setup if not already subscribed
        if (!this._overlaySubscription) {
            this._overlaySubscription = this.overlayService.isVisible().subscribe(
                visible => {
                    this.isOverlayVisible = visible;
                    console.log('overlayService.isVisible()', visible);
                    if (visible) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = 'auto';
                    }
                }
            );

            // Add z-index subscription
            this._overlaySubscription.add(
                this.overlayService.getZIndex().subscribe(
                    zIndex => {
                        this.overlayZIndex = zIndex;
                        console.log('overlayZIndex', this.overlayZIndex);
                    }
                )
            );
        }
    }

    private setupActivityMonitoring() {
        // Only setup if not already monitoring
        if (this._activitySubscription) {
            return;
        }

        // Combine all relevant user activity events
        const mouseEvents = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'mouseup'),
            fromEvent(document, 'click'),
            fromEvent(document, 'contextmenu'),
            fromEvent(document, 'dblclick')
        );

        const keyboardEvents = merge(
            fromEvent(document, 'keypress'),
            fromEvent(document, 'keydown'),
            fromEvent(document, 'keyup')
        );

        const touchEvents = merge(
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'touchend'),
            fromEvent(document, 'touchmove')
        );

        const scrollEvents = fromEvent(document, 'scroll');
        const wheelEvents = fromEvent(document, 'wheel');

        // Combine all events and debounce to prevent excessive timer resets
        this._activitySubscription = merge(
            mouseEvents,
            keyboardEvents,
            touchEvents,
            scrollEvents,
            wheelEvents
        )
            .pipe(debounceTime(300)) // Wait for 300ms of inactivity before triggering
            .subscribe(() => {
                // Use auth state to check if user is logged in
                this.authService.getAuthState().subscribe(authState => {
                    if (authState.isAuthenticated) {
                        this.idleTimer.resetTimer();
                    }
                }).unsubscribe(); // Unsubscribe immediately since we only need the current value
            });
    }

    private destroySubscriptions() {
        console.log('🔴 AppComponent - Destroying all subscriptions');

        [
            this._idleSubscription,
            this._activitySubscription,
            this._timeoutSubscription,
            this._overlaySubscription,
            this._accountSubscription,
            this.routerSubscription,
            this.authLogoutSubscription
        ].forEach(subscription => {
            if (subscription) {
                subscription.unsubscribe();
                subscription = null;
            }
        });
    }

    private closeWarningModal() {
        if (this.warningModal) {
            this.warningModal.close();
            this.warningModal = null;
        }
    }

    showIdleWarning() {
        // If there's already a warning modal, don't show another one
        if (this.warningModal) {
            return;
        }

        this.warningModal = this.modal.confirm({
            nzTitle: 'Session Timeout Warning',
            nzContent: 'Your session will expire in 1 minute due to inactivity. Would you like to continue?',
            nzOkText: 'Yes, keep me signed in',
            nzCancelText: 'No, log me out',
            nzOnOk: () => {
                this.idleTimer.stayLoggedIn();
                this.warningModal = null;
            },
            nzOnCancel: () => {
                this.closeWarningModal();
                this.destroySubscriptions(); // Ensure subscriptions are destroyed on logout
                this.idleTimer.logout('User chose to logout from inactivity warning');
            },
            nzClosable: false,
            nzMaskClosable: false,
        });
    }

    toggleClick(): void {
        (this.sidebarInstance as any).toggle();
    }

    OnSelect(args: any) {
        document.getElementById('listContent').innerHTML = args.data.description;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
        console.log('App component destroying');
        this.idleTimer.stopTimer();
        this.destroySubscriptions();
        this.closeWarningModal();

        if (this.safariVHEventListener) {
            window.removeEventListener('resize', this.safariVHEventListener);
            window.removeEventListener('orientationchange', this.safariVHEventListener);
            this.safariVHEventListener = null;
        }

        this.overlayService.reset();
    }

    OnClose(args: any) {
        if (args.event?.target.className == 'fa-solid fa-bars') {
            args.cancel = true;
        }
    }

    ngAfterViewInit() {

        // Only log in production
        if (environment.production) {
            console.log('Z-Index Debug Information:');

            // Check CDK Overlay
            const overlay = document.querySelector('.cdk-overlay-container');
            if (overlay) {
                console.log('CDK Overlay z-index:', getComputedStyle(overlay).zIndex);
            }

            // Check Modal
            const modal = document.querySelector('.modal');
            if (modal) {
                console.log('Modal z-index:', getComputedStyle(modal).zIndex);
            }

            // Check Header
            const header = document.querySelector('header');
            if (header) {
                console.log('Header z-index:', getComputedStyle(header).zIndex);
            }

            // Check Dropdowns
            const dropdowns = document.querySelectorAll('[ngbDropdown]');
            dropdowns.forEach(dropdown => {
                console.log('Dropdown z-index:', getComputedStyle(dropdown).zIndex);
            });
        }
    }

    private isSafari(): boolean {
        const ua = navigator.userAgent.toLowerCase();
        return (
            ua.includes('safari') &&
            !ua.includes('chrome') &&
            !ua.includes('android')
        ) || (
            /iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        );
    }

    private setupSafariViewportFix() {
        const setVH = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        setVH();
        window.addEventListener('resize', setVH);
        window.addEventListener('orientationchange', setVH);

        // Store the event listener for cleanup
        this.safariVHEventListener = setVH;
    }
}
