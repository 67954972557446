import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { AngularMaterialModule } from '../material.module';
import { NgZorroAntdModule } from '../zorro.module';

@NgModule({
  declarations: [
    BottomSheetComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    AngularMaterialModule,
    NgZorroAntdModule
  ],
  exports: [
    BottomSheetComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
