import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';
import { AccountService } from '../login/service/account.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../login/service/auth.service';
import { Router } from '@angular/router';
import { EventBusService } from '../common/event.bus.service';

// Create a singleton for managing refresh state
const refreshState = {
  isRefreshing: false,
  refreshTokenSubject: new BehaviorSubject<any>(null)
};

export const JwtInterceptor: HttpInterceptorFn = (req, next) => {
  const accountService = inject(AccountService);
  const eventBusService = inject(EventBusService);
  const authService = inject(AuthService);
  const router = inject(Router);

  const token = authService.getJwt();
  const isApiUrl = req.url.startsWith(environment.apiUrl);

  if (token && isApiUrl) {
    req = addToken(req, token);
  }

  return next(req).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && error.status === 401 && isApiUrl) {
        return handle401Error(req, next, authService, eventBusService);
      }
      return throwError(() => error);
    })
  );
};

function addToken(request: any, token: string) {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
}

function handle401Error(request: any, next: any, authService: AuthService, eventBusService: EventBusService): Observable<any> {
  if (!refreshState.isRefreshing) {
    refreshState.isRefreshing = true;
    refreshState.refreshTokenSubject.next(null);

    if (authService.isLoggedIn()) {
      return authService.refreshToken().pipe(
        switchMap((response: any) => {
          refreshState.isRefreshing = false;
          refreshState.refreshTokenSubject.next(response.token);

          authService.setToken(response.token);
          authService.setRefreshToken(response.refreshToken);

          return next(addToken(request, response.token));
        }),
        catchError((err) => {
          refreshState.isRefreshing = false;
          authService.logout();
          return throwError(() => err);
        })
      );
    }
    return throwError(() => new Error('Not logged in'));
  }

  return refreshState.refreshTokenSubject.pipe(
    filter(token => token !== null),
    take(1),
    switchMap(token => next(addToken(request, token)))
  );
}
