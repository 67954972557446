import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';

export interface NavGroup {
  title: string;
  icon: string;
  path: string;
  children?: NavItem[];
  roles: string[];
}

export interface NavItem {
  title: string;
  icon: string;
  path: string;
  roles?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private sidenavOpen = new BehaviorSubject<boolean>(false);
  private avatarSubject = new BehaviorSubject<string | null>(null);
  isMobile$: Observable<boolean>;

  navigationGroups: NavGroup[] = [
    {
      title: 'Home',
      icon: 'fa-home',
      path: '/dashboard',
      roles: ['ALL']
    },
    {
      title: 'Client Management',
      icon: 'fa-users',
      path: '/clients', // Added missing path property
      children: [
        { title: 'Client List', icon: 'fa-users', path: '/clients' },
        { title: 'Appointments', icon: 'fa-calendar', path: '/appointments' },
        { title: 'Assessments', icon: 'fa-clipboard-list', path: '/assessments', roles: ['DEVELOPER'] },
        { title: 'Forms', icon: 'fa-clipboard-check', path: '/forms', roles: ['DEVELOPER'] },
        { title: 'Authorizations', icon: 'fa-file-text', path: '/billing', roles: ['ADMIN'] }
      ],
      roles: ['ADMIN', 'STAFF']
    },
    {
      title: 'Tasks',
      icon: 'fa-tasks',
      path: '/tasks',
      roles: ['ADMIN', 'STAFF']
    },
    {
      title: 'Reports',
      icon: 'fa-chart-bar',
      path: '/reporting',
      roles: ['ADMIN']
    }
  ];

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(map(result => result.matches));

    // Auto-close sidenav on mobile
    this.isMobile$.subscribe(isMobile => {
      if (isMobile) {
        this.sidenavOpen.next(false);
      }
    });
  }

  toggleSidenav() {
    console.log('toggleSidenav', this.sidenavOpen.value);
    this.sidenavOpen.next(!this.sidenavOpen.value);
  }

  getSidenavState(): Observable<boolean> {
    return this.sidenavOpen.asObservable();
  }

  getNavigationGroups(): NavGroup[] {
    return this.navigationGroups;
  }

  updateAvatar(imageUrl: string): void {
    this.avatarSubject.next(imageUrl);
  }

  getAvatarState(): Observable<string | null> {
    return this.avatarSubject.asObservable();
  }
}
