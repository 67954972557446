// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            :host ::ng-deep.anticon:hover {
                cursor: pointer !important;
            }
            :host ::ng-deep.anticon.anticon-delete {
                color: red !important;
            }
        
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJ1dHRvbmNlbGwuYXJjaGl2ZS5yZW5kZXJlci50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO1lBQ1k7Z0JBQ0ksMEJBQTBCO1lBQzlCO1lBQ0E7Z0JBQ0kscUJBQXFCO1lBQ3pCIiwiZmlsZSI6ImJ1dHRvbmNlbGwuYXJjaGl2ZS5yZW5kZXJlci50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgICAgICAgOmhvc3QgOjpuZy1kZWVwLmFudGljb246aG92ZXIge1xuICAgICAgICAgICAgICAgIGN1cnNvcjogcG9pbnRlciAhaW1wb3J0YW50O1xuICAgICAgICAgICAgfVxuICAgICAgICAgICAgOmhvc3QgOjpuZy1kZWVwLmFudGljb24uYW50aWNvbi1kZWxldGUge1xuICAgICAgICAgICAgICAgIGNvbG9yOiByZWQgIWltcG9ydGFudDtcbiAgICAgICAgICAgIH1cbiAgICAgICAgIl19 */`, "",{"version":3,"sources":["webpack://./src/app/common/buttoncell.archive.renderer.ts"],"names":[],"mappings":";YACY;gBACI,0BAA0B;YAC9B;YACA;gBACI,qBAAqB;YACzB;;AAEZ,gmBAAgmB","sourcesContent":["\n            :host ::ng-deep.anticon:hover {\n                cursor: pointer !important;\n            }\n            :host ::ng-deep.anticon.anticon-delete {\n                color: red !important;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
