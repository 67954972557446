import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, Observable, of, map, BehaviorSubject, retry, timer } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { UtilityService } from 'src/app/common/utility';
import { environment } from 'src/environments/environment';
import {
    AppointmentModel,
    AppointmentStatus,
    AppointmentAvailabilityModel,
    AppointmentNotification,
    convertToApiStatus,
    convertFromApiStatus,
    AppointmentAvailabilityResponse,
} from '../model/appointment.model';
import * as signalR from '@microsoft/signalr';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class AppointmentService {
    private appointmentUpdates = new BehaviorSubject<AppointmentModel | null>(null);
    private notificationUpdates = new BehaviorSubject<AppointmentNotification | null>(null);
    private baseUrl: string = environment.apiUrl + '/StaffAppointment';
    private pollingInterval = 30000; // 30 seconds

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private utility: UtilityService
    ) {
        this.startPolling();
    }

    private startPolling() {
        timer(0, this.pollingInterval).subscribe(() => {
            this.checkForUpdates();
        });
    }

    private checkForUpdates() {
        this.getAllAppointments().subscribe((appointments) => {
            if (appointments && appointments.length > 0) {
                const latestAppointment = appointments[0];
                this.appointmentUpdates.next(latestAppointment);
            }
        });
    }

    getAppointmentUpdates(): Observable<AppointmentModel | null> {
        return this.appointmentUpdates.asObservable();
    }

    getNotificationUpdates(): Observable<AppointmentNotification | null> {
        return this.notificationUpdates.asObservable();
    }

    getAllAppointments(): Observable<AppointmentModel[]> {
        return this.http.get<AppointmentModel[]>(`${this.baseUrl}/GetAll`).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentModel[]>('getAllAppointments', []))
        );
    }

    getAppointmentsById(id: string): Observable<AppointmentModel> {
        return this.http.get<AppointmentModel>(`${this.baseUrl}/GetAppointmentsById?id=${id}`).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentModel>('getAppointmentsById'))
        );
    }

    getAppointmentsByStaffId(id: string): Observable<AppointmentModel[]> {
        console.log('getAppointmentsByStaffId', id);
        return this.http.get<AppointmentModel[]>(`${this.baseUrl}/GetAppointmentsByStaffId?id=${id}`).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentModel[]>('getAppointmentsByStaffId', []))
        );
    }

    getAppointmentsByClientId(id: string): Observable<AppointmentModel[]> {
        return this.http.get<AppointmentModel[]>(`${this.baseUrl}/GetAppointmentsByClientId?id=${id}`).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentModel[]>('getAppointmentsByClientId', []))
        );
    }

    getStaffAvailability(
        staffId: string,
        startDate: Date,
        endDate: Date
    ): Observable<AppointmentAvailabilityModel[]> {
        const url = `${
            this.baseUrl
        }/GetStaffAvailability?staffId=${staffId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
        return this.http.get<AppointmentAvailabilityModel[]>(url).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentAvailabilityModel[]>('getStaffAvailability', []))
        );
    }

    addNewAppointment(appointment: AppointmentModel): Observable<any> {
        const apiModel = appointment.toApiModel ? appointment.toApiModel() : appointment;
        return this.http
            .post<AppointmentModel>(`${this.baseUrl}/SetNewStaffAppointment`, apiModel, httpOptions)
            .pipe(
                map((response) => this.utility.toCamelCase(response)),
                retry(3),
                catchError(this.handleError<AppointmentModel>('addNewAppointment'))
            );
    }

    updateAppointment(appointment: AppointmentModel): Observable<any> {
        const apiModel = appointment.toApiModel ? appointment.toApiModel() : appointment;
        console.log('Update Appointment API Model', apiModel);
        return this.http
            .post<boolean>(`${this.baseUrl}/UpdateStaffAppointment`, apiModel, httpOptions)
            .pipe(retry(3), catchError(this.handleError<boolean>('updateAppointment')));
    }

    handleAppointmentRequest(
        appointmentId: string,
        status: AppointmentStatus,
        notes?: string
    ): Observable<boolean> {
        const updateData = {
            appointmentId,
            newStatus: convertToApiStatus(status),
            notes,
        };
        return this.http
            .post<boolean>(`${this.baseUrl}/UpdateStaffAppointment`, updateData, httpOptions)
            .pipe(retry(3), catchError(this.handleError<boolean>('handleAppointmentRequest')));
    }

    cancelAppointment(appointmentId: string, reason: string): Observable<boolean> {
        return this.http
            .post<boolean>(
                `${this.baseUrl}/DeleteStaffAppointment`,
                { id: appointmentId, reason },
                httpOptions
            )
            .pipe(retry(3), catchError(this.handleError<boolean>('cancelAppointment')));
    }

    rescheduleAppointment(oldAppointmentId: string, newAppointment: AppointmentModel): Observable<boolean> {
        const apiModel = newAppointment.toApiModel ? newAppointment.toApiModel() : newAppointment;
        return this.http
            .post<boolean>(
                `${this.baseUrl}/RescheduleAppointment?oldAppointmentId=${oldAppointmentId}`,
                apiModel,
                httpOptions
            )
            .pipe(retry(3), catchError(this.handleError<boolean>('rescheduleAppointment')));
    }

    setStaffAvailability(availability: AppointmentAvailabilityModel): Observable<boolean> {
        return this.http
            .post<boolean>(`${this.baseUrl}/SetStaffAvailability`, availability, httpOptions)
            .pipe(retry(3), catchError(this.handleError<boolean>('setStaffAvailability')));
    }

    deleteAppointmentById(id: string): Observable<any> {
        return this.http
            .delete<boolean>(`${this.baseUrl}/DeleteAppointmentById?id=${id}`, httpOptions)
            .pipe(retry(3), catchError(this.handleError('deleteAppointmentById')));
    }

    getPendingAppointments(staffId: string): Observable<AppointmentModel[]> {
        return this.getAppointmentsByStaffId(staffId).pipe(
            map((appointments) => appointments.filter((apt) => apt.status === AppointmentStatus.Pending))
        );
    }

    checkAvailability(staffId: string, startTime: Date, endTime: Date): Observable<AppointmentAvailabilityResponse> {
        const url = `${this.baseUrl}/CheckAvailability`;
        const request = {
            staffId: staffId,
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString()
        };

        return this.http.post<AppointmentAvailabilityResponse>(url, request, httpOptions).pipe(
            map((response) => this.utility.toCamelCase(response)),
            retry(3),
            catchError(this.handleError<AppointmentAvailabilityResponse>('checkAvailability',
                { isAvailable: false, conflictReason: 'Error checking availability' }))
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error);
            this.messageService.add(`${operation} failed: ${error.message}`);
            return of(result as T);
        };
    }
}
