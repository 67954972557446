import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Observable, Observer, Subject, take } from 'rxjs';
import { VendorModel } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-assessment-jobs-info',
    template: `
        <div class="jobs-form-container">
            <nz-page-header class="site-page-header" [nzTitle]="title">
            </nz-page-header>

            <nz-layout class="form-layout">
                <nz-content>
                    <div [ngClass]="{'mobile-view': isMobileView}" class="content-container">
                        <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                            <nz-form-item>
                                <nz-form-label [nzSpan]="isMobileView ? 24 : 6" nzRequired>Service Title</nz-form-label>
                                <nz-form-control [nzSpan]="isMobileView ? 24 : 16" nzHasFeedback [nzErrorTip]="jobErrorTpl">
                                    <input nz-input formControlName="jobTitle" placeholder="Enter service title" />
                                    <ng-template #jobErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">Service title is required</ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>

                            <div class="form-actions" [ngClass]="{'mobile-actions': isMobileView}">
                                <button nz-button (click)="resetForm($event)">Reset</button>
                                <button nz-button nzType="primary" type="submit" [disabled]="!validateForm.valid">Save</button>
                            </div>
                        </form>
                    </div>
                </nz-content>
            </nz-layout>
        </div>
    `,
    styles: [`
        .jobs-form-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .form-layout {
            height: 100%;
            background: transparent;
        }

        .content-container {
            padding: 24px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .mobile-view {
            padding: 16px;
        }

        .form-actions {
            display: flex;
            justify-content: flex-end;
            gap: 8px;
            margin-top: 24px;
        }

        .mobile-actions {
            flex-direction: column;
            gap: 12px;

            button {
                width: 100%;
            }
        }

        :host ::ng-deep {
            .ant-form-item {
                margin-bottom: 24px;
            }

            @media (max-width: 1366.98px) {
                .ant-form-item {
                    margin-bottom: 16px;
                }

                .ant-form-item-label {
                    padding-bottom: 4px;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzGridModule,
        NzPageHeaderModule,
        NzLayoutModule
    ]
})
export class AssessmentJobsFormComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    isMobileView = false;

    constructor(private service: AssessmentService) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
        this.checkScreenSize();
        window.addEventListener('resize', () => this.checkScreenSize());
    }

    private checkScreenSize() {
        this.isMobileView = window.innerWidth <= 1366;
    }

    ngOnInit(): void {
        this.title = 'Service Information';
        this.validateForm = new FormGroup({
            jobTitle: new FormControl('', Validators.required),
        });

        if (this.model?.jobInformationModel) {
            this.patchFormValues();
        }
    }

    patchFormValues() {
        this.validateForm.get('jobTitle').setValue(this.model.jobInformationModel.jobTitle);
        this.validateForm.updateValueAndValidity();
    }

    submitForm(): void {
        console.log('submit', this.validateForm.value);
        this.onAddClientReferral();
    }

    resetForm(e: MouseEvent): void {
        e.preventDefault();
        this.validateForm.reset();
        for (const key in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(key)) {
                this.validateForm.controls[key].markAsPristine();
                this.validateForm.controls[key].updateValueAndValidity();
            }
        }
    }

    validateConfirmPassword(): void {
        setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    userNameAsyncValidator = (control: FormControl) =>
        new Observable((observer: Observer<ValidationErrors | null>) => {
            setTimeout(() => {
                if (control.value === 'JasonWood') {
                    // you have to return `{error: true}` to mark it as an error event
                    observer.next({ error: true, duplicated: true });
                } else {
                    observer.next(null);
                }
                observer.complete();
            }, 1000);
        });

    confirmValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { error: true, required: true };
        } else if (control.value !== this.validateForm.controls.password.value) {
            return { confirm: true, error: true };
        }
        return {};
    };

    onAddClientReferral() {
        this.model = this.validateForm.value;
        this.assessmentOutput.emit({ form: this.model });
    }

    next() {
        this.model.jobInformationModel = this.validateForm.value;
    }
}
