import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { AuthGuard } from './login/service/authguard';
import { DashboardComponent } from './dashboard/components/dashboard.component';
import { SelectivePreloadingStrategy } from './common/selective-preloading.strategy';
import { VendorComponent } from './vendorList/vendorlist.component';
import { ActivityDashboardComponent } from './activity/activity-dashboard.component';
import { ActivityArchiveComponent } from './activity/activity-archive.component';

const accountModule: any = () => import('./login/account.module').then((x) => x.AccountModule);
const clientModule: any = () => import('./client/module/client.module').then((x) => x.ClientModule);

const reportModule: any = () => import('./reports/module/report.module').then((x) => x.ReportModule);
const taskModule: any = () => import('./tasks/module/tasks.module').then((x) => x.TaskModule);
const staffModule: any = () => import('./staff/module/staff.module').then((x) => x.StaffModule);
const calendarModule: any = () => import('./calendar/module/calendar.module').then((x) => x.CalendarModule);
const billingModule: any = () => import('./billing//module/billing.module').then((x) => x.BillingModule);
const appointmentModule: any = () =>
    import('./appointment/module/appointment.module').then((x) => x.AppointmentModule);
const migrationModule: any = () =>
    import('./migration-dashboard/migration-dashboard.routes').then((x) => ({ default: x.MIGRATION_ROUTES }));

// Standalone Components
const migrationTab: any = () => import('./reports/migration-tab.component').then((x) => x.MigrationTabComponent);
const reportDashboard: any = () => import('./reports/report-dashboard.component').then((x) => x.ReportDashboardComponent);

const routes: Routes = [
    { path: '', redirectTo: 'account/login', pathMatch: 'full' },
    { path: 'account', loadChildren: accountModule },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'USER', 'STAFF'],
            breadcrumb: 'Home',
        },
    },
    {
        path: 'activity',
        children: [
            {
                path: '',
                component: ActivityDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ['ADMIN'],
                    preload: true,
                    breadcrumb: 'Current Logs',
                },
            },
            {
                path: 'archive',
                component: ActivityArchiveComponent,
                canActivate: [AuthGuard],
                data: {
                    role: ['ADMIN'],
                    preload: true,
                    breadcrumb: 'Archive Logs',
                },
            },
        ],
    },
    {
        path: 'reporting',
        children: [
            {
                path: '',
                loadChildren: reportModule,
                canActivate: [AuthGuard],
                data: {
                    role: ['ADMIN', 'STAFF'],
                    preload: true,
                    breadcrumb: 'Reports',
                }
            },
            {
                path: 'migration',
                loadComponent: migrationTab,
                canActivate: [AuthGuard],
                data: {
                    role: ['ADMIN', 'STAFF'],
                    preload: true,
                    breadcrumb: 'Migration',
                }
            },
            {
                path: 'dashboard',
                loadComponent: reportDashboard,
                canActivate: [AuthGuard],
                data: {
                    role: ['ADMIN', 'STAFF'],
                    preload: true,
                    breadcrumb: 'Dashboard',
                }
            }
        ]
    },
    {
        path: 'tasks',
        loadChildren: taskModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            breadcrumb: 'Tasks',
        },
    },
    {
        path: 'appointments',
        loadChildren: appointmentModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            preload: true,
            breadcrumb: 'Appointments',
        },
    },
    {
        path: 'calendar',
        loadChildren: calendarModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            breadcrumb: 'Calendar',
        },
    },

    {
        path: 'billing',
        loadChildren: billingModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            preload: true,
            breadcrumb: 'Billing',
        },
    },
    {
        path: 'clients',
        loadChildren: clientModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            preload: true,
            breadcrumb: 'Clients',
        },
    },
    {
        path: 'staff',
        loadChildren: staffModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
            preload: true,
            breadcrumb: 'Staff',
        },
    },
    {
        path: 'forms',
        component: NotificationComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },

    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            breadcrumb: 'Settings',
        },
    },
    {
        path: 'vendorList',
        component: VendorComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            breadcrumb: 'Vendors',
        },
    },
    {
        path: 'firebasemigration',
        loadChildren: migrationModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
            preload: true,
            breadcrumb: 'Firebase Migration',
        },
    },
    { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: SelectivePreloadingStrategy,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
