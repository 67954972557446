import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of, from, throwError } from 'rxjs';
import { map, take, catchError, switchMap } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Forgotpass, Login, Resetpass } from '../models/login';
import { AuthenticatedResponse, StringResponse } from '../models/authenticatedresponse';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { VendorService } from 'src/app/vendorList/services/vendor.service';
import firebase from 'firebase/compat/app';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    private isLoading: boolean = false;
    loadingScreenObserver: Subject<boolean> = new Subject<boolean>();
    private env: any;
    _undefined: undefined;

    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService,
        private vendorService: VendorService
    ) {
        if (localStorage.getItem('USER') == '') {
            localStorage.setItem('USER', '""');
        }
        this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('USER')!));
        this.user = this.userSubject.asObservable();
        this.env = environment;
        this.loadingScreenObserver.subscribe((value) => (this.isLoading = value));
    }

    public get userValue() {
        return this.userSubject.value;
    }

    isNullUndefined(value): boolean {
        return value === null || value === undefined;
    }

    private loginWithSql(login: Login): Observable<User> {
        return this.http
            .post<AuthenticatedResponse>(`${environment.apiUrl}/auth/login`, {
                email: login.email,
                password: login.password,
                isClientPortal: false,
            })
            .pipe(
                map((response: AuthenticatedResponse) => {
                    const user = new User();
                    user.id = response?.staffId || response?.clientId || '';
                    user.email = response?.email || '';
                    user.firstName = response?.firstName || '';
                    user.lastName = response?.lastName || '';
                    user.role = response?.role || '';
                    user.accessToken = response?.accessToken || '';
                    user.refreshToken = response?.refreshToken || '';
                    user.lastLogin = response?.lastLogin || new Date().toISOString();
                    user.firebaseUid = ''; // Empty for SQL auth

                    // Optional fields
                    user.vendorName = response?.vendorName;
                    user.staffId = response?.staffId;
                    user.staffNumber = response?.staffNumber;
                    user.profileImage = response?.profileImage;
                    return user;
                })
            );
    }

    login(login: Login, useSqlAuth: boolean = false): Observable<User> {
        if (useSqlAuth) {
            console.log('Logging in with SQL authentication');
            return this.loginWithSql(login).pipe(
                map((user: User) => {
                    localStorage.clear();
                    this.authService.login(
                        user?.role || '',
                        JSON.stringify(user),
                        user?.accessToken || '',
                        user?.refreshToken || '',
                        user?.staffId || '',
                        user?.staffNumber || '',
                        user?.vendorName || `${user?.firstName} ${user?.lastName}`,
                        user?.profileImage || '',
                        '' // Empty Firebase UID for SQL auth
                    );
                    console.log('user login with sql', user);
                    this.userSubject.next(user);
                    return user;
                }),
                catchError((error: HttpErrorResponse) => {
                    console.error('SQL Login error:', error);
                    return throwError(() => new Error(this.getErrorMessage(error)));
                })
            );
        }

        // Firebase authentication
        return from(this.authService.loginWithFirebase(login.email, login.password)).pipe(
            switchMap(async (firebaseResult) => {
                console.log('Firebase login successful in account service', firebaseResult);
                const token = await firebaseResult.user?.getIdToken();
                const response = await this.http
                    .post<AuthenticatedResponse>(`${environment.apiUrl}/staff/update-login`, {
                        email: login.email,
                        firebaseUid: firebaseResult.user?.uid,
                        accessToken: token,
                    })
                    .toPromise().catch((error) => {
                        console.error('Error updating login:', error);
                        throw error;
                    });
                console.log('user login with firebase', response);

                const user = new User();
                user.id = response?.staffId || response?.clientId || '';
                user.email = response?.email || '';
                user.firstName = response?.firstName || '';
                user.lastName = response?.lastName || '';
                user.role = response?.role || '';
                user.accessToken = response?.accessToken || '';
                user.refreshToken = response?.refreshToken || '';
                user.lastLogin = response?.lastLogin || new Date().toISOString();
                user.firebaseUid = firebaseResult?.user?.uid || '';

                // Optional fields
                user.vendorName = response?.vendorName;
                user.staffId = response?.staffId;
                user.staffNumber = response?.staffNumber;
                user.profileImage = response?.profileImage;
                return user;
            }),
            map((user: User) => {
                console.log('user login with firebase in account service', user);
                localStorage.clear();
                this.authService.login(
                    user?.role || '' ,
                    JSON.stringify(user),
                    user?.accessToken || '',
                    user?.refreshToken || '',
                    user?.staffId || '',
                    user?.staffNumber || '',
                    user?.vendorName || `${user?.firstName} ${user?.lastName}`,
                    user?.profileImage || '',
                    user?.firebaseUid || ''
                );
                this.userSubject.next(user);
                return user;
            }),
            catchError((error: HttpErrorResponse) => {
                console.error('Firebase Login error:', error);
                return throwError(() => new Error(this.getErrorMessage(error)));
            })
        );
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.clear();

        return this.authService
            .logout()
            .pipe(take(1))
            .subscribe((res) => {
                this.userSubject.next(null);
                console.log('this.router.url logout authService', this.router.url);
                this.router.navigate(['/account/login']);
                return of({ success: res.success, role: res.role });
            });
    }

    forgotpass(forgotpass: Forgotpass): Observable<any> {
        return from(this.authService.resetPasswordWithFirebase(forgotpass.email)).pipe(
            map(() => ({ message: 'Password reset email sent successfully' })),
            catchError((error) => {
                console.error('Forgot password error:', error);
                return throwError(() => new Error(this.getErrorMessage(error)));
            })
        );
    }

    resetpass(resetpass: Resetpass): Observable<any> {
        return from(this.authService.updatePassword(resetpass.password)).pipe(
            map(() => ({ message: 'Password updated successfully' })),
            catchError((error) => {
                console.error('Reset password error:', error);
                return throwError(() => new Error(this.getErrorMessage(error)));
            })
        );
    }

    public getErrorMessage(error: any): string {
        if (error.code) {
            switch (error.code) {
                case 'auth/user-not-found':
                    return 'No account found with this email address';
                case 'auth/wrong-password':
                    return 'Invalid password';
                case 'auth/invalid-email':
                    return 'Invalid email address';
                case 'auth/user-disabled':
                    return 'This account has been disabled';
                case 'auth/too-many-requests':
                    return 'Too many failed attempts. Please try again later';
                case 'auth/email-already-in-use':
                    return 'This email address is already registered';
                case 'auth/operation-not-allowed':
                    return 'Email/password accounts are not enabled. Please contact support';
                case 'auth/weak-password':
                    return 'Password should be at least 6 characters';
                case 'auth/invalid-credential':
                    return 'The provided credential is invalid or has expired';
                case 'auth/invalid-verification-code':
                    return 'Invalid verification code';
                case 'auth/invalid-verification-id':
                    return 'Invalid verification ID';
                case 'auth/missing-verification-code':
                    return 'Please provide a verification code';
                case 'auth/missing-verification-id':
                    return 'Missing verification ID';
                case 'auth/network-request-failed':
                    return 'Network error occurred. Please check your internet connection';
                case 'auth/requires-recent-login':
                    return 'This operation requires recent authentication. Please log in again';
                case 'auth/provider-already-linked':
                    return 'Account already linked with another provider';
                case 'auth/credential-already-in-use':
                    return 'This credential is already associated with another account';
                case 'auth/popup-blocked':
                    return 'Authentication popup was blocked by the browser';
                case 'auth/popup-closed-by-user':
                    return 'Authentication popup was closed before completing the sign in';
                case 'auth/unauthorized-domain':
                    return 'This domain is not authorized for OAuth operations';
                case 'auth/invalid-action-code':
                    return 'Invalid action code. Please request a new one';
                case 'auth/expired-action-code':
                    return 'Action code has expired. Please request a new one';
                case 'auth/invalid-persistence-type':
                    return 'Invalid persistence type specified';
                case 'auth/invalid-tenant-id':
                    return 'Invalid tenant ID specified';
                case 'auth/multi-factor-auth-required':
                    return 'Multi-factor authentication is required';
                case 'auth/missing-android-pkg-name':
                    return 'Missing Android package name';
                case 'auth/missing-continue-uri':
                    return 'Missing continue URL';
                case 'auth/missing-ios-bundle-id':
                    return 'Missing iOS bundle ID';
                case 'auth/invalid-continue-uri':
                    return 'Invalid continue URL provided';
                case 'auth/invalid-dynamic-link-domain':
                    return 'Invalid dynamic link domain';
                case 'auth/invalid-oauth-provider':
                    return 'Invalid OAuth provider';
                case 'auth/invalid-oauth-client-id':
                    return 'Invalid OAuth client ID';
                case 'auth/captcha-check-failed':
                    return 'Captcha validation failed';
                case 'auth/invalid-app-credential':
                    return 'Invalid app credential';
                case 'auth/invalid-custom-token':
                    return 'Invalid custom token';
                case 'auth/invalid-phone-number':
                    return 'Invalid phone number format';
                case 'auth/missing-phone-number':
                    return 'Phone number is required';
                case 'auth/quota-exceeded':
                    return 'Quota exceeded. Please try again later';
                case 'auth/rejected-credential':
                    return 'The credential was rejected';
                case 'auth/tenant-id-mismatch':
                    return 'Tenant ID mismatch';
                case 'auth/timeout':
                    return 'Request timeout. Please try again';
                case 'auth/user-token-expired':
                    return 'User token has expired. Please log in again';
                case 'auth/web-storage-unsupported':
                    return 'Web storage is not supported by your browser';
                case 'auth/invalid-api-key':
                    return 'Invalid API key';
                case 'auth/app-deleted':
                    return 'Application has been deleted';
                case 'auth/user-token-mismatch':
                    return 'User token is invalid. Please log in again';
                default:
                    return error.message || 'An error occurred';
            }
        }
        return error.message || 'An error occurred';
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id: string, params: any) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params).pipe(
            map((x) => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue?.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('USER', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            })
        );
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`).pipe(
            map((x) => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue?.id) {
                    this.logout();
                }
                return x;
            })
        );
    }

    public setLoading(value) {
        this.loadingScreenObserver.next(value);
    }
}
