import { CdkPortal, CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
    OnInit,
    Component,
    ChangeDetectorRef,
    ViewChild,
    AfterViewInit,
    AfterContentInit,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    GridModule,
    InfiniteScrollService,
    InfiniteScrollSettingsModel,
    PageSettingsModel,
} from '@syncfusion/ej2-angular-grids';
import { DashboardLayoutComponent, DashboardLayoutModule, PanelModel } from '@syncfusion/ej2-angular-layouts';
import { ClientService } from 'src/app/client/services/client.services';
import { UtilityService } from 'src/app/common/utility';
import { User } from 'src/app/login/models/user';
import { AccountService } from 'src/app/login/service/account.service';
import { AuthService } from 'src/app/login/service/auth.service';
import { TaskModel } from 'src/app/tasks/model/task.model';
import { TaskService } from 'src/app/tasks/services/task.service';
import moment from 'moment';
import { ClientsModel } from 'src/app/client/models/client.model';
import { DayService, EventRenderedArgs, EventSettingsModel, MonthService, ScheduleAllModule, ScheduleComponent, View, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { StaffService } from 'src/app/staff/services/staff.service';
import { StaffModel } from 'src/app/staff/model/staff.model';
import { SpeedDialComponent, SpeedDialModule } from '@syncfusion/ej2-angular-buttons';
import { AppointmentService } from 'src/app/appointment/services/appointment.service';
import { AppointmentModel } from 'src/app/appointment/model/appointment.model';
import { AlertService } from 'src/app/alerts/alert.service';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgZorroAntdModule } from 'src/app/zorro.module';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars';
import { firstValueFrom } from 'rxjs';
var _ = require('lodash');
declare function showHeader(): any;

interface AppointmentInterface {
    Id: number,
    Subject: string,
    ClientId: string,
    CategoryColor: string,
    StartTime: string,
    EndTime: string
  }


@Component({
    selector: 'app-dashboard',
    styleUrls: ['./dashboard.component.css'],
    templateUrl: './dashboard.component.html',
    encapsulation: ViewEncapsulation.None,
    imports: [ScheduleAllModule, SpeedDialModule, GridModule, CommonModule,DashboardLayoutModule,
    FormsModule, ReactiveFormsModule, NgZorroAntdModule, CalendarModule, ScheduleAllModule],
    providers: [DayService, WeekService, WorkWeekService, MonthService, InfiniteScrollService],
    standalone: true
})
export class DashboardComponent implements OnInit, AfterViewInit {
    @ViewChild('list') listObj: any;
    @ViewChild('scheduleObj') public scheduleObj?: ScheduleComponent;
    @ViewChild('textbox') textboxEle: any;
    public data: Object[] = [];
    public fields = { text: 'Name' };
    @ViewChild('api_dashboard') public dashboardObject: DashboardLayoutComponent;
    @ViewChild('popupTempSpeeddial')
    public speeddialObj: SpeedDialComponent;
    public isAdmin: boolean = false;
    public mappedCasesGridData: any[];
    public mappedTaskGridData: any[];
    clientGridData: any[];
    taskGridData: TaskModel[];
    public staffData: StaffModel[] = [];
    public clientList: ClientsModel[] = [];

    public loadingIndicator?: any;
    staffClientData: any = [];
    public toolbar: string[];
    public sortSettings;
    public casesSortSettings;
    public cellSpacing: number[] = [30, 90];
    mediaQuery = 'max-width:1285px';
    mobileArray = [];
    effect = 'scrollx';
    Role: string;
    casesCount: number = 0;
    tasksCount: number = 0;
    messagesCount: number = 0;
    overDueTaskCount: number = 0;
    itemstoReviewCount: number = 0;
    allCasesCount: number = 0;    refreshAppointment: boolean = false;

    refreshcases: boolean = false;
    refreshtask1: boolean = false;
    refreshtask2: boolean = false;
    pendingTaskGridData: any;
    refreshPendingTask: boolean;
    public selectedDate: Date = new Date();
    speedDialClicked: boolean = false;
    allActiveCases: any[];
    allOverDueTaskGridData: any[];
    public options?: PageSettingsModel;
    public infiniteOptions?: InfiniteScrollSettingsModel | any;
    OverDueTasksGrid: any;
    allPendingTaskGridData: any;
    apptdata: AppointmentModel[];
    refreshAppt: boolean;
    get staffValue() {
        return this.staff;
    }
    appointmentDataSource: any;
    set staffValue(staff) {
        this.staff = staff;
    }
    private staff: string = 'Staff Member';
    viewDate: Date = new Date();
    user: User;
    public DateType:string = 'Week'
    public readonly = true;
    public currentView: View = 'Agenda';
    weekInterval = 1
    private calendarId = 'en.usa%23holiday@group.v.calendar.google.com';
    private publicKey = 'AIzaSyBgbX_tgmVanBP4yafDPPXxWr70sjbKAXM';
    // private dataManger: DataManager = new DataManager({
    //     url:
    //         'https://www.googleapis.com/calendar/v3/calendars/' +
    //         this.calendarId +
    //         '/events?key=' +
    //         this.publicKey,
    //     adaptor: new WebApiAdaptor(),
    //     crossDomain: true,
    // });
    // public eventSettings: EventSettingsModel = { dataSource: this.scheduleData, fields: {
    //     id: 'Id',
    //     subject: {name: 'Subject'},
    //     startTime: {name: 'StartTime'},
    //     endTime: {name: 'EndTime'}
    // }, allowEditing:false, enableTooltip:true };
    public eventSettings: EventSettingsModel = {
        fields: {
            subject: {  name: 'Subject',  },
            location: {  name: 'Location',  },
            description: {  name: 'Description' },
            startTime: {  name: 'StartTime' },
            endTime: {  name: 'EndTime' },
            isAllDay: { default: 'false' }
        }
    };
    constructor(
        private authService: AuthService,
        private taskService: TaskService,
        private accountService: AccountService,
        private clientService: ClientService,
        private utilityService: UtilityService,
        private router: Router,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private staffService: StaffService,
        private appointmentService: AppointmentService,
        private alertService:AlertService
    ) {
        this.accountService.setLoading(true);
        let tempuser = this.authService.getUser();
        if (tempuser != '') {
            this.user = <User>JSON.parse(tempuser);
            this.staffValue = this.user?.vendorName || `${this.user?.firstName} ${this.user?.lastName}`;
        }
        this.Role = this.authService.getRole();
        this.isAdmin = this.Role?.toUpperCase() == 'ADMIN';
    }
    ngAfterViewInit(): void {
        this.scheduleObj.showSpinner()
    }

    ngOnInit(): void {
        this.options = { pageSize: 5 };
        this.sortSettings = {
            columns: [
                { field: 'priority', direction: 'Ascending' },
                { field: 'deadlineDate', direction: 'Ascending' },
            ],
        };
        this.casesSortSettings = {
            columns: [{ field: 'createdDate', direction: 'Ascending' }],
        };
        this.infiniteOptions = { enableScroll: true, enableCache: true };
        showHeader();
        this.accountService.setLoading(false);
        this.refreshActiveCasesGrid();
        this.refreshStaffTaskGrid(1);
        this.refreshTaskForReviewGrid(1);
        this.refreshAllOverDueTasksGrid();
        this.refreshAppointments();
    }

    setStaffData() {
        this.staffService.getAllStaff().subscribe((staff) => {
            this.staffData = staff['body'];
        });
    }

    public openSpeedDial() {
        this.speedDialClicked = true;
        this.speeddialObj.show();
    }
    refreshAllOverDueTasksGrid() {
        this.taskService.getAllTask().subscribe(async (data) => {
            this.OverDueTasksGrid = data as any;
            let cloned: TaskModel[] = _.cloneDeep(data);
            let overdue = [];
            cloned.forEach((val) => {
                if (
                    this.utilityService.isTaskOverDue(new Date(val.dueDate)) == true &&
                    val.status != 'Completed'
                ) {
                    overdue.push(val);
                }
            });
            this.overDueTaskCount = overdue?.length;
            this.staffService.getAllStaff().subscribe((data) => {
                this.staffData = data['body'];
                this.allOverDueTaskGridData = overdue.map((item) => ({
                    title: item.title,
                    staffName: this.getStaffName(item),
                    priority: item.priority,
                    summary: item.summary,
                    taskId: item.taskId,
                    deadlineDate: item.dueDate != null ? moment(item.dueDate).format('MM/DD/YYYY') : '',
                    modifiedDate:
                        item.modifiedDate != null
                            ? moment(item.modifiedDate).format('MM/DD/YYYY')
                            : moment(item.createdDate).format('MM/DD/YYYY') || '',
                }));
                this.refreshPendingTask = false;
                this.cdr.detectChanges();
            });
        });
    }
    getStaffName(item: any): any {
        let fName = '';
        let lName = '';
        if (this.staffData?.length > 0) {
            fName = this.staffData.find((x) => x.staffId == item.staffId)?.firstName || '';
            lName = this.staffData.find((x) => x.staffId == item.staffId)?.lastName || '';
        }
        return `${fName} ${lName}`;
    }

    refreshActiveCasesGrid() {
        this.refreshcases = true;
        this.clientService.getAllClients().subscribe((data) => {
            this.clientGridData = data['body'];
            // this will add the cases count to the root of the ClientModel
            let activeCasesDataSource = this.getClientCaseCount(this.clientGridData);

            this.allCasesCount = activeCasesDataSource?.filter((c) => +c.openCases > 0)?.length;
            this.allActiveCases = this.clientGridData?.filter((c) => +c.openCases > 0 && c.isActive == 'Y');
            //This is admin view of all cases
            this.allActiveCases = this.allActiveCases.map((res) => ({
                clientId: res?.clientId,
                clientFirstName: `${res.clientFirstName} ${res.clientLastName}`,
                clientNumber: res?.clientNumber,
                openCases: res?.clientCases?.length,
                caseStatus: res?.isActive == 'Y' ? 'Active' : 'Inactive',
                createdDate:
                    res.modifiedDate != null
                        ? moment(res.modifiedDate).format('MM/DD/YYYY')
                        : moment(res.createdDate).format('MM/DD/YYYY'),
            }));

            //this is staff level view of their cases only
            let cloned: ClientsModel[] = _.cloneDeep(this.clientGridData);
            let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
            let openFilter = this.utilityService.getOpenFilter(staffFilter);
            let model: ClientsModel[] = this.utilityService.getFinalOpenFilter(openFilter);
            this.mappedCasesGridData = model.map((res) => ({
                clientId: res?.clientId,
                clientFirstName: `${res.clientFirstName} ${res.clientLastName}`,
                clientNumber: res?.clientNumber,
                openCases: res?.clientCases?.length,
                caseStatus: res?.isActive == 'Y' ? 'Active' : 'Inactive',
                createdDate:
                    res.modifiedDate != null
                        ? moment(res.modifiedDate).format('MM/DD/YYYY')
                        : moment(res.createdDate).format('MM/DD/YYYY'),
            }));
            this.casesCount = this.mappedCasesGridData?.length;
            this.refreshcases = false;
        });
    }

    refreshStaffTaskGrid(num: number) {
        if (num == 1) {
            this.refreshtask1 = true;
        } else {
            this.refreshtask2 = true;
        }
        this.taskService.getTaskById(this.authService.getStaffId()).subscribe((data) => {
            console.log('Task Grid', data);
            this.OverDueTasksGrid = data; //data['body'];
            if (this.OverDueTasksGrid?.length > 0) {
            let cloned: TaskModel[] = _.cloneDeep(this.OverDueTasksGrid);
            this.mappedTaskGridData = cloned.map((item) => ({
                isOverdue: this.utilityService.isTaskOverDue(item.dueDate),
                taskDaysRemaining: this.utilityService.ToTitleCase(
                    this.utilityService.CalculateTaskDays(item.dueDate)
                ),
                isTaskComingDue: this.utilityService.isTaskComingDue(item.dueDate),
                taskTitle: item.title,
                taskSummary: item.summary,
                priority: item.priority,
                taskId: item.taskId,
                deadlineDate: moment(item?.dueDate)?.format('MM/DD/YYYY'),
                modifiedDate:
                    item.modifiedDate != ''
                        ? moment(item?.modifiedDate)?.format('MM/DD/YYYY')
                        : moment(item?.createdDate)?.format('MM/DD/YYYY'),
            }));
            this.refreshtask1 = false;
            this.refreshtask2 = false;
            }
        });

    }

    refreshTaskForReviewGrid(num: number) {
        this.refreshPendingTask = true;
        this.taskService.getAllTask().subscribe((data) => {
            this.allPendingTaskGridData = data;
            if (this.allPendingTaskGridData?.length > 0) {
            let cloned: TaskModel[] = _.cloneDeep(this.allPendingTaskGridData);
            cloned = cloned?.filter((c) => c.status == 'Review');
            this.itemstoReviewCount = cloned?.length;
            this.staffService.getAllStaff().subscribe((data) => {
                this.staffData = data['body'];
                this.pendingTaskGridData = cloned?.map((item) => ({
                    taskTitle: item.title,
                    status: item.status,
                    taskId: item.taskId,
                    staffName: this.getStaffName(item),
                    priority: item.priority,
                    deadlineDate: item.dueDate != null ? moment(item.dueDate).format('MM/DD/YYYY') : '',
                }));
                this.refreshPendingTask = false;
                this.cdr.detectChanges();
            });
            }
        });
    }

    async refreshAppointments() {
        this.refreshAppointment = true;
        const appointments = (await firstValueFrom(this.appointmentService.getAppointmentsByStaffId(this.authService.getStaffId())))['body']?.length > 0 ? (await firstValueFrom(this.appointmentService.getAppointmentsByStaffId(this.authService.getStaffId())))['body'] : (await firstValueFrom(this.appointmentService.getAppointmentsByStaffId(this.authService.getStaffId())));

        // Get today's date and set time to start of day
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        // Get date 2 weeks from now
        const twoWeeksFromNow = new Date();
        twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
        twoWeeksFromNow.setHours(23, 59, 59, 999);

        const datasource = await Promise.all(appointments
            .filter(appt => {
                const apptDate = new Date(appt.startTime);
                apptDate.setHours(0, 0, 0, 0);

                // Include appointments that are today OR within the next 2 weeks
                return (apptDate.getTime() === today.getTime()) ||
                       (apptDate > today && apptDate <= twoWeeksFromNow);
            })
            .map(async appt => {
                const client = await firstValueFrom(this.clientService.getClientById(appt.clientId));
                return {
                    Id: appt.id,
                    ClientId: appt.clientId,
                    Subject: client ? `${client.clientFirstName} ${client.clientLastName}` : 'Unknown Client',
                    CategoryColor: appt.categoryColor || client?.clientColor || '#1890ff',
                    StartTime: new Date(appt.startTime),
                    EndTime: new Date(appt.endTime)
                };
            })
        );

        this.appointmentDataSource = datasource.sort((a, b) => a.StartTime.getTime() - b.StartTime.getTime());
        this.refreshAppointment = false;
        this.cdr.detectChanges();
    }
    navigateToAppointment(appointment: any) {
        this.router.navigate(['/appointments'], {
            state: {
                apptId: appointment.Id,
                mode: 'Update',
                staffId: this.authService.getStaffId()
            }
        });
    }

    onViewChanged(title: any) {
        this.DateType = title;
       }

    oneventRendered(args: EventRenderedArgs): void {
        let categoryColor: string = args.data.CategoryColor as string;
        if (!args.element || !categoryColor) {
            return;
        }
        if (this.scheduleObj.currentView === 'Agenda') {
            (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }
    onActionFailure(eventData: any): void {
        this.alertService.error('Server exception: 404 Not found');
     }
    onClientClick(event, data) {
        console.log('dashboardid', data.clientId);
        this.router.navigate(['/clients/client'], {
            queryParams: { client_id: data.clientId },
        });
    }

    onOverDueTaskClick(event, data) {
        let taskData = this.OverDueTasksGrid.find((x) => x.taskId == data.taskId);
        let assigneeData = this.staffData.map((x) => {
            return x.firstName + ' ' + x.lastName;
        });
        this.routeToTaskDetails(data, taskData, assigneeData);
    }

    onPendingTaskClick(event, data) {
        let taskData = this.allPendingTaskGridData.find((x) => x.taskId == data.taskId);
        let assigneeData = this.staffData.map((x) => {
            return x.firstName + ' ' + x.lastName;
        });
        this.routeToTaskDetails(data, taskData, assigneeData);
    }

    routeToTaskDetails(data, taskData, assigneeData) {
        this.router.navigate(['/tasks/details'], {
            state: {
                taskId: data.taskId,
                taskData: taskData,
                assigneeData: assigneeData,
                staffData: this.staffData,
                assignee: data.staffName,
            },
        });
    }

    queryCellInfo(args: any): void {
        if (args.column.field == 'taskDaysRemaining') {
            if (args.data['isOverdue'] == true) {
                args.cell.classList.add('task-red');
            } else if (args.data['isTaskComingDue'] == true) {
                args.cell.classList.add('task-purple');
            } else {
                args.cell.classList.add('task-green');
            }
        }
        if (args.column.field == 'priority') {
            if (args.data.priority == 'Critical') {
                args.cell.classList.add('task-red');
            } else if (args.data.priority == 'High') {
                args.cell.classList.add('task-orange');
            } else if (args.data.priority == 'Medium') {
                args.cell.classList.add('task-blue');
            } else if (args.data.priority == 'Low') {
                args.cell.classList.add('task-green');
            }
        }
    }

    getClientCaseCount(model) {
        for (const client of model) {
            const openCasesCount: number = client?.clientCases?.filter(
                (caseItem) => caseItem.caseStatus === 'Open'
            )?.length;
            client.openCases = openCasesCount?.toString();
        }
        return model;
    } refreshAllTemplate(): void {
        this.scheduleObj?.refreshEvents();
        this.scheduleObj?.refreshLayout()
        this.scheduleObj?.refreshTemplates()
        this.scheduleObj?.refresh()
        this.cdr.detectChanges();
    }

    public btnClick() {
        let value = this.textboxEle.nativeElement.value;
        this.listObj.addItem([
            { text: 'Deandrea', contact: value, id: '2', avatar: '', pic: 'pic01', chat: 'receiver' },
        ]);
        this.textboxEle.nativeElement.value = '';
    }
}
