import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Subject, interval, Subscription } from 'rxjs';
import { takeUntil, switchMap, catchError, finalize, take } from 'rxjs/operators';
import { StaffService } from '../staff/services/staff.service';
import { AuthService } from '../login/service/auth.service';
import { ActivityService } from '../services/activity.service';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { ClientService } from '../client/services/client.services';
import { ReportService } from '../reports/services/report.service';
import { TaskService } from '../tasks/services/task.service';
import { ActivityDetailsModalComponent } from './components/activity-details-modal/activity-details-modal.component';
import { ErrorDetailsModalComponent } from './components/error-details-modal/error-details-modal.component';
import { ArchiveConfirmationModalComponent } from './components/archive-confirmation-modal/archive-confirmation-modal.component';
import { ClientsModel } from '../client/models/client.model';
import { TaskModel } from '../tasks/model/task.model';

// Syncfusion imports
import {
    GridModule,
    GridComponent,
    PageSettingsModel,
    FilterSettingsModel,
    ToolbarItems,
    SortSettingsModel,
    ExcelExportProperties,
    RowSelectEventArgs,
    RowDataBoundEventArgs,
    VirtualScrollService,
} from '@syncfusion/ej2-angular-grids';
import { DatePickerModule, DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule, DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { NgZorroAntdModule } from '../zorro.module';
import { AlertService } from '../alerts/alert.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DeviceService } from '../services/device.service';
import { LocationDrawerComponent } from './components/location-drawer/location-drawer.component';
import { ReportsModel } from '../reports/models/report.model';

interface StaffMember {
    staffId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    isActive: boolean;
}

interface ActionType {
    text: string;
    value: string;
}

interface ActivityStats {
    totalActions: number;
    activeSessions: number;
    avgSessionDuration: number;
    todaysActions: number;
}

@Component({
    selector: 'app-activity-dashboard',
    templateUrl: './activity-dashboard.component.html',
    styleUrls: ['./activity-dashboard.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        NzButtonModule,
        NzIconModule,
        NgZorroAntdModule,
        GridModule,
        DatePickerModule,
        DropDownListModule,
        ErrorDetailsModalComponent,
        ArchiveConfirmationModalComponent,
        ActivityDetailsModalComponent,
        LocationDrawerComponent,
    ],
    providers: [DatePipe, VirtualScrollService],
})
export class ActivityDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('grid') public grid: GridComponent;
    @ViewChild('startDate') public startDatePicker: DatePickerComponent;
    @ViewChild('endDate') public endDatePicker: DatePickerComponent;
    @ViewChild('staffDropdown') public staffDropdown: DropDownListComponent;
    @ViewChild('actionDropdown') public actionDropdown: DropDownListComponent;

    // Grid Properties
    public activityData: any[] = [];
    public pageSettings: PageSettingsModel;
    public filterOptions: FilterSettingsModel;
    public toolbarOptions: ToolbarItems[];
    public sortSettings: SortSettingsModel;

    // Filter Properties
    public staffMembers: StaffMember[] = [];
    public actionTypes: ActionType[] = [
        { text: 'Page Entry', value: 'PageEntry' },
        { text: 'Page Exit', value: 'PageExit' },
        { text: 'Login', value: 'Login' },
        { text: 'Failed Login', value: 'FailedLogin' },
        { text: 'Logout', value: 'Logout' },
        { text: 'Error', value: 'Error' },
        { text: 'Form Submission', value: 'FormSubmission' },
        { text: 'Download', value: 'Download' },
        { text: 'Upload', value: 'Upload' },
        { text: 'Search', value: 'Search' },
    ];

    // Browser filter options
    public browserTypes: { text: string; value: string }[] = [
        { text: 'Chrome', value: 'Chrome' },
        { text: 'Firefox', value: 'Firefox' },
        { text: 'Safari', value: 'Safari' },
        { text: 'Edge', value: 'Edge' },
        { text: 'Opera', value: 'Opera' },
        { text: 'Internet Explorer', value: 'Internet Explorer' },
    ];

    public deviceTypes: { text: string; value: string }[] = [
        { text: 'Desktop', value: 'Desktop' },
        { text: 'Mobile', value: 'Mobile' },
        { text: 'Tablet', value: 'Tablet' },
    ];
    public selectedStaff: string | null = null;
    public selectedAction: string | null = null;
    public selectedBrowser: string | null = null;
    public selectedDevice: string | null = null;
    public startDate: Date;
    public endDate: Date;

    // Stats Properties
    public totalActions: number = 0;
    public activeSessions: number = 0;
    public todaysActions: number = 0;
    public avgSessionDuration: string = '0m';
    public isLoading: boolean = true;

    private destroy$ = new Subject<void>();
    private statsPolling$: Subscription;
    private staffMap: Map<string, string> = new Map();
    private readonly POLLING_INTERVAL = 5000;
    private localTimezoneOffset = new Date().getTimezoneOffset() * 60000;

    public selectedStaffName: string = 'All Staff';

    // Add template for staff dropdown
    public staffDropdownTemplate: string = `
        <div class="staff-dropdown-item">
            <span class="staff-name">${'${fullName}'}</span>
            <span class="staff-status ${'{isActive ? "active" : "inactive"}'}"></span>
        </div>
    `;

    public gridOptions: any;

    // Add lookup maps for clients, reports, and tasks
    private clientMap: Map<string, ClientsModel> = new Map();
    private reportMap: Map<string, ReportsModel> = new Map();
    private taskMap: Map<string, TaskModel> = new Map();

    earliestLogDate: Date | null = null;
    hasActivityLogs: boolean = false;

    constructor(
        private http: HttpClient,
        private staffService: StaffService,
        private authService: AuthService,
        public activityService: ActivityService,
        private modal: NzModalService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private message: NzMessageService,
        private clientService: ClientService,
        private reportService: ReportService,
        private taskService: TaskService,
        private datePipe: DatePipe,
        private alertService: AlertService,
        private drawerService: NzDrawerService,
        private deviceService: DeviceService
    ) {
        this.pageSettings = { pageSize: 50 };
        this.filterOptions = { type: 'Menu' };
        this.toolbarOptions = ['Search', 'ExcelExport', 'PdfExport'];
        this.sortSettings = {
            columns: [{ field: 'timestamp', direction: 'Descending' }],
        };

        this.endDate = new Date();
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);

        this.initializeGrid();
    }

    ngOnInit(): void {
        this.loadStaffMembers();
        this.loadClients();
        this.loadReports();
        this.loadTasks();
        this.startStatsPolling();
        this.loadEarliestLogDate();
    }

    ngOnDestroy(): void {
        this.stopStatsPolling();
        this.destroy$.next();
        this.destroy$.complete();
        // Clean up any existing modal when component is destroyed
        if (this.currentErrorModal) {
            this.currentErrorModal.destroy();
            this.currentErrorModal = null;
        }
    }

    private startStatsPolling(): void {
        this.stopStatsPolling();

        this.statsPolling$ = interval(this.POLLING_INTERVAL)
            .pipe(
                takeUntil(this.destroy$),
                switchMap(() => this.fetchStats())
            )
            .subscribe(
                (stats) => {
                    if (this.isLoading) {
                        this.calculateLocalStats(this.activityData);
                        this.isLoading = false;
                    }
                },
                (error) => console.error('Error polling stats:', error)
            );
    }

    private stopStatsPolling(): void {
        if (this.statsPolling$) {
            this.statsPolling$.unsubscribe();
        }
    }

    private fetchStats() {
        const params = {
            userID: this.selectedStaff || '',
            startDate: this.startDate?.toISOString(),
            endDate: this.endDate?.toISOString(),
        };

        return this.http.get<ActivityStats>(`${environment.apiUrl}/activity/stats`, { params }).pipe(
            catchError((error) => {
                console.error('Error fetching stats:', error);
                return [];
            })
        );
    }

    private resetStats(): void {
        this.totalActions = 0;
        this.activeSessions = 0;
        this.todaysActions = 0;
        this.avgSessionDuration = '0m';
    }

    private loadStaffMembers(): void {
        this.staffService
            .getAllStaff()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (response) => {
                    // Filter for active staff only using string comparison
                    this.staffMembers = response.body
                        .filter((staff) => staff.isActive === 'Y')
                        .map((staff) => ({
                            staffId: staff.staffId,
                            firstName: staff.firstName,
                            lastName: staff.lastName,
                            fullName: `${staff.firstName} ${staff.lastName}`,
                            isActive: staff.isActive === 'Y',
                        }));

                    this.staffMembers.forEach((staff) => {
                        this.staffMap.set(staff.staffId, staff.fullName);
                    });
                    this.loadActivityData();
                },
                (error) => console.error('Error loading staff members:', error)
            );
    }

    private loadActivityData(): void {
        if (this.grid) {
            this.grid.showSpinner();
        }

        // Get today's start and end for accurate count
        const now = new Date();
        const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

        // First get today's count
        const todayFilters = {
            userID: this.selectedStaff || '',
            action: this.selectedAction || '',
            browser: this.selectedBrowser || '',
            device: this.selectedDevice || '',
            startDate: todayStart.toISOString(),
            endDate: todayEnd.toISOString(),
            pageSize: 10000, // Get all records for today
            includeCount: true,
        };

        // Get today's count first
        this.http
            .get<any>(`${environment.apiUrl}/activity/logs`, { params: todayFilters })
            .pipe(takeUntil(this.destroy$))
            .subscribe((todayResponse) => {
                // Update today's actions count from API total
                this.todaysActions = todayResponse.totalCount;

                // Now get the main data with original date range
                const filters = {
                    userID: this.selectedStaff || '',
                    action: this.selectedAction || '',
                    browser: this.selectedBrowser || '',
                    device: this.selectedDevice || '',
                    startDate: this.startDate?.toISOString(),
                    endDate: this.endDate?.toISOString(),
                    pageSize: 100000, // Get all records for virtual scrolling
                    includeCount: true,
                };

                this.http
                    .get<any>(`${environment.apiUrl}/activity/logs`, { params: filters })
                    .pipe(
                        takeUntil(this.destroy$),
                        finalize(() => {
                            if (this.grid) {
                                this.grid.hideSpinner();
                            }
                        })
                    )
                    .subscribe(
                        (response) => {
                            // Update total count from API
                            this.totalActions = response.totalCount;

                            const mappedData = this.mapActivityData(response.logs);

                            if (this.grid) {
                                this.grid.dataSource = mappedData;
                                this.grid.refresh();
                            }

                            this.activityData = mappedData;
                            this.isLoading = false;
                            this.calculateLocalStats(mappedData);
                            this.cdr.detectChanges();
                        },
                        (error) => {
                            console.error('Error loading activity data:', error);
                            this.message.error('Failed to load activity data. Please try again.');
                        }
                    );
            });
    }

    private calculateLocalStats(data: any[]): void {
        // Calculate active sessions (sessions with activity in last hour)
        const now = new Date();
        const oneHourAgo = new Date(now.getTime() - 3600000);

        // Group data by sessionID and calculate durations
        const sessions = new Map();
        data.forEach((item) => {
            if (item.sessionID) {
                const timestamp = new Date(item.timestamp);
                if (!sessions.has(item.sessionID)) {
                    sessions.set(item.sessionID, {
                        start: timestamp,
                        end: timestamp,
                        isActive: timestamp >= oneHourAgo,
                    });
                } else {
                    const session = sessions.get(item.sessionID);
                    if (timestamp < session.start) session.start = timestamp;
                    if (timestamp > session.end) session.end = timestamp;
                    if (timestamp >= oneHourAgo) session.isActive = true;
                }
            }
        });

        // Count active sessions
        this.activeSessions = Array.from(sessions.values()).filter((session) => session.isActive).length;

        // Calculate average session duration
        let totalDuration = 0;
        let sessionCount = 0;
        sessions.forEach((session) => {
            const duration = (session.end.getTime() - session.start.getTime()) / 60000; // Convert to minutes
            if (duration > 0) {
                totalDuration += duration;
                sessionCount++;
            }
        });

        const avgDuration = sessionCount > 0 ? totalDuration / sessionCount : 0;
        this.avgSessionDuration = this.formatDuration(avgDuration);
    }

    private mapActivityData(logs: any[]): any[] {
        if (!logs) return [];

        return logs.map((log) => {
            const metadata = log.metadata ? JSON.parse(log.metadata) : {};
            const browser = metadata.browser || {};
            const location = metadata.location || {};

            // Format browser info
            const browserInfo = browser.version
                ? `${browser.name || 'Unknown'} ${browser.version}`
                : browser.name || 'Unknown';

            // Format location info
            const locationInfo = this.formatLocationInfo(log);

            // Handle time spent
            const timeSpent = metadata.timeSpentSeconds
                ? this.formatTimeSpent(metadata.timeSpentSeconds)
                : '';

            // Enhanced page information with context
            const pageInfo = this.formatPagePath(log.page || metadata.page, metadata);

            return {
                timestamp: this.utcToLocal(new Date(log?.timestamp)),
                staffMember: this.staffMap.get(log?.userID) || '',
                action: log?.action,
                page: pageInfo.path,
                context: pageInfo.context,
                timeSpent: timeSpent,
                sessionID: log?.sessionID,
                userID: log?.userID,
                browserInfo,
                operatingSystem: browser.os || 'Unknown',
                deviceType: browser.device || 'Unknown',
                screenResolution: browser?.screenResolution || 'Unknown',
                locationInfo,
                ip: metadata?.ip || 'Unknown',
                error: log?.error,
                metadata: log?.metadata,
            };
        });
    }

    private formatTimeSpent(seconds: number): string {
        if (!seconds) return '';

        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        let result = '';

        if (hours > 0) {
            result += `${hours}h `;
        }
        if (minutes > 0 || hours > 0) {
            result += `${minutes}m `;
        }
        if (remainingSeconds > 0) {
            result += `${remainingSeconds}s`;
        }

        return result.trim();
    }

    private formatPagePath(page: string, metadata: any): { path: string; context: string } {
        if (!page) return { path: 'N/A', context: '' };

        let path = page;
        let context = '';

        // Extract route parameters and query parameters
        const params = metadata?.routeParams || {};
        const queryParams = metadata?.queryParams || {};

        // Handle client pages
        if (page.includes('client') && (params.id || queryParams.client_id)) {
            const clientId = params.id || queryParams.client_id;
            path = 'Client Details';
            context = `Client ID: ${clientId}`;
            // Note: In a real implementation, you might want to fetch the client name
            // but that would require an additional API call
        }

        // Handle report pages
        else if (page.includes('report') && (params.id || queryParams.report_id)) {
            const reportId = params.id || queryParams.report_id;
            path = 'Report View';
            context = `Report ID: ${reportId}`;
        }

        // Handle task pages
        else if (page.includes('task') && (params.id || queryParams.task_id)) {
            const taskId = params.id || queryParams.task_id;
            path = 'Task Details';
            context = `Task ID: ${taskId}`;
        }

        // Format other pages nicely
        else {
            path = page
                .split('/')
                .filter(Boolean)
                .map((segment) => segment.charAt(0).toUpperCase() + segment.slice(1))
                .join(' > ');
        }

        return { path, context };
    }

    private utcToLocal(utcDate: Date): Date {
        const localDate = new Date(utcDate.getTime() - this.localTimezoneOffset);
        return localDate;
    }

    public onRowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as any;
        if (data && data?.action === 'Error') {
            // Add error row styling
            (args.row as HTMLElement).classList.add('error-row');
        }
    }

    private formatDuration(minutes: number): string {
        if (minutes < 60) {
            return `${Math.round(minutes)}m`;
        }
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = Math.round(minutes % 60);
        return `${hours}h ${remainingMinutes}m`;
    }

    private formatDate(date: Date): string {
        return date
            .toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
            .replace(/\//g, '-');
    }

    private getExportFileName(): string {
        const userName = this.selectedStaff
            ? this.staffMap.get(this.selectedStaff) || 'All-Staff'
            : 'All-Staff';
        const dateRange = `${this.formatDate(this.startDate)}_to_${this.formatDate(this.endDate)}`;
        return `Activity_Log_${userName}_${dateRange}`;
    }

    private getExportTitle(): string {
        const userName = this.selectedStaff
            ? this.staffMap.get(this.selectedStaff) || 'All Staff'
            : 'All Staff';
        const dateRange = `${this.formatDate(this.startDate)} to ${this.formatDate(this.endDate)}`;
        return `Activity Log Report\n${userName}\n${dateRange}`;
    }

    // Event Handlers
    public onStaffChange(event: any): void {
        this.isLoading = true;
        if (this.grid) {
            this.grid.dataSource = [];
            this.grid.refresh();
        }

        if (event.itemData) {
            this.selectedStaff = event.itemData.staffId;
            this.selectedStaffName = event.itemData.fullName;
        } else {
            this.selectedStaff = null;
            this.selectedStaffName = 'All Staff';
        }

        // Update grid title using the correct property
        const titleElement = document.querySelector('.e-gridheader .e-headercontent .e-headertext');
        if (titleElement) {
            titleElement.textContent = `Activity Log - ${this.selectedStaffName}`;
        }

        this.loadActivityData();
    }

    public onActionChange(event: any): void {
        if (event.itemData) {
            this.selectedAction = event.itemData.value;
        } else {
            this.selectedAction = null;
        }
        this.loadActivityData();
    }

    public onBrowserChange(event: any): void {
        if (event.itemData) {
            this.selectedBrowser = event.itemData.value;
        } else {
            this.selectedBrowser = null;
        }
        this.loadActivityData();
    }

    public onDeviceChange(event: any): void {
        if (event.itemData) {
            this.selectedDevice = event.itemData.value;
        } else {
            this.selectedDevice = null;
        }
        this.loadActivityData();
    }

    public onDateChange(): void {
        if (this.startDate && this.endDate) {
            this.loadActivityData();
        }
    }

    public refreshGrid(): void {
        this.loadActivityData();
    }

    public toolbarClick(args: ClickEventArgs): void {
        switch (args.item.id) {
            case 'grid_excelexport': {
                const excelExportProperties: ExcelExportProperties = {
                    header: {
                        headerRows: 3,
                        rows: [
                            {
                                cells: [
                                    {
                                        colSpan: 5,
                                        value: this.getExportTitle(),
                                        style: {
                                            fontColor: '#000000',
                                            fontSize: 14,
                                            hAlign: 'Center',
                                            bold: true,
                                            wrapText: true,
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                    fileName: this.getExportFileName(),
                };
                this.grid.excelExport(excelExportProperties);
                break;
            }
            case 'grid_pdfexport':
                this.grid.pdfExport({
                    fileName: this.getExportFileName(),
                });
                break;
        }
    }

    public exportToCSV(): void {
        const filters = {
            userID: this.selectedStaff || '',
            action: this.selectedAction || '',
            startDate: this.startDate?.toISOString(),
            endDate: this.endDate?.toISOString(),
            format: 'csv',
        };

        this.http
            .get(`${environment.apiUrl}/activity/export`, {
                params: filters,
                responseType: 'blob',
            })
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                (blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    const fileName = this.getExportFileName();
                    link.href = url;
                    link.download = `${fileName}.csv`;
                    link.click();
                    window.URL.revokeObjectURL(url);
                },
                (error) => console.error('Error exporting CSV:', error)
            );
    }

    public getActionClass(action: string): string {
        const actionLower = action?.toLowerCase();
        switch (actionLower) {
            case 'pageentry':
                return 'view';
            case 'pageexit':
                return 'update';
            case 'login':
                return 'create';
            case 'failedlogin':
                return 'delete';
            case 'logout':
                return 'delete';
            case 'error':
                return 'error';
            case 'formsubmission':
                return 'create';
            case 'download':
                return 'view';
            case 'upload':
                return 'create';
            case 'search':
                return 'view';
            default:
                return '';
        }
    }

    // public showErrorDetails(data: any): void {
    //     const dialogRef = this.dialog.open(ActivityDetailsModalComponent, {
    //         width: '600px',
    //         data: {
    //             ...data,
    //             type: 'error',
    //             title: 'Error Details',
    //             clientName: this.clientMap.get(data.metadata?.clientId)?.clientFullName || 'Unknown Client',
    //             reportTitle: this.reportMap.get(data.metadata?.reportId)?.reportName || 'Unknown Report',
    //             taskTitle: this.taskMap.get(data.metadata?.taskId)?.title || 'Unknown Task'
    //         }
    //     });
    // }

    public showDetails(data: any): void {
        this.modal.create({
            nzTitle: 'Activity Details',
            nzContent: ActivityDetailsModalComponent,
            nzData: {
                ...data,
                type: 'details',
                title: 'Activity Details',
                duration: data?.metadata?.timeSpent,
                clientName: this.clientMap.get(data?.metadata?.clientId)?.clientFullName || 'Unknown Client',
                reportTitle: this.reportMap.get(data?.metadata?.reportId)?.reportName || 'Unknown Report',
                taskTitle: this.taskMap.get(data?.metadata?.taskId)?.title || 'Unknown Task',
            },
            nzFooter: null,
            nzWidth: '600px',
            nzMaskClosable: true,
            nzClassName: 'activity-details-modal',
        });
    }

    private formatActivityDetails(data: any): string {
        const staffName = data?.staffMember || 'Unknown Staff';
        const action = data?.action?.toLowerCase();
        const timestamp = this.datePipe.transform(data?.timestamp, 'M/d/y, h:mm a') || '';

        let entityName = 'Unknown';
        let pageType = data.page || 'Unknown Page';

        if (data.metadata?.clientId) {
            entityName = this.clientMap.get(data.metadata.clientId)?.clientFullName || 'Unknown Client';
            pageType = 'Client';
        } else if (data.metadata?.reportId) {
            entityName = this.reportMap.get(data.metadata.reportId)?.reportName || 'Unknown Report';
            pageType = 'Report';
        } else if (data.metadata?.taskId) {
            entityName = this.taskMap.get(data.metadata.taskId)?.title || 'Unknown Task';
            pageType = 'Task';
        }

        return `${staffName} ${action} ${entityName} on ${pageType} Screen at ${timestamp}`;
    }

    public rowSelected(args: RowSelectEventArgs): void {
        const selectedRecord = args.data as any;
        if (selectedRecord && selectedRecord?.action === 'Error') {
            this.showErrorDetails(selectedRecord);
        }
    }

    private currentErrorModal: any = null;
    private showErrorDetails(record: any): void {
        try {
            // If there's already a modal open, don't open another one
            if (this.currentErrorModal) {
                console.warn('Modal already open, skipping new modal');
                return;
            }

            const metadata = record.metadata ? JSON.parse(record.metadata) : {};
            const [browserName, browserVersion] = (record.browserInfo || '').split(' ');

            const errorDetails = {
                message: metadata.message || 'Unknown error',
                stackTrace: metadata.stackTrace || 'No stack trace available',
                timestamp: record.timestamp,
                status: metadata.status,
                statusText: metadata.statusText,
                url: metadata.url,
                method: metadata.method,
                page: metadata.page || record.page,
                context: {
                    page: metadata.page || record.page,
                    browser: {
                        name: browserName || metadata.browser?.name || 'Unknown',
                        version: browserVersion || metadata.browser?.version || '',
                        os: record.operatingSystem || metadata.browser?.os || 'Unknown',
                        device: record.deviceType || metadata.browser?.device || 'Unknown',
                        screenResolution:
                            record.screenResolution || metadata.browser?.screenResolution || 'Unknown',
                    },
                    location: metadata.location || {},
                    ipAddress: record.ipAddress || metadata.ipAddress,
                    metadata: metadata,
                },
            };

            const modalRef = this.modal.create({
                nzTitle: 'Error Details',
                nzContent: ErrorDetailsModalComponent,
                nzData: errorDetails,
                nzFooter: null,
                nzWidth: '600px',
                nzMaskClosable: true,
                nzClassName: 'activity-details-modal',
            });

            this.currentErrorModal = modalRef;

            // Handle navigation away from page
            const navigationSub = this.router.events
                .pipe(
                    takeUntil(modalRef.afterClose),
                    filter((event) => event instanceof NavigationStart)
                )
                .subscribe(() => {
                    if (this.currentErrorModal) {
                        this.currentErrorModal.close();
                        this.currentErrorModal = null;
                    }
                });

            // Handle modal closure
            modalRef.afterClose.pipe(take(1)).subscribe(() => {
                this.currentErrorModal = null;
                navigationSub.unsubscribe();
            });
        } catch (error) {
            console.error('Error showing modal:', error);
            if (this.currentErrorModal) {
                this.currentErrorModal.close();
                this.currentErrorModal = null;
            }
        }
    }

    openArchiveDialog(): void {
        if (!this.hasActivityLogs) {
            return; // Don't open modal if there are no logs
        }

        const modalRef = this.modal.create({
            nzTitle: null,
            nzFooter: null,
            nzClosable: false,
            nzMaskClosable: false,
            nzContent: ArchiveConfirmationModalComponent,
            nzData: {
                earliestLogDate: this.earliestLogDate,
                hasActivityLogs: this.hasActivityLogs,
            },
        });

        // Handle the modal result
        modalRef.afterClose.subscribe((result) => {
            if (result?.isArchiving) {
                this.archiveLogs(result.days);
            }
        });
    }

    private async archiveLogs(days: number) {
        try {
            const result = await this.activityService.archiveOldLogs(days).toPromise();
            if (result.success) {
                // Show success message
                this.alertService.success(`Successfully archived logs older than ${days} days`);
                // Refresh the grid data
                this.refreshGrid();
            }
        } catch (error) {
            console.error('Error archiving logs:', error);
            this.alertService.error('Failed to archive logs. Please try again.');
        }
    }

    private initializeGrid(): void {
        this.gridOptions = {
            enableVirtualization: true,
            height: 500,
            enableInfiniteScrolling: true,
            loadingIndicator: { indicatorType: 'Shimmer' },
            filterSettings: { type: 'Menu' },
            allowFiltering: true,
            filterOptions: this.filterOptions,
            columns: [
                {
                    field: 'timestamp',
                    headerText: 'Timestamp',
                    textAlign: 'left',
                    width: '160',
                    type: 'datetime',
                    format: 'M/d/y h:mm a',
                    allowFiltering: true,
                },
                {
                    field: 'staffMember',
                    headerText: 'Staff Member',
                    textAlign: 'left',
                    width: '150',
                    allowFiltering: true,
                },
                {
                    field: 'action',
                    headerText: 'Action',
                    textAlign: 'center',
                    width: '120',
                    template: '#actionTemplate',
                    allowFiltering: true,
                },
                {
                    field: 'page',
                    headerText: 'Page',
                    width: '180',
                    textAlign: 'left',
                    allowFiltering: true,
                },
                {
                    field: 'timeSpent',
                    headerText: 'Time Spent',
                    width: '100',
                    textAlign: 'center',
                    allowFiltering: true,
                },
                {
                    field: 'details',
                    headerText: 'Details',
                    width: '100',
                    template: '#detailsTemplate',
                    textAlign: 'center',
                    visible: true,
                    displayAsHidden: true,
                    valueAccessor: (field: string, data: any) => {
                        return data?.action === 'PageExit';
                    },
                },
                {
                    field: 'browserInfo',
                    headerText: 'Browser',
                    width: '150',
                    allowFiltering: true,
                },
                {
                    field: 'operatingSystem',
                    headerText: 'OS',
                    width: '120',
                    allowFiltering: true,
                },
                {
                    field: 'deviceType',
                    headerText: 'Device',
                    width: '100',
                    allowFiltering: true,
                },
                {
                    field: 'locationInfo',
                    headerText: 'Location',
                    width: 150,
                    template: this.locationTemplate,
                    textAlign: 'left'
                },
            ],
        };
    }

    private loadClients(): void {
        this.clientService.getAllClients().subscribe({
            next: (response: any) => {
                if (response && response.body) {
                    response.body.forEach((client: ClientsModel) => {
                        this.clientMap.set(client.clientId, client);
                    });
                }
            },
            error: (error) => {
                console.error('Error loading clients:', error);
            },
        });
    }

    private loadReports(): void {
        this.reportService.getAllTemplates().subscribe({
            next: (response: any) => {
                if (response && response.body) {
                    response.body.forEach((report: ReportsModel) => {
                        if (report.reportId) {
                            this.reportMap.set(report.reportId, report);
                        }
                    });
                }
            },
            error: (error) => {
                console.error('Error loading reports:', error);
            },
        });
    }

    private loadTasks(): void {
        this.taskService.getAllTask().subscribe({
            next: (response: any) => {
                if (response && response.body) {
                    response.body.forEach((task: TaskModel) => {
                        if (task.taskId) {
                            this.taskMap.set(task.taskId, task);
                        }
                    });
                }
            },
            error: (error) => {
                console.error('Error loading tasks:', error);
            },
        });
    }

    private async loadEarliestLogDate() {
        try {
            const result = await this.activityService.getEarliestLogDate().toPromise();
            this.earliestLogDate = result?.earliestDate ? new Date(result.earliestDate) : null;
            this.hasActivityLogs = result?.hasLogs || false;
        } catch (error) {
            console.error('Error loading earliest log date:', error);
        }
    }

    // Add a method to format location info
    private formatLocationInfo(data: any): string {
        const metadata = data.metadata ? JSON.parse(data.metadata) : {};
        const location = metadata.location || {};

        if (location.city && location.country) {
            return `${location.city}, ${location.country}`;
        } else if (metadata.ipAddress) {
            return metadata.ipAddress;
        }
        return 'Unknown';
    }

    getLocationTooltip(data: any): string {
        const metadata = data.metadata ? JSON.parse(data.metadata) : {};
        const location = metadata.location || {};

        if (!location.city && !location.country) {
            return metadata.ipAddress || 'Location information unavailable';
        }

        return `${location.city}, ${location.region}
${location.country}
${location.coordinates}
${location.isp}
IP: ${metadata.ipAddress}`;
    }

    locationTemplate = (args: any) => {
        const metadata = args.metadata ? JSON.parse(args.metadata) : {};
        const location = metadata.location || {};

        return `<div class="location-cell" onclick="this.openLocationDetails(args)">
            <i nz-icon nzType="environment" class="location-icon"></i>
            <span class="location-text">${this.formatLocationInfo(args)}</span>
        </div>`;
    }

    openLocationDetails(data: any): void {
        if (this.deviceService.isMobile) {
            this.openLocationDrawer(data);
        } else {
            // Show tooltip (handled by template)
        }
    }

    private openLocationDrawer(data: any): void {
        const metadata = data.metadata ? JSON.parse(data.metadata) : {};
        const location = metadata.location || {};

        const drawerRef = this.drawerService.create<LocationDrawerComponent>({
            nzContent: LocationDrawerComponent,
            nzPlacement: 'bottom',
            nzHeight: '80%',
            nzContentParams: {
                location: location,
                ipAddress: metadata.ipAddress,
                visible: true
            }
        });

        drawerRef.afterClose.subscribe(() => {
            // Handle any cleanup if needed
        });
    }
}
