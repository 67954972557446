import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../alerts/alert.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MatDialog } from '@angular/material/dialog';
import {
    ApplicationStatus,
    ApplicationStatusLabel,
    CompanyInformationModel,
    JobInformationModel,
    LocationInformationModel,
    VendorModel,
} from '../assessment/models/assessment.model';
import { AssessmentModalComponent } from '../assessment/modal/assessmentmodal.component';
import { VendorService } from './services/vendor.service';
import { AuthService } from '../login/service/auth.service';
import { User } from '../login/models/user';
import { GridComponent, ToolbarItems, SearchSettingsModel, PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-vendor',
    templateUrl: './vendorlist.component.html',
    styleUrls: ['./vendorlist.component.css'],
    standalone: false
})
export class VendorComponent implements OnInit {
    @ViewChild('grid') public grid: GridComponent;

    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public files: NgxFileDropEntry[] = [];
    public assessmentData: VendorModel[];
    public mappedAssessmentData: any[];
    public toolbarOptions: ToolbarItems[];
    public searchOptions: SearchSettingsModel;
    public pageSettings: PageSettingsModel;
    isMobile$: Observable<boolean>;
    private readonly MOBILE_BREAKPOINT = 1366;

    constructor(
        public vendorService: VendorService,
        @Inject(FormBuilder) private fb: FormBuilder,
        private authService: AuthService,
        private alertService: AlertService,
        public dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {
        this.toolbarOptions = ['Search', 'PdfExport', 'ExcelExport'];
        this.pageSettings = { pageSizes: true, pageSize: 10 };
        this.searchOptions = {
            fields: ['applicationNumber', 'applicationStatus', 'companyName', 'contactName', 'jobTitle'],
            operator: 'contains',
            key: '',
            ignoreCase: true
        };

        // Initialize mobile detection using breakpointObserver
        this.isMobile$ = this.breakpointObserver
            .observe([`(max-width: ${this.MOBILE_BREAKPOINT}px)`])
            .pipe(map(result => result.matches));

        // Update grid settings when breakpoint changes
        this.isMobile$.subscribe(isMobile => {
            if (this.grid) {
                if (isMobile) {
                    this.grid.pageSettings.pageSize = 5;
                    this.toolbarOptions = ['Search'];
                } else {
                    this.grid.pageSettings.pageSize = 10;
                    this.toolbarOptions = ['Search', 'PdfExport', 'ExcelExport'];
                }
                this.grid.refreshHeader();
            }
        });
    }

    ngOnInit(): void {
        this.loading = true;
        let vendor = new VendorModel();
        vendor.isNew = 0;
        var user = <User>JSON.parse(this.authService.getUser());
        vendor.viewedBy = `${user?.firstName} ${user?.lastName}`;
        vendor.dateViewed = new Date().toISOString();
        vendor.dateCreated = new Date().toISOString();
        this.vendorService.clearNewVendor(vendor).subscribe(() => {
            this.loadVendorData();
        });
    }

    loadVendorData() {
        this.vendorService.getAssessment().subscribe((data) => {
            this.assessmentData = data['body'];
            this.mappedAssessmentData = this.assessmentData.map((item) => ({
                applicationNumber: item.applicationNumber,
                applicationStatus: this.getApplicationStatus(item.applicationStatus),
                companyName: item.companyInformationModel?.companyName,
                contactName: item.companyInformationModel?.contactName,
                address: item.locationInformationModels?.[0]?.address1,
                city: item.locationInformationModels?.[0]?.city,
                jobTitle: item.jobInformationModel?.jobTitle,
                dateCreated: new Date(item.dateCreated),
                action: item
            }));
            this.loading = false;
        });
    }

    addAssessmentModal() {
        let model = new VendorModel();
        model.companyInformationModel = new CompanyInformationModel();
        model.jobInformationModel = new JobInformationModel();
        model.locationInformationModels = [];
        model.locationInformationModels[0] = new LocationInformationModel();
        this.openDialog(model, 'New Situational Site Application', 'Add');
    }

    openDialog(model: VendorModel, title: string, mode: string): void {
        this.files = [];
        let dialogRef = this.dialog.open(AssessmentModalComponent, {
            width: this.breakpointObserver.isMatched(`(max-width: ${this.MOBILE_BREAKPOINT}px)`) ? '100%' : '1140px',
            height: '700px',
            maxHeight: '80vh',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            data: { title: title, model: model, files: this.files, mode: mode },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            if (result.mode == 'Update') {
                this.onUpdateAssessment(result);
            }
        });
    }

    onUpdateAssessment(result) {
        this.submitted = true;
        this.alertService.clear();
        this.loading = true;

        this.vendorService.updateAssessment(result.form)
            .subscribe(
                (data) => {
                    this.assessmentData = data['VendorModels'];
                    this.loadVendorData();
                },
                (error) => {
                    this.alertService.error(error);
                    this.loading = false;
                }
            );
    }

    getApplicationStatus(applicationStatus: ApplicationStatus): any {
        let retval = '';
        ApplicationStatusLabel.forEach((label, value) => {
            if (value == applicationStatus) {
                retval = label;
            }
        });
        return retval;
    }

    toolbarClick(args: any): void {
        if (args.item.id === 'Grid_pdfexport') {
            this.grid.pdfExport();
        }
        if (args.item.id === 'Grid_excelexport') {
            this.grid.excelExport();
        }
    }

    rowSelected(args: any) {
        const data = args.data;
        this.openDialog(data.action, 'Approved Vendor Services', 'Update');
    }
}
