import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
    FormControl,
    FormGroup,
    FormGroupDirective,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { Observable, Observer, Subject, take } from 'rxjs';
import { VendorModel } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { CommonModule } from '@angular/common';
var _ = require('lodash');

@Component({
    selector: 'app-assessment-review',
    template: `
        <div class="review-container">
            <nz-page-header class="site-page-header" [nzTitle]="title">
            </nz-page-header>

            <nz-layout class="review-layout">
                <nz-content>
                    <div [ngClass]="{'mobile-view': isMobileView}" class="content-container">
                        <nz-collapse nzAccordion>
                            <nz-collapse-panel
                                *ngFor="let panel of panels"
                                [nzHeader]="panel.name"
                                [nzActive]="panel.active"
                                [nzDisabled]="panel.disabled"
                                [nzExtra]="extraTpl"
                                [ngStyle]="{ background: '#2c677b', color: 'white', marginBottom: '8px' }"
                            >
                                <nz-table
                                    #basicTable
                                    [nzData]="panel.tempControls"
                                    [nzShowPagination]="false"
                                    [nzFrontPagination]="false"
                                    [nzScroll]="{ y: isMobileView ? '50vh' : 'calc(100vh - 300px)' }"
                                >
                                    <tbody>
                                        <tr *ngFor="let ctrl of panel.tempControls; let i = index">
                                            <td [nzWidth]="'40%'" style="color: white">
                                                <strong>{{ ctrl.Title }}:</strong>
                                            </td>
                                            <td style="color: white">{{ ctrl.Value }}</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </nz-collapse-panel>
                        </nz-collapse>
                    </div>
                </nz-content>
            </nz-layout>
        </div>

        <ng-template #extraTpl>
            <span nz-icon nzType="edit" nzTheme="outline" (click)="goToScreen($event); $event.stopPropagation()"></span>
        </ng-template>
    `,
    styles: [`
        .review-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .review-layout {
            height: 100%;
            background: transparent;
        }

        .content-container {
            padding: 16px;
            max-width: 1200px;
            margin: 0 auto;
        }

        .mobile-view {
            padding: 8px;
        }

        :host ::ng-deep {
            .ant-collapse {
                background: transparent;
                border: none;
            }

            .ant-collapse-item {
                border-radius: 4px;
                margin-bottom: 8px;
                overflow: hidden;
            }

            .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                color: white !important;
                padding: 12px 16px;
                font-size: 16px;
                font-weight: 500;
            }

            .ant-table {
                background: transparent;
            }

            .ant-table-tbody > tr > td {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                transition: all 0.3s;
            }

            .ant-table-tbody > tr:hover > td {
                background: rgba(255, 255, 255, 0.1);
            }

            @media (max-width: 1366.98px) {
                .ant-collapse > .ant-collapse-item > .ant-collapse-header {
                    padding: 8px 12px;
                    font-size: 14px;
                }

                .ant-table-tbody > tr > td {
                    padding: 8px 12px;
                    font-size: 13px;
                }
            }
        }
    `],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NzCollapseModule,
        NzTableModule,
        NzIconModule,
        NzPageHeaderModule,
        NzLayoutModule,
        NzGridModule
    ]
})
export class AssessmentReviewSubmitComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput: EventEmitter<any> = new EventEmitter<any>();
    isMobileView = false;

    constructor(
        private service: AssessmentService
    ) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
        this.checkScreenSize();
        window.addEventListener('resize', () => this.checkScreenSize());
    }

    private checkScreenSize() {
        this.isMobileView = window.innerWidth <= 1366;
    }

    panels = [
        {
            active: true,
            name: 'Company Information',
            disabled: false,
            tempControls: [],
        },
        {
            active: false,
            disabled: false,
            name: 'Locations',
            tempControls: [],
        },
        {
            active: false,
            disabled: false,
            name: 'Job Information',
            tempControls: [],
        },
    ];

    ngOnInit(): void {
        this.title = 'Review Information';
        this.setPanels(this.model);
    }

    goToScreen(event) {
        if (event?.currentTarget?.parentElement?.parentElement?.textContent.length > 1) {
            let index = this.panels.indexOf(
                this.panels.find(
                    (object) => object.name == event?.currentTarget?.parentElement?.parentElement?.textContent
                )
            );
            this.assessmentOutput.emit(index);
        }
    }
    setPanels(model: VendorModel) {
        this.panels.forEach((panel) => {
            if (panel.name === 'Company Information') {
                Object.entries(model.companyInformationModel).forEach((entry) => {
                    panel.tempControls.push({
                        Title: _.startCase(entry[0]),
                        Value: entry[1],
                    });
                });
            } else if (panel.name === 'Job Information') {
                Object.entries(model.jobInformationModel).forEach((entry) => {
                    panel.tempControls.push({
                        Title: _.startCase(entry[0]),
                        Value: entry[1],
                    });
                });
            } else if (panel.name === 'Locations') {
                model.locationInformationModels.forEach((location) => {
                    Object.entries(location).forEach((entry) => {
                        panel.tempControls.push({
                            Title: _.startCase(entry[0]),
                            Value: entry[1],
                        });
                    });
                });
            }
        });
    }

    next() {}
}
