import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private bottomNavHeightSubject = new BehaviorSubject<number>(0);
    bottomNavHeight$ = this.bottomNavHeightSubject.asObservable();

    updateBottomNavHeight(height: number) {
        // Add 30px to the height as specified
        this.bottomNavHeightSubject.next(height + 80);
    }

    getBottomNavHeight(): Observable<number> {
        return this.bottomNavHeight$;
    }
}
