import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

@Component({
    selector: 'btn-cell-renderer',
    styles: [
        `
      :host ::ng-deep.anticon:hover {
        cursor: pointer !important;
      }
      :host ::ng-deep.anticon.anticon-delete {
        color: red !important;
      }
    `,
    ],
    template: `
    <span
      class="me-4"
      nz-icon
      nzType="edit"
      nzTheme="twotone"
      (click)="btnClickedHandler($event, 'Edit')"
    ></span>
    <span
      nz-popconfirm
      nzOkText="Yes"
      nzCancelText="No"
      nzPopconfirmTitle="Are you sure you want to delete this row?"
      (nzOnConfirm)="btnClickedHandler($event, 'Delete')"
      nzPopconfirmPlacement="top"
      nz-icon
      nzType="delete"
      nzTheme="outline"
    ></span>
    <ng-template #iconTpl>
      <span nz-icon nzType="question-circle-o" style="color: red;"></span>
    </ng-template>
  `,
    standalone: false
})
export class BtnCellRenderer implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams<any, any>): boolean {
    return true;
  }
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(event: any, mode: any) {
    this.params.clicked(this.params['data'], mode);
  }
}
