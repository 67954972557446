import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ActivityService } from '../../services/activity.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    protected baseUrl = environment.apiUrl;
    protected defaultHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(
        protected http: HttpClient,
        protected activityService: ActivityService
    ) {}

    protected get<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http
            .get<T>(`${this.baseUrl}${path}`, { headers: this.defaultHeaders, params })
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'GET'))
            );
    }

    protected post<T>(path: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http
            .post<T>(`${this.baseUrl}${path}`, body, { headers: this.defaultHeaders, params })
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'POST'))
            );
    }

    protected put<T>(path: string, body: any = {}, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http
            .put<T>(`${this.baseUrl}${path}`, body, { headers: this.defaultHeaders, params })
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'PUT'))
            );
    }

    protected delete<T>(path: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http
            .delete<T>(`${this.baseUrl}${path}`, { headers: this.defaultHeaders, params })
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'DELETE'))
            );
    }

    protected uploadFile<T>(path: string, formData: FormData): Observable<T> {
        return this.http
            .post<T>(`${this.baseUrl}${path}`, formData)
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'POST'))
            );
    }

    protected downloadFile(path: string, params: HttpParams = new HttpParams()): Observable<Blob> {
        return this.http
            .get(`${this.baseUrl}${path}`, {
                headers: this.defaultHeaders,
                params,
                responseType: 'blob'
            })
            .pipe(
                retry(1),
                catchError((error) => this.handleError(error, path, 'GET'))
            );
    }

    protected handleError(error: HttpErrorResponse, url: string, method: string) {
        // Check if this is an activity validation error
        if (url.includes('/activity') && error.error?.errors &&
            (error.error.errors.activities || error.error.errors['$[0].metadata'])) {
            // Just log to console, don't show alert
            console.error('Activity validation error:', error);
            return throwError(() => error);
        }

        let errorMessage = 'An error occurred';

        if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = error.error.message;
        } else {
            // Server-side error
            if (error.error?.message) {
                errorMessage = error.error.message;
            } else if (error.error?.errors) {
                errorMessage = Object.values(error.error.errors).join(', ');
            } else if (typeof error.error === 'string') {
                errorMessage = error.error;
            } else {
                errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
        }

        // Only log non-activity validation errors
        if (!url.includes('/activity')) {
            this.activityService.logError({
                message: error.error?.message || error.message || errorMessage,
                stack: error.error?.stackTrace || '',
                status: error.status,
                statusText: error.statusText,
                url: `${this.baseUrl}${url}`,
                method,
                page: 'ApiService'
            });
        }

        console.error('API Error:', error);
        return throwError(() => new Error(errorMessage));
    }
}
