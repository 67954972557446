import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationComponent } from './notification/notification.component';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AuthGuard } from './login/service/authguard';
import { JwtModule } from '@auth0/angular-jwt';
import { AngularMaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './common/dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { NgZorroAntdModule } from './zorro.module';
import { en_US, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { PortalModule } from '@angular/cdk/portal';
import { AlertService } from './alerts/alert.service';
import { AccountService } from './login/service/account.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { FileSizePipe } from './common/pipes/filesize.pipe';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BtnCellArchiveRenderer } from './common/buttoncell.archive.renderer';
import { BtnCellRenderer } from './common/buttoncell.renderer';
import { FileAttachmentService } from './common/fileattachment.service';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { provideUserIdleConfig } from 'angular-user-idle';
import { enUS } from 'date-fns/locale';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { RouterModule } from '@angular/router';
import { ClientService } from './client/services/client.services';
import { UtilityService } from './common/utility';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ReportService } from './reports/services/report.service';
import { StaffService } from './staff/services/staff.service';
import { NgxBackButtonModule, NgxBackButtonService } from 'ngx-back-button';
import { CommonCalendarComponent } from './calendar/components/common-calendar/common-calendar.component';
import { TooltipListPipe } from './common/pipes/tooltip.list.pipe';
import { ActivityInterceptor } from './interceptors/activity.interceptor';
import { MainNavigationModule } from './main-navigation/main-navigation.module';
import { VendorModule } from './vendor/vendor.module';
import { VendorService } from './vendorList/services/vendor.service';
import { AssessmentService } from './assessment/services/assessment.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UploadImageComponent } from './core/upload-image/upload-image.component';
import { UploadFileComponent } from './core/upload-file/upload-file.component';
import {
    DetailRowService,
    ExcelExportService,
    FilterService,
    GroupService,
    InfiniteScrollService,
    PageService,
    PdfExportService,
    SortService,
    ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { MaskedDateTimeService } from '@syncfusion/ej2-angular-calendars';
import {
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
} from '@syncfusion/ej2-angular-schedule';
import { jwtInterceptor } from './core/interceptors/jwt.interceptor';

export function tokenGetter() {
    return localStorage.getItem('JWT');
}
const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        CommonCalendarComponent,
        NotificationComponent,
        DialogComponent,
        FileSizePipe,
        BtnCellArchiveRenderer,
        BtnCellRenderer,
        UploadImageComponent,
        UploadFileComponent,
        TooltipListPipe,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireAnalyticsModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        NgZorroAntdModule,
        FontAwesomeModule,
        PortalModule,
        MatTabsModule,
        MatExpansionModule,
        NzDropDownModule,
        NgbDropdownModule,
        MainNavigationModule,
        VendorModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:7047', 'myworkatcornerstone.com'],
                disallowedRoutes: [
                    '/auth/login',
                    '/auth/forgotPass',
                    '/auth/ResetPassword',
                    '/Token/Refresh',
                ],
            },
        }),
        NgxBackButtonModule.forRoot({ rootUrl: '/dashboard' }),
        MarkdownModule.forRoot(),
    ],
    providers: [
        AuthGuard,
        UtilityService,
        AlertService,
        AccountService,
        VendorService,
        AssessmentService,
        FileAttachmentService,
        ClientService,
        StaffService,
        ReportService,
        TooltipListPipe,
        PageService,
        SortService,
        FilterService,
        GroupService,
        DetailRowService,
        PdfExportService,
        ExcelExportService,
        ToolbarService,
        MaskedDateTimeService,
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        InfiniteScrollService,
        provideNgxMask(),
        provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: icons },
        { provide: NZ_DATE_LOCALE, useValue: enUS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: REGION, useValue: 'us-central1' },
        NgxBackButtonService,
        provideHttpClient(withInterceptors([ErrorInterceptor, jwtInterceptor, ActivityInterceptor])),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    exports: [NgZorroAntdModule, AngularMaterialModule, TooltipListPipe],
})
export class AppModule {
    constructor(private i18n: NzI18nService, private nzConfigService: NzConfigService) {
        library.add(fab, far, fas);
        this.i18n.setDateLocale(enUS);
        this.nzConfigService.set('message', { nzMaxStack: 1 });
    }
}
