import { Injectable } from '@angular/core';
import { PreloadAllModules, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectivePreloadingStrategy implements PreloadAllModules {
    preloadedModules: string[] = [];

    preload(route: Route, load: () => Observable<any>): Observable<any> {
        if (route.data?.['preload'] === true) {
            // Add the route path to preloadedModules
            this.preloadedModules.push(route.path || '');
            console.log('Preloaded:', route.path);
            return load();
        } else {
            return of(null);
        }
    }
}
