import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GridModule, GridComponent, VirtualScrollService, ExcelExportProperties, RowDataBoundEventArgs } from '@syncfusion/ej2-angular-grids';
import { ActivityService } from '../services/activity.service';
import { StaffService } from '../staff/services/staff.service';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { LayoutService } from '../services/layout.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
interface ActionType {
    text: string;
    value: string;
}
@Component({
    selector: 'app-activity-archive',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        GridModule,
        DateRangePickerModule,
        DropDownListModule,
        NzButtonModule,
        NzIconModule,
        NzMessageModule
    ],
    providers: [VirtualScrollService],
    templateUrl: './activity-archive.component.html',
    styleUrls: ['./activity-archive.component.scss']
})
export class ActivityArchiveComponent implements OnInit, OnDestroy {
    @ViewChild('grid') public grid!: GridComponent;
    bottomNavHeight: number = 0;

    public data: any[] = [];
    public staffMembers: any[] = [];
    public selectedStaff: string | null = null;
    public selectedAction: string | null = null;
    public selectedBrowser: string | null = null;
    public selectedDevice: string | null = null;
    public startDate: Date;
    public endDate: Date;
    public dateRange: Date[] = [];
    public totalRecords: number = 0;
    public archiveDateRange: string = '';
    public totalArchiveSize: string = '0 MB';
    public isLoading: boolean = false;
    public hasData: boolean = false;
    private staffMap: Map<string, string> = new Map();

    public actionTypes: ActionType[] = [
        { text: 'Page Entry', value: 'PageEntry' },
        { text: 'Page Exit', value: 'PageExit' },
        { text: 'Login', value: 'Login' },
        { text: 'Failed Login', value: 'FailedLogin' },
        { text: 'Logout', value: 'Logout' },
        { text: 'Error', value: 'Error' },
        { text: 'Form Submission', value: 'FormSubmission' },
        { text: 'Download', value: 'Download' },
        { text: 'Upload', value: 'Upload' },
        { text: 'Search', value: 'Search' }
    ];
    public browserTypes: string[] = ['Chrome', 'Firefox', 'Safari', 'Edge', 'Opera', 'Other'];
    public deviceTypes: string[] = ['Desktop', 'Mobile', 'Tablet', 'Other'];
    public selectedStaffName: string = 'All Staff';

    // Add template for staff dropdown
    public staffDropdownTemplate: string = `
        <div class="staff-dropdown-item">
            <span class="staff-name">${'${fullName}'}</span>
            <span class="staff-status ${'{isActive ? "active" : "inactive"}'}"></span>
        </div>
    `;
    public gridOptions = {
        enableVirtualization: true,
        height: '100%',
        pageSettings: {
            pageSize: 50
        },
        filterSettings: {
            type: 'Excel'
        },
        toolbar: ['ExcelExport', 'PdfExport'],
        allowExcelExport: true,
        allowPdfExport: true
    };
    private destroy$ = new Subject<void>();

    constructor(
        private activityService: ActivityService,
        private staffService: StaffService,
        private message: NzMessageService,
        private layoutService: LayoutService
    ) {
        this.layoutService.getBottomNavHeight().subscribe(height => {
            this.bottomNavHeight = height;
        });
    }

    ngOnInit() {
        this.loadStaffMembers();
        this.loadActivityData();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private async loadStaffMembers() {
        try {
            this.staffService.getAllStaff()
            .pipe(takeUntil(this.destroy$))
            .subscribe(
                response => {
                    // Filter for active staff only using string comparison
                    this.staffMembers = response.body
                        .filter(staff => staff.isActive === 'Y')
                        .map(staff => ({
                            staffId: staff.staffId,
                            firstName: staff.firstName,
                            lastName: staff.lastName,
                            fullName: `${staff.firstName} ${staff.lastName}`,
                            isActive: staff.isActive === 'Y'
                        }));

                    this.staffMembers.forEach(staff => {
                        this.staffMap.set(staff.staffId, staff.fullName);
                    });
                    this.loadActivityData();
                },
                error => console.error('Error loading staff members:', error)
            );
        } catch (error) {
            this.message.error('Failed to load staff members');
        }
    }

    public async loadActivityData() {
        this.isLoading = true;
        try {
            const filterParams: any = {
                PageNumber: 1,
                PageSize: 50,
                SortField: "Timestamp",
                SortOrder: "DESC"
            };

            console.log(this.selectedStaff);
            if (this.selectedStaff) {
                filterParams.UserID = this.selectedStaff;
            }
            if (this.selectedBrowser) {
                filterParams.Browser = this.selectedBrowser;
            }
            if (this.selectedDevice) {
                filterParams.Device = this.selectedDevice;
            }
            if (this.dateRange && this.dateRange[0]) {
                filterParams.StartDate = this.dateRange[0].toISOString();
            }
            if (this.dateRange && this.dateRange[1]) {
                filterParams.EndDate = this.dateRange[1].toISOString();
            }

            const response = await this.activityService.getArchivedActivityLogs(filterParams).toPromise();

            if (response) {
                console.log('Response', response);
                this.data = response.logs.map((item: any) => {
                    const metadata = typeof item.metadata === 'string' ?
                        JSON.parse(item.metadata) : item.metadata;

                    const browser = metadata?.browser || {};
                    const browserInfo = browser.version ?
                        `${browser.name || 'Unknown'} ${browser.version}` :
                        (browser.name || 'Unknown');

                    const location = metadata?.location || {};
                    const locationInfo = location.city && location.country ?
                        `${location.city}, ${location.country}` :
                        (metadata.ipAddress || 'Unknown');

                    const timeSpent = metadata?.timeSpentSeconds ?
                        this.formatTimeSpent(metadata.timeSpentSeconds) : '';

                    return {
                        timestamp: new Date(item.timestamp),
                        staffMember: item.userName || 'Unknown',
                        action: item.action,
                        page: item.pageName,
                        timeSpent: timeSpent,
                        browserInfo: browserInfo,
                        operatingSystem: browser.os || 'Unknown',
                        deviceType: browser.device || 'Unknown',
                        locationInfo: locationInfo,
                        metadata: metadata
                    };
                });

                this.totalRecords = response.totalCount;
                this.hasData = this.data.length > 0;

                if (this.data.length > 0) {
                    const timestamps = this.data.map(item => new Date(item.timestamp).getTime());
                    const oldestDate = new Date(Math.min(...timestamps));
                    const newestDate = new Date(Math.max(...timestamps));
                    this.archiveDateRange = this.formatDateRange(oldestDate, newestDate);
                }

                const totalSize = this.data.reduce((acc, item) => {
                    const metadataSize = new Blob([JSON.stringify(item.metadata)]).size;
                    return acc + metadataSize;
                }, 0);
                this.totalArchiveSize = this.formatSize(totalSize);

                if (this.grid) {
                    this.grid.refresh();
                }
            }
        } catch (error) {
            this.message.error('Failed to load archived activity data');
            console.error('Error loading archived activity data:', error);
        } finally {
            this.isLoading = false;
        }
    }

    public refreshGrid(): void {
        this.loadActivityData();
        this.message.success('Grid refreshed successfully');
    }

    private formatTimeSpent(seconds: number): string {
        if (!seconds) return '';
        if (seconds < 60) return `${seconds}s`;
        const minutes = Math.floor(seconds / 60);
        if (minutes < 60) return `${minutes}m`;
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;
        return `${hours}h ${remainingMinutes}m`;
    }

    public clearFilters(): void {
        this.selectedStaff = null;
        this.selectedBrowser = null;
        this.selectedDevice = null;
        this.dateRange = [];

        const staffDropdown = document.querySelector('ejs-dropdownlist[placeholder="Select Staff Member"]') as any;
        const browserDropdown = document.querySelector('ejs-dropdownlist[placeholder="Select Browser"]') as any;
        const deviceDropdown = document.querySelector('ejs-dropdownlist[placeholder="Select Device"]') as any;
        const dateRangePicker = document.querySelector('ejs-daterangepicker') as any;

        if (staffDropdown) staffDropdown.value = null;
        if (browserDropdown) browserDropdown.value = null;
        if (deviceDropdown) deviceDropdown.value = null;
        if (dateRangePicker) dateRangePicker.value = null;

        this.loadActivityData();
        this.message.success('Filters cleared successfully');
    }

    public toolbarClick(args: any): void {
        if (!this.hasData) return;

        if (args.item.id.includes('excelexport')) {
            const excelProperties: ExcelExportProperties = {
                fileName: this.getExportFileName('excel'),
                header: {
                    headerRows: 1,
                    rows: [
                        {
                            cells: [
                                {
                                    colSpan: 5,
                                    value: 'Archived Activity Logs',
                                    style: { fontSize: 20, hAlign: 'Center', bold: true }
                                }
                            ]
                        }
                    ]
                }
            };
            this.grid.excelExport(excelProperties);
        } else if (args.item.id.includes('pdfexport')) {
            this.grid.pdfExport({
                fileName: this.getExportFileName('pdf')
            });
        }
    }

    private getExportFileName(type: string): string {
        const date = this.formatDateForFileName(new Date());
        return `archived_activity_logs_${date}.${type}`;
    }

    private formatDateForFileName(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    private formatDateRange(oldestDate: Date, newestDate: Date): string {
        return `${this.formatDate(oldestDate)} - ${this.formatDate(newestDate)}`;
    }

    private formatDate(date: Date): string {
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
    }

    private formatSize(bytes: number): string {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    public onStaffChange(event: any): void {
        this.isLoading = true;
        if (this.grid) {
            this.grid.dataSource = [];
            this.grid.refresh();
        }

        if (event.itemData) {
            this.selectedStaff = event.itemData.staffId;
            this.selectedStaffName = event.itemData.fullName;
        } else {
            this.selectedStaff = null;
            this.selectedStaffName = 'All Staff';
        }

        // Update grid title using the correct property
        const titleElement = document.querySelector('.e-gridheader .e-headercontent .e-headertext');
        if (titleElement) {
            titleElement.textContent = `Activity Log - ${this.selectedStaffName}`;
        }

        this.loadActivityData();
    }

    public onActionChange(event: any): void {
        if (event.itemData) {
            this.selectedAction = event.itemData.value;
        } else {
            this.selectedAction = null;
        }
        this.loadActivityData();
    }

    public onBrowserChange(event: any): void {
        if (event.itemData) {
            this.selectedBrowser = event.itemData.value;
        } else {
            this.selectedBrowser = null;
        }
        this.loadActivityData();
    }

    public onDeviceChange(event: any): void {
        if (event.itemData) {
            this.selectedDevice = event.itemData.value;
        } else {
            this.selectedDevice = null;
        }
        this.loadActivityData();
    }

    public onDateChange(event: any): void {
        if (this.dateRange[0]) {
            this.loadActivityData();
        }
    }
    public onRowDataBound(args: RowDataBoundEventArgs): void {
        const data = args.data as any;
        if (data && data?.action === 'Error') {
            // Add error row styling
            (args.row as HTMLElement).classList.add('error-row');
        }
    }
}