import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../login/service/auth.service';
import { ActivityService } from '../services/activity.service';

@Injectable({
    providedIn: 'root',
})
export class IdleTimerService {
    private idle = new BehaviorSubject<boolean>(false);
    private timeout = new BehaviorSubject<boolean>(false);
    private timer: any;
    private warningTimer: any;
    private timeoutMinutes = 15;
    private warningMinutes = 14;
    private readonly timeoutMs: number;
    private readonly warningMs: number;
    private isTimerActive = false;
    private finalTimeoutActive = false;
    private lastActivity: number = Date.now();
    private isResetting = false;

    idle$ = this.idle.asObservable();
    timeout$ = this.timeout.asObservable();

    constructor(
        private ngZone: NgZone,
        private authService: AuthService,
        private router: Router,
        private activityService: ActivityService
    ) {
        this.timeoutMs = this.timeoutMinutes * 60 * 1000;
        this.warningMs = this.warningMinutes * 60 * 1000;
    }

    startTimer() {
        if (!this.isTimerActive && !this.isResetting) {
            this.isTimerActive = true;
            this.lastActivity = Date.now();
            this.finalTimeoutActive = false;
            this.initializeWarningTimer();
        }
    }

    stopTimer() {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        if (this.warningTimer) {
            clearTimeout(this.warningTimer);
            this.warningTimer = null;
        }
        this.isTimerActive = false;
        this.finalTimeoutActive = false;
        this.idle.next(false);
        this.timeout.next(false);
    }

    resetTimer() {
        if (this.isTimerActive && !this.isResetting) {
            const now = Date.now();
            const timeSinceLastActivity = now - this.lastActivity;

            // Only reset if significant time has passed (prevent excessive resets)
            if (timeSinceLastActivity > 1000) {
                // 1 second threshold
                this.lastActivity = now;

                // If warning or timeout is active, do a full reset
                if (this.finalTimeoutActive || this.idle.value) {
                    this.isResetting = true;
                    this.stopTimer();
                    this.startTimer();
                    this.isResetting = false;
                } else {
                    // Otherwise just reset the warning timer
                    if (this.warningTimer) {
                        clearTimeout(this.warningTimer);
                    }
                    this.initializeWarningTimer();
                }
            }
        }
    }

    stayLoggedIn() {
        this.isResetting = true;
        // Clear all states
        this.idle.next(false);
        this.timeout.next(false);
        this.finalTimeoutActive = false;

        // Stop existing timers
        this.stopTimer();

        // Reset activity timestamp
        this.lastActivity = Date.now();

        // Start fresh timer cycle
        this.isTimerActive = true;
        this.initializeWarningTimer();

        this.isResetting = false;
    }

    // Make logout public so it can be called from app component
    logout(reason?: string) {
        this.stopTimer();
        // Log the auto-logout activity before actually logging out
        this.activityService.logLogout(true, reason);
        this.authService.logout().subscribe(() => {
            this.router.navigate(['/account/login']);
        });
    }

    private initializeWarningTimer() {
        if (this.isResetting) return;

        // Run timer outside Angular's zone to avoid triggering change detection
        this.ngZone.runOutsideAngular(() => {
            // Warning at 14 minutes
            this.warningTimer = setTimeout(() => {
                if (this.isResetting) return;

                const now = Date.now();
                const timeSinceLastActivity = now - this.lastActivity;

                // Only show warning if user has been inactive
                if (timeSinceLastActivity >= this.warningMs) {
                    this.ngZone.run(() => {
                        this.idle.next(true);
                        // Only start the final timeout if user hasn't responded to warning
                        if (!this.finalTimeoutActive && !this.isResetting) {
                            this.initializeFinalTimeout();
                        }
                    });
                } else {
                    // If user was active, reset the warning timer
                    clearTimeout(this.warningTimer);
                    this.initializeWarningTimer();
                }
            }, this.warningMs);
        });
    }

    private initializeFinalTimeout() {
        if (this.isResetting) return;

        this.finalTimeoutActive = true;
        // Run timer outside Angular's zone to avoid triggering change detection
        this.ngZone.runOutsideAngular(() => {
            // Final timeout at 1 minute after warning
            this.timer = setTimeout(() => {
                if (this.isResetting) return;

                const now = Date.now();
                const timeSinceLastActivity = now - this.lastActivity;

                // Only trigger timeout if user has been inactive for the full duration
                if (timeSinceLastActivity >= this.timeoutMs && !this.isResetting) {
                    this.ngZone.run(() => {
                        this.timeout.next(true);
                        this.logout('Session timeout after 15 minutes of inactivity'); // Call logout with reason when timeout occurs
                    });
                } else {
                    // If user was active, clear the timeout
                    clearTimeout(this.timer);
                    this.finalTimeoutActive = false;
                }
            }, this.timeoutMs - this.warningMs); // Only wait the remaining 1 minute
        });
    }
}
