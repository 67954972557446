import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActionEventArgs, EventRenderedArgs, EventSettingsModel, ScheduleAllModule, ScheduleComponent, View } from '@syncfusion/ej2-angular-schedule';

@Component({
    selector: 'app-common-calendar',
    templateUrl: './common-calendar.component.html',
    styleUrls: ['./common-calendar.component.css'],
    standalone: false
})

export class CommonCalendarComponent  implements OnInit{
  @ViewChild('scheduleObj') scheduleObj: ScheduleComponent;
  @Input() appointmentData :  object[] = [{}]
  @Input()  currentView: View = 'Month';
  @Output('dateType') dateType = new EventEmitter<string>()
  public agendaView: View = 'Agenda';
  public showWeekend: Boolean = true;
  public isReadOnly: number = 1;
  public selectedDate: Date = new Date();
  public eventSettings: EventSettingsModel = { dataSource: []};
  

  ngOnInit(): void {    
    this.eventSettings.dataSource = this.appointmentData 
  }

  oneventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data['CategoryColor'] as string;
    if (!args.element || !categoryColor) {
        return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
        (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
        args.element.style.backgroundColor = categoryColor;
    }
  }

  onViewChanged(viewTitle: ActionEventArgs){
    if(viewTitle.requestType === 'viewNavigate'){
      if(this.scheduleObj.activeView.viewClass.includes('e-agenda-view')){
        this.dateType.emit('Week')
      }else if(this.scheduleObj.activeView.viewClass.includes('e-month-view')){
        this.dateType.emit('Month')
      }
    }
  }
}
