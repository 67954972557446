import {
    AfterViewInit,
    Compiler,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { enableRipple } from '@syncfusion/ej2-base';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Subscription, fromEvent, merge } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccountService } from './login/service/account.service';
import { AuthService } from './login/service/auth.service';
import { IdleTimerService } from './common/idle-timer.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ClientService } from './client/services/client.services';
import { OverlayService } from './shared/services/overlay.service';
import { environment } from 'src/environments/environment';

enableRipple(true);
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [ClientService],
    standalone: false
})
export class AppComponent implements OnDestroy, AfterViewInit, OnInit {
    [x: string]: any;
    title = 'Cornerstone';
    showHead: boolean = false;
    private _idleSubscription: Subscription | null = null;
    private _activitySubscription: Subscription | null = null;
    private _timeoutSubscription: Subscription | null = null;
    private _overlaySubscription: Subscription | null = null;
    isOverlayVisible = false;
    overlayZIndex = 1000;
    @ViewChild('dockBar') dockBar?: SidebarComponent;
    public enableDock: boolean = true;
    public width: string = '250px';
    public dockSize: string = '60px';
    routes: any[];
    private _accountSubscription: Subscription | null = null;
    public urlValue: String;
    @ViewChild('sidebarInstance')
    public sidebarInstance: SidebarComponent;
    closeOnDocumentClick = true;
    private warningModal: any = null;
    private safariVHEventListener: (() => void) | null = null;

    constructor(
        private router: Router,
        private compiler: Compiler,
        private injector: Injector,
        private accountService: AccountService,
        private authService: AuthService,
        private idleTimer: IdleTimerService,
        private modal: NzModalService,
        public overlayService: OverlayService
    ) {
        // Subscribe to router events
        this.router.events.forEach((event) => {
            if (event instanceof NavigationEnd) {
                this.urlValue = event.url;
                // Allow public routes without authentication
                const publicRoutes = [
                    '/account/login',
                    '/account/forgot-password',
                    '/account/reset-password',
                ];
                if (!publicRoutes.some((route) => this.router.url.includes(route))) {
                    const token = localStorage.getItem('JWT');
                    const refreshToken = localStorage.getItem('RefreshToken');
                    const state = localStorage.getItem('STATE');

                    if (state === 'true' && token && refreshToken) {
                        // Valid session exists
                        this.showHead = true;
                        this.setupSubscriptions();
                        this.idleTimer.startTimer();
                    } else {
                        // No valid session
                        this.showHead = false;
                        this.destroySubscriptions();
                        this.idleTimer.stopTimer();
                        this.router.navigate(['/account/login']);
                    }
                } else {
                    // On public routes
                    this.showHead = false;
                    this.destroySubscriptions();
                    this.idleTimer.stopTimer();
                }
            }
        });
    }

    private setupSubscriptions() {
        // Clean up any existing subscriptions first
        this.destroySubscriptions();

        // Setup idle timer subscription
        if (!this._idleSubscription) {
            this._idleSubscription = this.idleTimer.idle$.subscribe((isIdle) => {
                if (isIdle) {
                    this.showIdleWarning();
                }
            });
        }

        // Setup timeout subscription
        if (!this._timeoutSubscription) {
            this._timeoutSubscription = this.idleTimer.timeout$.subscribe(() => {
                this.closeWarningModal();
            });
        }

        // Setup activity monitoring
        this.setupActivityMonitoring();

        // Setup overlay subscriptions
        this.setupOverlaySubscriptions();
    }

    private setupOverlaySubscriptions() {
        // Only setup if not already subscribed
        if (!this._overlaySubscription) {
            this._overlaySubscription = this.overlayService.isVisible().subscribe(
                visible => {
                    this.isOverlayVisible = visible;
                    console.log('overlayService.isVisible()', visible);
                    if (visible) {
                        document.body.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = 'auto';
                    }
                }
            );

            // Add z-index subscription
            this._overlaySubscription.add(
                this.overlayService.getZIndex().subscribe(
                    zIndex => {
                        this.overlayZIndex = zIndex;
                        console.log('overlayZIndex', this.overlayZIndex);
                    }
                )
            );
        }
    }

    ngOnInit() {
        const isLoggedIn = localStorage.getItem('STATE') === 'true';
        if (isLoggedIn) {
            this.setupSubscriptions();
            this.idleTimer.startTimer();
        }

        // Only apply viewport height fix for Safari and iOS
        const isSafari = () => {
            const ua = navigator.userAgent.toLowerCase();
            return (
                ua.includes('safari') &&
                !ua.includes('chrome') &&
                !ua.includes('android')
            ) || (
                // Check for iOS devices
                /iPad|iPhone|iPod/.test(navigator.platform) ||
                (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
            );
        };

        if (isSafari()) {
            const setVH = () => {
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            };

            setVH();
            window.addEventListener('resize', setVH);
            window.addEventListener('orientationchange', setVH);

            // Store the event listener for cleanup
            this.safariVHEventListener = setVH;
        }
    }

    private setupActivityMonitoring() {
        // Only setup if not already monitoring
        if (this._activitySubscription) {
            return;
        }

        // Combine all relevant user activity events
        const mouseEvents = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'mouseup'),
            fromEvent(document, 'click'),
            fromEvent(document, 'contextmenu'),
            fromEvent(document, 'dblclick')
        );

        const keyboardEvents = merge(
            fromEvent(document, 'keypress'),
            fromEvent(document, 'keydown'),
            fromEvent(document, 'keyup')
        );

        const touchEvents = merge(
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'touchend'),
            fromEvent(document, 'touchmove')
        );

        const scrollEvents = fromEvent(document, 'scroll');
        const wheelEvents = fromEvent(document, 'wheel');

        // Combine all events and debounce to prevent excessive timer resets
        this._activitySubscription = merge(
            mouseEvents,
            keyboardEvents,
            touchEvents,
            scrollEvents,
            wheelEvents
        )
            .pipe(debounceTime(300)) // Wait for 300ms of inactivity before triggering
            .subscribe(() => {
                if (this.authService.isLoggedIn()) {
                    this.idleTimer.resetTimer();
                }
            });
    }

    private destroySubscriptions(): void {
        if (this._idleSubscription) {
            this._idleSubscription.unsubscribe();
            this._idleSubscription = null;
        }
        if (this._activitySubscription) {
            this._activitySubscription.unsubscribe();
            this._activitySubscription = null;
        }
        if (this._timeoutSubscription) {
            this._timeoutSubscription.unsubscribe();
            this._timeoutSubscription = null;
        }
        if (this._overlaySubscription) {
            this._overlaySubscription.unsubscribe();
            this._overlaySubscription = null;
        }
        if (this._accountSubscription) {
            this._accountSubscription.unsubscribe();
            this._accountSubscription = null;
        }
    }

    private closeWarningModal() {
        if (this.warningModal) {
            this.warningModal.close();
            this.warningModal = null;
        }
    }

    showIdleWarning() {
        // If there's already a warning modal, don't show another one
        if (this.warningModal) {
            return;
        }

        this.warningModal = this.modal.confirm({
            nzTitle: 'Session Timeout Warning',
            nzContent: 'Your session will expire in 1 minute due to inactivity. Would you like to continue?',
            nzOkText: 'Yes, keep me signed in',
            nzCancelText: 'No, log me out',
            nzOnOk: () => {
                this.idleTimer.stayLoggedIn();
                this.warningModal = null;
            },
            nzOnCancel: () => {
                this.closeWarningModal();
                this.destroySubscriptions(); // Ensure subscriptions are destroyed on logout
                this.idleTimer.logout('User chose to logout from inactivity warning');
            },
            nzClosable: false,
            nzMaskClosable: false,
        });
    }

    toggleClick(): void {
        (this.sidebarInstance as any).toggle();
    }

    OnSelect(args: any) {
        document.getElementById('listContent').innerHTML = args.data.description;
    }

    ngOnDestroy(): void {
        this.destroySubscriptions();
        this.closeWarningModal();

        // Only remove listeners if they were added
        if (this.safariVHEventListener) {
            window.removeEventListener('resize', this.safariVHEventListener);
            window.removeEventListener('orientationchange', this.safariVHEventListener);
        }
    }

    OnClose(args: any) {
        if (args.event?.target.className == 'fa-solid fa-bars') {
            args.cancel = true;
        }
    }

    onCreated() {
        if (this.sidebarInstance.element.classList.contains('e-hidden')) {
            this.sidebarInstance.element.classList.remove('e-hidden');
            this.checkURL();
        }
    }

    checkURL() {
        !this.urlValue || this.urlValue !== '/' ? this.sidebarInstance.hide() : this.sidebarInstance.show();
    }

    ngAfterViewInit() {
        this.sidebarInstance?.hide(); // Always hide sidebar on initialization

        // Only log in production
        if (environment.production) {
            console.log('Z-Index Debug Information:');

            // Check CDK Overlay
            const overlay = document.querySelector('.cdk-overlay-container');
            if (overlay) {
                console.log('CDK Overlay z-index:', getComputedStyle(overlay).zIndex);
            }

            // Check Modal
            const modal = document.querySelector('.modal');
            if (modal) {
                console.log('Modal z-index:', getComputedStyle(modal).zIndex);
            }

            // Check Header
            const header = document.querySelector('header');
            if (header) {
                console.log('Header z-index:', getComputedStyle(header).zIndex);
            }

            // Check Dropdowns
            const dropdowns = document.querySelectorAll('[ngbDropdown]');
            dropdowns.forEach(dropdown => {
                console.log('Dropdown z-index:', getComputedStyle(dropdown).zIndex);
            });
        }
    }
}
