import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { finalize, takeUntil } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StaffModel } from '../staff/model/staff.model';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../login/service/auth.service';
import packageJson from '../../../package.json';

// Ng-Zorro imports
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { StaffService } from '../staff/services/staff.service';

// Maskito imports
import { MaskitoDirective } from '@maskito/angular';
import { MaskitoElementPredicate, MaskitoOptions, MaskitoPreprocessor } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: [
        './settings.component.scss',
        './settings.mobile.scss'
    ],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        NzCardModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzIconModule,
        NzDatePickerModule,
        NzProgressModule,
        NzAvatarModule,
        NzToolTipModule,
        MaskitoDirective
    ]
})
export class SettingsComponent implements OnInit, OnDestroy {
    profileForm: FormGroup;
    loading = false;
    avatarLoading = false;
    currentStaff: StaffModel | null = null;
    isMobile = false;
    hasChanges = false;
    private destroy$ = new Subject<void>();
    private readonly MOBILE_BREAKPOINT = 1366;
    public disabledDate = (current: Date): boolean => current > new Date();
    dateFormat = 'MM/dd/yyyy';
    today = new Date();
    startAt: Date;
    maskOptions: MaskitoOptions = maskitoDateOptionsGenerator({
        mode: 'mm/dd/yyyy',
        separator: '/',
        max: new Date(),
    });
    readonly elementPredicate: MaskitoElementPredicate = (el) => el as HTMLInputElement;
    readonly phoneMask: MaskitoOptions = {
        mask: ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        preprocessors: [
            (data: { elementState: any; data: string }) => ({
                elementState: data.elementState,
                data: data.data.replace(/\D/g, '')
            }),
        ],
    };
    version = packageJson.version;

    constructor(
        private fb: FormBuilder,
        private staffService: StaffService,
        private message: NzMessageService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        public authService: AuthService
    ) {
        this.initializeForm();
        this.setupResponsiveObserver();
    }

    ngOnInit(): void {
        this.loadCurrentStaff();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private setupResponsiveObserver(): void {
        this.breakpointObserver.observe([
            `(max-width: ${this.MOBILE_BREAKPOINT}px)`
        ]).pipe(
            takeUntil(this.destroy$)
        ).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    private initializeForm(): void {
        this.profileForm = this.fb.group({
            firstName: ['', Validators.required],
            middleName: [''],
            lastName: ['', Validators.required],
            staffColor: ['#009ef7'],
            dateofBirth: [null],
            mobilePhone: ['', Validators.required],
            officePhone: [''],
            emailAddress: [{ value: '', disabled: true }],
            staffNumber: [{ value: '', disabled: true }],
            staffTitle: [{ value: '', disabled: true }],
            titlesCertifications: [{ value: '', disabled: true }],
            titlesName: [{ value: '', disabled: true }],
            hireDate: [{ value: null, disabled: true }]
        });

        // Track form changes
        this.profileForm.valueChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.checkFormChanges();
        });

        // Also track status changes (for validation)
        this.profileForm.statusChanges.pipe(
            takeUntil(this.destroy$)
        ).subscribe(() => {
            this.checkFormChanges();
        });
    }

    private checkFormChanges(): void {
        if (!this.currentStaff) return;

        const formValue = this.profileForm.getRawValue();

        // Debug form state
        console.log('Form State:', {
            valid: this.profileForm.valid,
            dirty: this.profileForm.dirty,
            touched: this.profileForm.touched,
            errors: this.profileForm.errors,
            firstNameErrors: this.profileForm.get('firstName')?.errors,
            lastNameErrors: this.profileForm.get('lastName')?.errors,
            mobilePhoneErrors: this.profileForm.get('mobilePhone')?.errors,
            formValue: formValue
        });

        // Helper function to compare values, handling null/undefined/empty string cases
        const hasValueChanged = (currentValue: any, newValue: any): boolean => {
            // Handle empty string and null/undefined cases
            if (currentValue === null || currentValue === undefined) currentValue = '';
            if (newValue === null || newValue === undefined) newValue = '';

            // Special handling for date of birth to prevent false triggers
            if (currentValue instanceof Date || newValue instanceof Date) {
                // If either value is null/empty after the above normalization, they're different only if the other has a value
                if (currentValue === '' || newValue === '') {
                    return currentValue !== newValue;
                }

                // Convert string dates to Date objects for comparison
                const currentDate = currentValue instanceof Date ? currentValue : new Date(currentValue);
                const newDate = newValue instanceof Date ? newValue : new Date(newValue);

                // Compare the actual date values
                return currentDate.getTime() !== newDate.getTime();
            }

            // For non-date values, convert to string and compare
            return String(currentValue).trim() !== String(newValue).trim();
        };

        const changes = {
            firstName: hasValueChanged(this.currentStaff.firstName, formValue.firstName),
            middleName: hasValueChanged(this.currentStaff.middleName, formValue.middleName),
            lastName: hasValueChanged(this.currentStaff.lastName, formValue.lastName),
            staffColor: hasValueChanged(this.currentStaff.staffColor, formValue.staffColor),
            dateofBirth: hasValueChanged(this.currentStaff.dateofBirth, formValue.dateofBirth),
            mobilePhone: hasValueChanged(this.currentStaff.mobilePhone, formValue.mobilePhone),
            officePhone: hasValueChanged(this.currentStaff.officePhone, formValue.officePhone)
        };

        this.hasChanges = Object.values(changes).some(changed => changed);

        // Debug changes and button state
        console.log('Changes detected:', changes);
        console.log('Save button should be enabled:', this.profileForm.valid && this.hasChanges);
    }

    private loadCurrentStaff(): void {
        this.loading = true;
        const staffId = this.authService.getStaffId();

        this.staffService.getStaffById(staffId).pipe(
            finalize(() => this.loading = false),
            takeUntil(this.destroy$)
        ).subscribe({
            next: (staff: StaffModel) => {
                this.currentStaff = staff;
                this.updateFormWithStaffData(staff);
            },
            error: () => this.showError('Error loading profile')
        });
    }

    private updateFormWithStaffData(staff: StaffModel): void {
        this.profileForm.patchValue({
            firstName: staff.firstName,
            middleName: staff.middleName,
            lastName: staff.lastName,
            staffColor: staff.staffColor || '#009ef7',
            dateofBirth: staff.dateofBirth ? new Date(staff.dateofBirth) : null,
            mobilePhone: staff.mobilePhone,
            officePhone: staff.officePhone,
            emailAddress: staff.emailAddress,
            staffNumber: staff.staffNumber,
            staffTitle: staff.staffTitle,
            titlesCertifications: staff.titlesCertifications,
            titlesName: staff.titlesName,
            hireDate: staff.hireDate ? new Date(staff.hireDate) : null
        }, { emitEvent: true }); // Ensure form change detection runs after setting initial values
    }

    onAvatarChange(event: any): void {
        const file = event.target.files[0];
        if (file) {
            if (!this.validateImageFile(file)) return;

            const formData = new FormData();
            formData.append('files', file);

            this.avatarLoading = true;
            this.staffService.uploadAvatarAttachment([file], null, this.currentStaff?.staffId).pipe(
                finalize(() => this.avatarLoading = false),
                takeUntil(this.destroy$)
            ).subscribe({
                next: response => {
                     console.log('avatar response', response);
                    if (response && this.currentStaff) {
                        console.log('current staff', this.currentStaff);
                        this.currentStaff.profileImage = response.uri;
                        this.staffService.updateStaff(this.currentStaff).subscribe({
                            next: () => {
                                this.showSuccess('Profile picture updated successfully');
                            },
                            error: () => this.showError('Error updating profile')
                        });
                        this.authService.setUserImage(response.uri);
                    }
                },
                error: () => this.showError('Error uploading profile picture')
            });
        }
    }

    private validateImageFile(file: File): boolean {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        if (!allowedTypes.includes(file.type)) {
            this.showError('Please upload an image file (JPEG, PNG, or GIF)');
            return false;
        }

        if (file.size > 5 * 1024 * 1024) { // 5MB limit
            this.showError('Image size should be less than 5MB');
            return false;
        }

        return true;
    }

    onSave(): void {
        if (this.profileForm.valid && this.currentStaff && this.hasChanges) {
            this.loading = true;
            const formValue = this.profileForm.getRawValue();

            const updatedStaff: StaffModel = {
                ...this.currentStaff,
                firstName: formValue.firstName,
                middleName: formValue.middleName,
                lastName: formValue.lastName,
                staffColor: formValue.staffColor,
                dateofBirth: formValue.dateofBirth,
                mobilePhone: formValue.mobilePhone,
                officePhone: formValue.officePhone
            };

            this.staffService.updateStaff(updatedStaff).pipe(
                finalize(() => this.loading = false)
            ).subscribe({
                next: () => {
                    this.showSuccess('Profile updated successfully');
                    this.loadCurrentStaff();
                    this.hasChanges = false;
                },
                error: () => this.showError('Error updating profile')
            });
        }
    }

    onCancel(): void {
       this.router.navigate(['/dashboard']);
    }

    getAvatarUrl(): SafeUrl {
        return this.authService.getUserImage();
    }

    private showSuccess(message: string): void {
        this.message.success(message);
    }

    private showError(message: string): void {
        this.message.error(message);
    }

    onColorChange(color: string): void {
        // Ensure color is in correct hex format
        const hexColor = color.startsWith('#') ? color : `#${color}`;
        this.profileForm.patchValue({ staffColor: hexColor }, { emitEvent: true });
    }

    // Helper method to get color help text
    getColorHelpText(): string {
        return 'Choose your identifying color for appointments and calendars across the application. This helps you quickly spot your scheduled events.';
    }

    onDeleteAvatar(): void {
        if (this.currentStaff?.profileImage) {
            this.avatarLoading = true;
            this.staffService.deleteAvatar(this.currentStaff.profileImage).pipe(
                finalize(() => this.avatarLoading = false),
                takeUntil(this.destroy$)
            ).subscribe({
                next: (success) => {
                    if (success) {
                        this.currentStaff!.profileImage = '';
                        this.authService.setUserImage('');
                        this.staffService.updateStaff(this.currentStaff).subscribe({
                            next: () => {
                                this.showSuccess('Profile picture removed successfully');
                            },
                            error: () => this.showError('Error removing profile picture')
                        });
                    } else {
                        this.showError('Error removing profile picture');
                    }
                },
                error: () => this.showError('Error removing profile picture')
            });
        }
    }

    // Add new helper method for full name
    getFullName(): string {
        if (!this.currentStaff) return '';
        return `${this.currentStaff.firstName} ${this.currentStaff.lastName}`;
    }

    // Add new helper method for staff info
    getStaffInfo(): string {
        if (!this.currentStaff) return '';
        return `${this.currentStaff.staffTitle || 'Staff Member'} · ${this.currentStaff.staffNumber}`;
    }
}
