import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NzModalRef, NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDividerModule } from 'ng-zorro-antd/divider';

interface ErrorDetails {
  message: string;
  stackTrace: string;
  timestamp: Date;
  status?: number;
  statusText?: string;
  url?: string;
  method?: string;
  page?: string;
  context: {
    browser: {
      name: string;
      version: string;
      os: string;
      device: string;
      screenResolution: string;
    };
    location?: {
      city?: string;
      region?: string;
      country?: string;
      timezone?: string;
    };
    ip?: string;
    page?: string;
    metadata?: any;
  };
}

@Component({
  selector: 'app-error-details-modal',
  template: `
    <div class="error-modal">
      <div class="modal-content" *ngIf="!isLoading">
        <div class="error-section">
          <h3>Error Message</h3>
          <div class="error-message">
            {{ errorDetails.message }}
          </div>
        </div>

        <nz-divider></nz-divider>

        <div class="stack-trace-section">
          <h3>Stack Trace</h3>
          <pre class="stack-trace">{{ errorDetails.stackTrace }}</pre>
        </div>

        <nz-divider></nz-divider>

        <div class="context-section">
          <h3>Context Information</h3>

          <div class="context-grid">
            <div class="context-item">
              <span class="label">Status:</span>
              <span class="value">{{ errorDetails.status }} {{ errorDetails.statusText }}</span>
            </div>

            <div class="context-item">
              <span class="label">URL:</span>
              <span class="value">{{ errorDetails.url }}</span>
            </div>

            <div class="context-item">
              <span class="label">Method:</span>
              <span class="value">{{ errorDetails.method }}</span>
            </div>

            <div class="context-item">
              <span class="label">Page:</span>
              <span class="value">{{ errorDetails.page || errorDetails.context.page }}</span>
            </div>

            <div class="context-item">
              <span class="label">Browser:</span>
              <span class="value">{{ errorDetails.context.browser.name }} {{ errorDetails.context.browser.version }}</span>
            </div>

            <div class="context-item">
              <span class="label">Operating System:</span>
              <span class="value">{{ errorDetails.context.browser.os }}</span>
            </div>

            <div class="context-item">
              <span class="label">Device:</span>
              <span class="value">{{ errorDetails.context.browser.device }}</span>
            </div>

            <div class="context-item">
              <span class="label">Location:</span>
              <span class="value">
                {{ errorDetails.context.location?.city }},
                {{ errorDetails.context.location?.country }}
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="anthropicSolution" class="solution-section">
          <nz-divider></nz-divider>
          <h3>Proposed Solution</h3>
          <div class="solution-content">
            {{ anthropicSolution }}
          </div>
        </div>
      </div>

      <div *ngIf="isLoading" class="loading-container">
        <nz-spin nzSize="large"></nz-spin>
      </div>

      <div class="modal-footer">
        <button nz-button (click)="copyToClipboard()">
          <span nz-icon nzType="copy"></span>
          Copy Details
        </button>
        <button nz-button nzType="primary" (click)="sendToSupport()" [disabled]="isLoading">
          <span nz-icon nzType="mail"></span>
          Send to Support
        </button>
        <button nz-button (click)="close()">Close</button>
      </div>
    </div>
  `,
  styles: [`
    .error-modal {
      padding: 24px;
    }

    .modal-header {
      margin-bottom: 20px;
    }

    .modal-header h2 {
      margin: 0;
      color: #2c3e50;
    }

    .error-section, .stack-trace-section, .context-section, .solution-section {
      margin-bottom: 20px;
    }

    h3 {
      color: #2c3e50;
      margin-bottom: 12px;
    }

    .error-message {
      padding: 12px;
      background-color: #fff1f0;
      border: 1px solid #ffccc7;
      border-radius: 4px;
      color: #cf1322;
    }

    .stack-trace {
      padding: 12px;
      background-color: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      overflow-x: auto;
      font-family: monospace;
      white-space: pre-wrap;
      margin: 0;
    }

    .context-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 16px;
    }

    .context-item {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .label {
      color: #8c8c8c;
      font-size: 0.9em;
    }

    .value {
      color: #262626;
    }

    .solution-content {
      padding: 16px;
      background-color: #f6ffed;
      border: 1px solid #b7eb8f;
      border-radius: 4px;
    }

    .loading-container {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
    }

    .modal-footer {
      margin-top: 24px;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    NzButtonModule,
    NzSpinModule,
    NzIconModule,
    NzDividerModule
  ]
})
export class ErrorDetailsModalComponent {
  errorDetails: ErrorDetails;
  isLoading = false;
  anthropicSolution: string = '';
  messageCollapsed = false;
  stackTraceCollapsed = true;

  constructor(
    private modal: NzModalRef,
    private http: HttpClient,
    private message: NzMessageService,
    private clipboard: Clipboard,
    private cdr: ChangeDetectorRef,
    @Inject(NZ_MODAL_DATA) data: ErrorDetails
  ) {
    this.errorDetails = data;
  }

  ngOnInit() {
    console.log('incoming errorDetails', this.errorDetails);
    this.cdr.detectChanges();
  }

  copyToClipboard() {
    const textToCopy = `Error Message: ${this.errorDetails.message}\n\nStack Trace: ${this.errorDetails.stackTrace}`;
    this.clipboard.copy(textToCopy);
    this.message.success('Copied to clipboard');
  }

  async sendToSupport() {
    this.isLoading = true;
    try {
      // Get solution from Anthropic
      interface AnthropicResponse {
        content: Array<{ text: string }>;
      }

      const anthropicResponse = await this.http.post<AnthropicResponse>(`${environment.apiUrl}/Default/SendMessageToAnthropic`, {
        model: 'claude-3-5-haiku-latest',
        max_tokens: 8192,
        temperature: 1,
        messages: [{
          role: 'user',
          content: [{
            type: 'text',
            text: `As a software expert, please analyze this error and provide a clear, concise solution:
Error Message: ${this.errorDetails.message}
Stack Trace: ${this.errorDetails.stackTrace}
Status: ${this.errorDetails.status} ${this.errorDetails.statusText}
URL: ${this.errorDetails.url}
Method: ${this.errorDetails.method}
Browser: ${this.errorDetails.context.browser.name} ${this.errorDetails.context.browser.version}
OS: ${this.errorDetails.context.browser.os}
Device: ${this.errorDetails.context.browser.device}
Location: ${this.errorDetails.context.location?.city}, ${this.errorDetails.context.location?.country}
Page: ${this.errorDetails.page || this.errorDetails.context.page}`
          }]
        }]
      }).toPromise();

      this.anthropicSolution = anthropicResponse?.content[0]?.text || 'No solution available';

      // Send email to support
      const emailData = {
        contactId: crypto.randomUUID(),
        toMail: 'support@kaddatechnologies.com',
        subject: `Error Report: ${this.errorDetails.message.substring(0, 20)}...`,
        message: this.formatEmailBody(),
        senderName: 'Cornerstone Error Reporting',
        senderEmail: 'system@cornerstone.com',
        date: new Date(),
        folder: 'Sent'
      };

      await this.http.post(`${environment.apiUrl}/Mail/SendMail`, emailData).toPromise();

      this.message.success('Error details sent to support successfully');
      this.modal.close();
    } catch (error) {
      this.message.error('Failed to send to support. Please try again.');
      console.error('Error sending to support:', error);
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.modal.close();
  }

  private formatEmailBody(): string {
    const deviceInfo = {
      userAgent: navigator.userAgent,
      platform: navigator.platform,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      timestamp: new Date().toISOString(),
      language: navigator.language,
      cookiesEnabled: navigator.cookieEnabled,
      onLine: navigator.onLine,
      memory: (performance as any).memory?.usedJSHeapSize ?
        `${Math.round((performance as any).memory.usedJSHeapSize / 1048576)}MB used` : 'N/A'
    };

    return `
Error Report
===========

Problem:
${this.errorDetails.message}

Stack Trace:
${this.errorDetails.stackTrace}

HTTP Details:
- Status: ${this.errorDetails.status} ${this.errorDetails.statusText}
- URL: ${this.errorDetails.url}
- Method: ${this.errorDetails.method}

Proposed Solution:
${this.anthropicSolution}

Context Information:
- Page: ${this.errorDetails.page || this.errorDetails.context.page}
- Browser: ${this.errorDetails.context.browser.name} ${this.errorDetails.context.browser.version}
- Operating System: ${this.errorDetails.context.browser.os}
- Device Type: ${this.errorDetails.context.browser.device}
- Screen Resolution: ${this.errorDetails.context.browser.screenResolution}
- Location: ${this.errorDetails.context.location?.city}, ${this.errorDetails.context.location?.region}, ${this.errorDetails.context.location?.country}
- Timezone: ${this.errorDetails.context.location?.timezone}
- IP Address: ${this.errorDetails.context.ip}

System Diagnostics:
- Timestamp: ${deviceInfo.timestamp}
- User Agent: ${deviceInfo.userAgent}
- Platform: ${deviceInfo.platform}
- Screen Resolution: ${deviceInfo.screenResolution}
- Language: ${deviceInfo.language}
- Cookies Enabled: ${deviceInfo.cookiesEnabled}
- Online Status: ${deviceInfo.onLine}
- Memory Usage: ${deviceInfo.memory}

Additional Context:
${JSON.stringify(this.errorDetails.context.metadata, null, 2)}
    `.trim();
  }
}
