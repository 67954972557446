import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService implements OnDestroy {
  private overlayVisible = new BehaviorSubject<boolean>(false);
  private overlayZIndex = new BehaviorSubject<number>(998);
  private showActivityIndicator = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();

  show(zIndex: number = 998, showActivity: boolean = false): void {
    console.log('Showing overlay with z-index:', zIndex);
    this.overlayZIndex.next(zIndex);
    this.overlayVisible.next(true);
    this.showActivityIndicator.next(showActivity);
    document.body.style.overflow = 'hidden';
  }

  hide(): void {
    console.log('Hiding overlay');
    this.overlayVisible.next(false);
    this.showActivityIndicator.next(false);
    document.body.style.overflow = '';
  }

  isVisible(): Observable<boolean> {
    return this.overlayVisible.asObservable();
  }

  getZIndex(): Observable<number> {
    return this.overlayZIndex.asObservable();
  }

  showingActivityIndicator(): Observable<boolean> {
    return this.showActivityIndicator.asObservable();
  }

  reset(): void {
    this.hide();
    this.overlayZIndex.next(1000);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.overlayVisible.complete();
    this.overlayZIndex.complete();
    this.showActivityIndicator.complete();
  }
}
