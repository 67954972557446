import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable, catchError, map, of, tap, Subject, from, throwError } from 'rxjs';
import { TaskModel } from 'src/app/tasks/model/task.model';
import { environment } from 'src/environments/environment';
import { OverlayService } from '../../shared/services/overlay.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { AuthenticatedResponse } from '../models/authenticatedresponse';

const AUTH_API = environment.apiUrl;

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    isLogin = false;
    http: HttpClient;
    private roleAs: string;
    private user: string;
    private jwt: string;
    private refresh: string;
    private staffId: string;
    private staffNumber: string;
    private userName: string;
    private googleToken: any;
    private userImage: any;
    private sessionID: string;
    clientUrl: string = environment.apiUrl + '/Token';
    private logoutSubject = new Subject<void>();
    public logout$ = this.logoutSubject.asObservable();

    constructor(
        private httpClient: HttpClient,
        private overlayService: OverlayService,
        private sanitizer: DomSanitizer,
        private afAuth: AngularFireAuth
    ) {
        this.http = httpClient;
        // Initialize state from localStorage on service creation
        this.isLogin = localStorage.getItem('STATE') === 'true';
        this.roleAs = localStorage.getItem('ROLE') || '';
        this.user = localStorage.getItem('USER') || '';
        this.jwt = localStorage.getItem('JWT') || '';
        this.refresh = localStorage.getItem('RefreshToken') || '';
        this.staffId = localStorage.getItem('StaffId') || '';
        this.staffNumber = localStorage.getItem('StaffNumber') || '';
        this.userName = localStorage.getItem('USERNAME') || '';
        this.userImage = localStorage.getItem('UserImage') || '';
        this.sessionID = localStorage.getItem('SessionID') || '';
    }

    async loginWithFirebase(email: string, password: string): Promise<any> {
        try {
            console.log('Logging in with Firebase', email, password);
            const result = await this.afAuth.signInWithEmailAndPassword(email, password);
            const token = await result.user?.getIdToken();

            // Sync with backend and get user details
            if (result.user?.uid) {
                console.log('Updating login timestamp in backend with firebase uid', result.user.uid);
                const response = await this.httpClient
                    .post<AuthenticatedResponse>(`${AUTH_API}/staff/update-login`, {
                        firebaseUid: result.user.uid,
                        accessToken: token,
                        email: result.user.email
                    })
                    .toPromise();

                // Set up local storage with user details
                if (response) {
                    console.log('Response from backend', response);
                    this.login(
                        response.role || '',
                        JSON.stringify(response),
                        response.accessToken || '',
                        response.refreshToken || '',
                        response.staffId?.toString() || '',
                        response.staffNumber || '',
                        response.vendorName || `${response.firstName} ${response.lastName}`,
                        response.profileImage || '',
                        result.user.uid
                    );
                }
            }

            return result;
        } catch (error) {
            console.error('Firebase login error:', error);
            throw error;
        }
    }

    async resetPasswordWithFirebase(email: string): Promise<void> {
        try {
            await this.afAuth.sendPasswordResetEmail(email);
        } catch (error) {
            console.error('Firebase reset password error:', error);
            throw error;
        }
    }

    async updatePassword(newPassword: string): Promise<void> {
        try {
            const user = await this.afAuth.currentUser;
            if (user) {
                await user.updatePassword(newPassword);
            } else {
                throw new Error('No authenticated user');
            }
        } catch (error) {
            console.error('Firebase update password error:', error);
            throw error;
        }
    }

    async logoutFromFirebase(): Promise<void> {
        try {
            await this.afAuth.signOut();
            this.logout();
        } catch (error) {
            console.error('Firebase logout error:', error);
            throw error;
        }
    }

    login(
        role: string,
        user: string,
        jwt: string,
        refreshToken: string,
        staffId: string,
        staffNumber: string,
        userName: string,
        profileImage: string,
        firebaseUid: string
    ) {
        this.isLogin = true;
        this.roleAs = role?.toUpperCase();
        this.user = user;
        this.jwt = jwt;
        this.refresh = refreshToken;
        this.staffId = staffId;
        this.staffNumber = staffNumber;
        this.userName = userName;
        this.userImage = profileImage;
        // Generate new session ID on login
        this.sessionID = Guid.create().toString();
        localStorage.setItem('STATE', 'true');
        localStorage.setItem('ROLE', this.roleAs);
        localStorage.setItem('USER', this.user);
        localStorage.setItem('JWT', this.jwt);
        localStorage.setItem('RefreshToken', this.refresh);
        localStorage.setItem('StaffId', this.staffId);
        localStorage.setItem('StaffNumber', this.staffNumber);
        localStorage.setItem('USERNAME', this.userName);
        localStorage.setItem('UserImage', this.userImage);
        localStorage.setItem('SessionID', this.sessionID);
        localStorage.setItem('FIREBASE_UID', firebaseUid);
        return of({ success: this.isLogin, role: this.roleAs });
    }

    logout() {
        this.logoutFromFirebase().catch(console.error);
        this.isLogin = false;
        this.roleAs = '';

        // Clear all localStorage items
        localStorage.clear();

        // Reset overlay service
        this.overlayService.reset();

        // Notify all subscribers about logout
        this.logoutSubject.next();

        return of({ success: this.isLogin, role: '' });
    }

    isLoggedIn() {
        const loggedIn = localStorage.getItem('STATE');
        if (loggedIn == 'true') this.isLogin = true;
        else this.isLogin = false;
        return this.isLogin;
    }

    getUserImage() {
        const imageUrl =
            localStorage.getItem('UserImage') ||
            'https://myworkatcornerstone.blob.core.windows.net/imagescontainer/default_avatar.jpg';
        this.userImage = this.sanitizer.bypassSecurityTrustUrl(imageUrl);
        return this.userImage;
    }
    getRole() {
        this.roleAs = localStorage.getItem('ROLE') || '';
        return this.roleAs;
    }

    getUser() {
        this.user = localStorage.getItem('USER') || '';
        return this.user;
    }

    getJwt() {
        this.jwt = localStorage.getItem('JWT') || '';
        return this.jwt;
    }

    getStaffId() {
        this.staffId = localStorage.getItem('StaffId') || '';
        return this.staffId;
    }

    getStaffNumber() {
        this.staffNumber = localStorage.getItem('StaffNumber') || '';
        return this.staffNumber;
    }

    getUsersName() {
        this.userName = localStorage.getItem('USERNAME') || '';
        return this.userName;
    }

    getSessionID() {
        this.sessionID = localStorage.getItem('SessionID') || '';
        return this.sessionID;
    }

    getRefreshToken() {
        this.refresh = localStorage.getItem('RefreshToken') || '';
        return this.refresh;
    }

    setToken(token: string) {
        localStorage.setItem('JWT', token);
        this.jwt = token;
    }

    setRefreshToken(token: string) {
        localStorage.setItem('RefreshToken', token);
        this.refresh = token;
    }

    refreshToken() {
        const tokenApiModel = JSON.stringify({
            accessToken: this.getJwt(),
            refreshToken: this.getRefreshToken(),
        });
        return this.http.post<any>(AUTH_API + '/Token/Refresh', tokenApiModel, httpOptions);
    }

    setUserImage(imageUrl: string) {
        localStorage.setItem('UserImage', imageUrl);
        this.userImage = imageUrl;
    }

    getGoogleToken() {
        // return this.getAccessToken();
    }

    setGoogleToken() {
        return this.http
            .get<any>(this.clientUrl + '/GetAccessToken', {
                observe: 'response',
            })
            .pipe(
                tap((response) => {
                    localStorage.setItem('AccessToken', '');
                    localStorage.setItem('AccessTokenExp', '');
                    localStorage.setItem('IssuedDate', '');
                    console.log('Token Response', response);
                    this.setAccessToken(response['body']?.accessToken);
                    this.setAccessTokenExp(response['body']?.expiresInSeconds);
                    this.setIssuedDate(response['body']?.issued);
                })
            )
            .toPromise();
    }

    isTokenExpired(token: any) {
        if (typeof token === undefined || token === '' || token === null) {
            return true;
        }
        const expiry = +this.getAccessTokenExp() * 1000;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
    }

    private getAccessToken(): any {
        return localStorage.getItem('AccessToken');
    }

    setAccessToken(token: string) {
        localStorage.setItem('AccessToken', token);
    }
    setAccessTokenExp(expiresInSeconds: any) {
        localStorage.setItem('AccessTokenExp', expiresInSeconds);
    }

    private getAccessTokenExp() {
        return localStorage.getItem('AccessTokenExp');
    }
    setIssuedDate(date) {
        localStorage.setItem('IssuedDate', date);
    }
    private getIssuedDate() {
        return new Date(localStorage.getItem('IssuedDate'));
    }

    changePassword(passwordData: { currentPassword: string; newPassword: string }): Observable<any> {
        return this.httpClient.post(`${AUTH_API}/changePassword`, passwordData, httpOptions);
    }

    getFirebaseUid(): string {
        return localStorage.getItem('FIREBASE_UID') || '';
    }

    sendPasswordResetEmail(email: string): Observable<void> {
        return from(this.afAuth.sendPasswordResetEmail(email)).pipe(
            catchError((error) => {
                console.error('Firebase password reset error:', error);
                throw error;
            })
        );
    }
}
