import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alerts/alert.service';
import { AttachmentModel } from './models/attachment.model';
import { UtilityService } from './utility';
import { AttachmentForm } from './models/attachment.form';
import { ImageForm } from './models/image.form.model';
import { ReportsModel } from '../reports/models/report.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class FileAttachmentService {

    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/Referalls';
   attachmentUrl: string = environment.apiUrl + '/Attachment';

    constructor(httpClient: HttpClient, private alertService: AlertService, private utility: UtilityService) {
        this.http = httpClient;
    }

    public uploadImages(formData: any, clientId: any): Observable<any> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.attachmentUrl + `/UploadImages`, formData).pipe(
            catchError((err) => {
                this.handleError<any[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public uploadDocuments(formData: FormData, clientId: string, staffId: string, taskId:string, reportId:string): Observable<any> {
        formData.append("ClientId", clientId)
        formData.append("StaffId", staffId),
        formData.append("ReportId", reportId),
        formData.append("TaskId", taskId)
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.attachmentUrl + `/UploadDocuments`, formData).pipe(
            catchError((err) => {
                this.handleError<any[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    public getAttachment(clientId: any): Observable<any> {
        return this.http.post<FormData>(this.clientUrl + `/GetAttachment?id=${clientId}`, {}).pipe(
            catchError((err) => {
                this.handleError<any[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    GetImagesByStaffId(staffId: any): Observable<any> {
        return this.http.post<AttachmentModel[]>(this.attachmentUrl + `/GetImageByStaffId?id=${staffId}`, {}).pipe(
            catchError((err) => {
                this.handleError<any[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    GetImageByClientId(staffId: any): Observable<any> {
        return this.http.post<AttachmentModel[]>(this.attachmentUrl + `/GetImageByClientId?id=${staffId}`, {}).pipe(
            catchError((err) => {
                this.handleError<any[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    GetImageByReportId(staffId: any): Observable<any> {
        return this.http.post<AttachmentModel[]>(this.attachmentUrl + `/GetImageByReportId?id=${staffId}`, {}).pipe(
            catchError((err) => {
                this.handleError<any[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    getAttachmentsByTaskId(taskId: string) : Observable<any> {
        const url = `${this.attachmentUrl}/GetAttachmentsByTaskId?taskId=${taskId}`;
        return this.http.post<AttachmentForm[]>(url, {}).pipe(
            map((response) => {
                if (response) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),
            catchError(this.handleError<AttachmentForm[]>(`getClient taskId=${taskId}`))
        );
    }

    getAttachmentsByReportId(reportId: string) : Observable<any> {
        const url = `${this.attachmentUrl}/GetAttachmentsByReportId?reportId=${reportId}`;
        return this.http.post<AttachmentForm[]>(url, {}).pipe(
            map((response) => {
                if (response) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),
            catchError(this.handleError<AttachmentForm[]>(`getClient reportId=${reportId}`))
        );
    }

    getAttachmentsByStaffId(staffId: string) : Observable<any> {
        const url = `${this.attachmentUrl}/GetAttachmentsByStaffId?id=${staffId}`;
        return this.http.post<AttachmentForm[]>(url, {}).pipe(
            map((response) => {
                if (response) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),
            catchError(this.handleError<AttachmentForm[]>(`getClient id=${staffId}`))
        );
    }

    getAttachmentsByClientId(clientId: string) : Observable<any> {
        const url = `${this.attachmentUrl}/GetAttachmentsByClientId?id=${clientId}`;
        return this.http.post<AttachmentForm>(url, {}).pipe(
            map((response) => {
                if (response) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),
            catchError(this.handleError<AttachmentForm>(`getClient id=${clientId}`))
        );
    }

    downloadForm(referralForm: ImageForm): Observable<any> {
        return this.http
            .post(this.clientUrl + '/DownloadFile', referralForm, {
                responseType: 'blob',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsModel[]>('DownloadFile', err);
                    throw 'error in Downloading File. Details: ' + err;
                })
            );
    }

    downloadPDF(pdfData: any): Observable<any> {
        const requestOptions: Object = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            responseType: 'Blob',
        };
        return this.http.post(this.clientUrl + '/DownloadPDF', pdfData, requestOptions).pipe(
            catchError((err) => {
                this.handleError<ReportsModel[]>('DownloadFile', err);
                throw 'error in Downloading File. Details: ' + err;
            })
        );
    }

    private log(message: string) {
        this.alertService.error(`AttachmentService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
