import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OverlayService implements OnDestroy {
  private overlayVisible = new BehaviorSubject<boolean>(false);
  private overlayZIndex = new BehaviorSubject<number>(998);
  private showActivityIndicator = new BehaviorSubject<boolean>(false);
  private destroy$ = new Subject<void>();
  private initialized = false;

  constructor() {
    // Initialize subscriptions
    this.setupSubscriptions();
  }

  private setupSubscriptions(): void {
    if (this.initialized) {
      return;
    }

    // Subscribe to overlay visibility changes
    this.overlayVisible
      .pipe(takeUntil(this.destroy$))
      .subscribe(visible => {
        if (visible) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = '';
        }
      });

    this.initialized = true;
  }

  show(zIndex: number = 998, showActivity: boolean = false): void {
    this.overlayZIndex.next(zIndex);
    this.overlayVisible.next(true);
    this.showActivityIndicator.next(showActivity);
  }

  hide(): void {
    this.overlayVisible.next(false);
    this.showActivityIndicator.next(false);
  }

  isVisible(): Observable<boolean> {
    return this.overlayVisible.asObservable();
  }

  getZIndex(): Observable<number> {
    return this.overlayZIndex.asObservable();
  }

  showingActivityIndicator(): Observable<boolean> {
    return this.showActivityIndicator.asObservable();
  }

  reset(): void {
    // Complete and recreate subjects
    this.destroy$.next();
    this.overlayVisible.complete();
    this.overlayZIndex.complete();
    this.showActivityIndicator.complete();

    // Create new subjects
    this.overlayVisible = new BehaviorSubject<boolean>(false);
    this.overlayZIndex = new BehaviorSubject<number>(1000);
    this.showActivityIndicator = new BehaviorSubject<boolean>(false);
    this.destroy$ = new Subject<void>();

    // Reset initialization flag
    this.initialized = false;

    // Setup subscriptions again
    this.setupSubscriptions();
  }

  ngOnDestroy(): void {
    // Complete all subjects
    this.destroy$.next();
    this.destroy$.complete();
    this.overlayVisible.complete();
    this.overlayZIndex.complete();
    this.showActivityIndicator.complete();
  }
}
