import { AuthenticatedResponse } from './authenticatedresponse';

export class User extends AuthenticatedResponse {
    id!: string;
    email!: string;
    password?: string;
    firstName!: string;
    middleName?: string;
    lastName!: string;
    vendorName?: string;
    role!: string;
    staffId?: string;
    staffNumber?: string;
    lastLogin!: string;
    hasImage?: boolean;
    profileImage?: string;
    firebaseUid!: string;

    constructor() {
        super();
    }
}
