import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { User  } from '../models/user';
import { AuthenticatedResponse } from '../models/authenticatedresponse';
import { Login } from '../models/login';

@Injectable({
    providedIn: 'root',
})
export class FirebaseAuthService {
    private readonly AUTH_API = environment.apiUrl;

    constructor(
        private afAuth: AngularFireAuth,
        private http: HttpClient
    ) {}

    // Firebase Authentication
    loginWithFirebase(email: string, password: string): Observable<User> {
        return from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
            switchMap(async (result) => {
                if (!result.user) throw new Error('No user data');

                const token = await result.user.getIdToken();
                const response = await this.http
                    .post<AuthenticatedResponse>(`${this.AUTH_API}/staff/update-login`, {
                        firebaseUid: result.user.uid,
                        refreshToken: result.user.refreshToken,
                        accessToken: token,
                        email: result.user.email
                    })
                    .toPromise();

                return this.mapResponseToUser(response, result.user.uid);
            }),
            catchError((error) => {
                console.error('Firebase login error:', error);
                throw error;
            })
        );
    }

    // SQL Authentication
    loginWithSql(login: Login): Observable<User> {
        return this.http
            .post<AuthenticatedResponse>(`${this.AUTH_API}/auth/login`, {
                email: login.email,
                password: login.password,
                isClientPortal: false,
            })
            .pipe(
                map(response => this.mapResponseToUser(response)),
                catchError((error) => {
                    console.error('SQL login error:', error);
                    throw error;
                })
            );
    }
    // Firebase Operations
    logout(): Observable<void> {
        return from(this.afAuth.signOut());
    }

    getCurrentUser(): Observable<firebase.default.User | null> {
        return this.afAuth.user;
    }

    getIdToken(): Observable<string | null> {
        return this.afAuth.idToken;
    }

    sendPasswordResetEmail(email: string): Observable<void> {
        return from(this.afAuth.sendPasswordResetEmail(email));
    }

    confirmPasswordReset(code: string, newPassword: string): Observable<void> {
        return from(this.afAuth.confirmPasswordReset(code, newPassword));
    }

    verifyPasswordResetCode(code: string): Observable<string> {
        return from(this.afAuth.verifyPasswordResetCode(code));
    }

    updatePassword(newPassword: string): Observable<void> {
        return this.getCurrentUser().pipe(
            switchMap((user) => {
                if (!user) throw new Error('No authenticated user');
                return from(user.updatePassword(newPassword));
            })
        );
    }

    // Helper Methods
    private mapResponseToUser(response: AuthenticatedResponse, firebaseUid: string = ''): User {
        console.log('mapResponseToUser', response, firebaseUid);
        const user = new User();
        user.id = response?.staffId || response?.clientId || '';
        user.email = response?.email || '';
        user.firstName = response?.firstName || '';
        user.lastName = response?.lastName || '';
        user.role = response?.role || '';
        user.accessToken = response?.accessToken || '';
        user.refreshToken = response?.refreshToken || '';
        user.lastLogin = response?.lastLogin || new Date().toISOString();
        user.firebaseUid = firebaseUid;
        user.vendorName = response?.vendorName;
        user.staffId = response?.staffId;
        user.staffNumber = response?.staffNumber;
        user.profileImage = response?.profileImage;
        return user;
    }

    public getErrorMessage(error: any): string {
        if (error.code) {
            switch (error.code) {
                case 'auth/user-not-found':
                    return 'No account found with this email address';
                case 'auth/wrong-password':
                    return 'Invalid password';
                case 'auth/invalid-email':
                    return 'Invalid email address';
                case 'auth/user-disabled':
                    return 'This account has been disabled';
                case 'auth/too-many-requests':
                    return 'Too many failed attempts. Please try again later';
                case 'auth/email-already-in-use':
                    return 'This email address is already registered';
                case 'auth/operation-not-allowed':
                    return 'Email/password accounts are not enabled. Please contact support';
                case 'auth/weak-password':
                    return 'Password should be at least 6 characters';
                case 'auth/invalid-credential':
                    return 'The provided credential is invalid or has expired';
                case 'auth/invalid-verification-code':
                    return 'Invalid verification code';
                case 'auth/invalid-verification-id':
                    return 'Invalid verification ID';
                case 'auth/missing-verification-code':
                    return 'Please provide a verification code';
                case 'auth/missing-verification-id':
                    return 'Missing verification ID';
                case 'auth/network-request-failed':
                    return 'Network error occurred. Please check your internet connection';
                case 'auth/requires-recent-login':
                    return 'This operation requires recent authentication. Please log in again';
                case 'auth/provider-already-linked':
                    return 'Account already linked with another provider';
                case 'auth/credential-already-in-use':
                    return 'This credential is already associated with another account';
                case 'auth/popup-blocked':
                    return 'Authentication popup was blocked by the browser';
                case 'auth/popup-closed-by-user':
                    return 'Authentication popup was closed before completing the sign in';
                case 'auth/unauthorized-domain':
                    return 'This domain is not authorized for OAuth operations';
                case 'auth/invalid-action-code':
                    return 'Invalid action code. Please request a new one';
                case 'auth/expired-action-code':
                    return 'Action code has expired. Please request a new one';
                case 'auth/invalid-persistence-type':
                    return 'Invalid persistence type specified';
                case 'auth/invalid-tenant-id':
                    return 'Invalid tenant ID specified';
                case 'auth/multi-factor-auth-required':
                    return 'Multi-factor authentication is required';
                case 'auth/missing-android-pkg-name':
                    return 'Missing Android package name';
                case 'auth/missing-continue-uri':
                    return 'Missing continue URL';
                case 'auth/missing-ios-bundle-id':
                    return 'Missing iOS bundle ID';
                case 'auth/invalid-continue-uri':
                    return 'Invalid continue URL provided';
                case 'auth/invalid-dynamic-link-domain':
                    return 'Invalid dynamic link domain';
                case 'auth/invalid-oauth-provider':
                    return 'Invalid OAuth provider';
                case 'auth/invalid-oauth-client-id':
                    return 'Invalid OAuth client ID';
                case 'auth/captcha-check-failed':
                    return 'Captcha validation failed';
                case 'auth/invalid-app-credential':
                    return 'Invalid app credential';
                case 'auth/invalid-custom-token':
                    return 'Invalid custom token';
                case 'auth/invalid-phone-number':
                    return 'Invalid phone number format';
                case 'auth/missing-phone-number':
                    return 'Phone number is required';
                case 'auth/quota-exceeded':
                    return 'Quota exceeded. Please try again later';
                case 'auth/rejected-credential':
                    return 'The credential was rejected';
                case 'auth/tenant-id-mismatch':
                    return 'Tenant ID mismatch';
                case 'auth/timeout':
                    return 'Request timeout. Please try again';
                case 'auth/user-token-expired':
                    return 'User token has expired. Please log in again';
                case 'auth/web-storage-unsupported':
                    return 'Web storage is not supported by your browser';
                case 'auth/invalid-api-key':
                    return 'Invalid API key';
                case 'auth/app-deleted':
                    return 'Application has been deleted';
                case 'auth/user-token-mismatch':
                    return 'User token is invalid. Please log in again';
                default:
                    return error.message || 'An error occurred';
            }
        }
        return error.message || 'An error occurred';
    }
}
