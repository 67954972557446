import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs';
import { VendorModel } from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import { AssessmentModalComponent } from '../assessmentmodal.component';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
    selector: 'app-assessment-submit',
    template: `<nz-result
            *ngIf="!error"
            nzStatus="success"
            nzTitle="Report Successfully Submitted!
Report Number: {{ applicationNumber }}."
            nzSubTitle=" Please click the 'Return to Dashboard' button below to return to the main dashboard"
        >
        </nz-result>
        <nz-result
            *ngIf="error"
            nzTitle="Submission Failed"
            nzStatus="error"
            nzSubTitle="Please check and modify the following information before resubmitting."
        >
            <div nz-result-content>
                <div class="desc">
                    <h4 nz-title>The content you submitted has the following error:</h4>
                    <p nz-paragraph>
                        <span nz-icon nzType="close-circle"></span>
                        {{ errorMessage }}
                    </p>
                </div>
            </div>
        </nz-result> `,
    standalone: false
})
export class AssessmentSubmitComponent {
    @Input() applicationNumber: string;
    @Input() error: boolean = false;
    @Input() errorMessage: string;
    @Input() model: VendorModel;
    @Output() assessmentOutput: EventEmitter<any> = new EventEmitter<any>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private service: ReportService) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
    }
    ngInit() {}
    next() {
        this.model = this.data.model;
        this.applicationNumber = this.model['reportNumber'];
        if (this.model?.errorMessage?.message) {
            this.error = true;
            this.errorMessage = this.model?.errorMessage?.message;
        }
    }
}
