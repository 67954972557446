import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/login/service/auth.service';
import { OverlayService } from '../../services/overlay.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  standalone: false,
  animations: [
    trigger('bottomSheet', [
      state('void', style({
        transform: 'translateY(100%)',
        opacity: 0
      })),
      state('visible', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('void => visible', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('visible => void', animate('195ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class BottomSheetComponent implements OnInit, OnDestroy {
  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    if (value) {
      this.overlayService.show(997);
    } else {
      this.overlayService.hide();
    }
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  @Output() closed = new EventEmitter<void>();

  private _isVisible = false;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private overlayService: OverlayService
  ) {
    // Subscribe to logout events
    this.authService.logout$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.close();
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.overlayService.hide();
  }

  close(): void {
    this._isVisible = false;
    this.overlayService.hide();
    this.closed.emit();
  }

  checkRole(requiredRoles: string[]): boolean {
    const currentRole = this.authService.getRole();
    return requiredRoles.includes(currentRole);
  }

  async logout(): Promise<void> {
    try {
      await this.authService.logout();
      this.router.navigate(['/login']);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }
}
