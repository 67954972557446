import { inject } from '@angular/core';
import { HttpInterceptorFn, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { ActivityService } from '../services/activity.service';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../environments/environment';

export const ActivityInterceptor: HttpInterceptorFn = (req, next) => {
  const activityService = inject(ActivityService);
  const router = inject(Router);
  const startTime = Date.now();

  // Don't track activity logging requests to avoid infinite loops
  if (req.url.includes('/api/activity')) {
    return next(req);
  }

  const requestUrl = req.url.replace(environment.apiUrl, '');

  return next(req).pipe(
    tap({
      next: (event) => {
        if (event instanceof HttpResponse) {
          const duration = Date.now() - startTime;

          if (event.headers.get('Content-Disposition')?.includes('attachment')) {
            activityService.logDownload({
              url: requestUrl,
              filename: getFilenameFromResponse(event),
              size: event.headers.get('Content-Length'),
              duration: duration
            });
          }

          if ((req.method === 'POST' || req.method === 'PUT') &&
              (req.body instanceof FormData || typeof req.body === 'object')) {
            activityService.logFormSubmission(requestUrl, true);
          }
        }
      },
      error: (error: HttpErrorResponse) => {
        activityService.logError({
          message: error.message,
          status: error.status,
          url: requestUrl,
          method: req.method,
          duration: Date.now() - startTime
        });

        if ((req.method === 'POST' || req.method === 'PUT') &&
            (req.body instanceof FormData || typeof req.body === 'object')) {
          activityService.logFormSubmission(requestUrl, false);
        }
      }
    })
  );
};

function getFilenameFromResponse(response: HttpResponse<any>): string {
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return 'unknown';
}
