import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-activity-details-modal',
    standalone: true,
    imports: [
        CommonModule,
        NzButtonModule,
        NzIconModule
    ],
    template: `
        <div class="activity-details-modal">
            <div class="modal-content">
                <div class="message-section">
                    <p class="activity-message">{{formattedMessage}}</p>
                </div>

                <div class="details-section">
                    <div class="detail-item">
                        <span class="label">Staff Member:</span>
                        <span class="value">{{data?.staffMember}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Action:</span>
                        <span class="value">{{data?.action}}</span>
                    </div>

                    <div class="detail-item" *ngIf="data?.timeSpent">
                        <span class="label">Time Spent:</span>
                        <span class="value">{{data?.timeSpent}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Page:</span>
                        <span class="value">{{data?.page}}</span>
                    </div>

                    <div class="detail-item" *ngIf="data?.context">
                        <span class="label">Context:</span>
                        <span class="value">{{data?.context}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Browser:</span>
                        <span class="value">{{data?.browserInfo}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Operating System:</span>
                        <span class="value">{{data?.operatingSystem}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Device Type:</span>
                        <span class="value">{{data?.deviceType}}</span>
                    </div>

                    <div class="detail-item">
                        <span class="label">Location:</span>
                        <span class="value">{{data?.locationInfo}}</span>
                    </div>
                </div>
            </div>

            <div class="modal-footer">
                <button nz-button nzType="primary" (click)="close()">Close</button>
            </div>
        </div>
    `,
    styles: [`
        .activity-details-modal {
            padding: 24px;
        }

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
        }

        .modal-header h2 {
            margin: 0;
            color: #2c3e50;
        }

        .message-section {
            margin-bottom: 24px;
            padding: 16px;
            background-color: #f8fafc;
            border-radius: 8px;
        }

        .activity-message {
            margin: 0;
            color: #1f2937;
            font-size: 1.1em;
            line-height: 1.5;
        }

        .details-section {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        .detail-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;
            border-bottom: 1px solid #e5e7eb;
        }

        .label {
            color: #64748b;
            font-weight: 500;
        }

        .value {
            color: #1f2937;
        }

        .modal-footer {
            margin-top: 24px;
            display: flex;
            justify-content: flex-end;
        }
    `]
})
export class ActivityDetailsModalComponent {
    formattedMessage: string = '';

    constructor(
        private modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) public data: any
    ) {
        console.log('modal data', data);
        const cleanPage = this.cleanPagePath(data?.page);
        this.formattedMessage = `${data?.staffMember} visited ${cleanPage} for ${data?.timeSpent} at ${new Date(data?.timestamp).toLocaleString()}`;
    }

    private cleanPagePath(page: string): string {
        if (!page) return 'Unknown Page';

        // Remove query parameters and fragments
        let cleanPath = page.split(/[?#]/)[0];

        // Replace specific patterns with generic names
        cleanPath = cleanPath
            .replace(/\/client\/[^\/]+/, '/Client Details')
            .replace(/\/report\/[^\/]+/, '/Report Details')
            .replace(/\/task\/[^\/]+/, '/Task Details')
            .replace(/\/appointment\/[^\/]+/, '/Appointment Details')
            .replace(/\/document\/[^\/]+/, '/Document Details')
            .replace(/\/(dashboard|home|settings|profile)/, '/$1')
            .replace(/^\//, '');

        // Convert to readable format
        return cleanPath.split('/')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' > ');
    }

    close(): void {
        this.modal.close();
    }
}