import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { catchError, Observable, of, Subject, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { ReportsModel } from 'src/app/reports/models/report.model';
import { ReportsTemplateModel } from '../model/report.template.model';
import { ImageForm } from 'src/app/common/models/image.form.model';
import { ActivityService } from 'src/app/services/activity.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    @Output() IsModelComplete: EventEmitter<any> = new EventEmitter();
    private modelComplete = new Subject<any>();

    private subject = new Subject<any>();
    private changeIndexSubject = new Subject<any>();
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/SituationalAssessment';

    constructor(
        httpClient: HttpClient,
        private messageService: MessageService,
        private activityService: ActivityService
    ) {
        this.http = httpClient;
    }

    setModelComplete(value) {
        this.IsModelComplete.emit(value);
    }

    goToPage(index: string) {
        this.changeIndexSubject.next(index);
    }

    getIndexSubjectEvent(): Observable<any> {
        return this.changeIndexSubject.asObservable();
    }

    sendNextEvent(event: any) {
        this.subject.next(event);
    }

    getNextEvent(): Observable<any> {
        return this.subject.asObservable();
    }

    public sendMessageToAnthropic(apiPayload: any): Observable<any> {
        const url = environment.apiUrl + '/Default/SendMessageToAnthropic';
        return this.http.post<any>(url, apiPayload, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error sending message to Anthropic',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public getAllReports(): Observable<HttpResponse<ReportsModel[]>> {
        const url = this.clientUrl + '/GetAll';
        return this.http.get<ReportsModel[]>(url, {
            observe: 'response',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting all reports',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public addReport(reportModel: ReportsModel) {
        const url = this.clientUrl + '/AddAssessment';
        return this.http.post<any>(url, reportModel, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error adding report',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public updateReport(reportModel: ReportsModel): Observable<ReportsModel[]> {
        const url = this.clientUrl + '/UpdateAssessment?id=' + reportModel.reportId;
        return this.http.post<ReportsModel[]>(url, reportModel, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error updating report',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public deactivateClientReport(model: ReportsModel): Observable<any> {
        const url = this.clientUrl + '/DeactivateClientReport';
        return this.http.post<ReportsModel[]>(url, model, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deactivating client report',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    getReportbyId(id: string): Observable<ReportsModel> {
        const url = `${this.clientUrl}/GetReportById?id=${id}`;
        return this.http.get<ReportsModel>(url).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || `Error getting report by ID: ${id}`,
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public getAllReportsbyClientId(id: string): Observable<any> {
        const url = this.clientUrl + '/GetAllReportsbyClientId?id=' + id;
        return this.http.get<ReportsModel[]>(url).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || `Error getting reports by client ID: ${id}`,
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public getClientReportById(reportTemplateId: string): Observable<ReportsTemplateModel> {
        const url = this.clientUrl + '/GetReportTemplateById?id=' + reportTemplateId;
        return this.http.get<ReportsTemplateModel>(url).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || `Error getting report template by ID: ${reportTemplateId}`,
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public getAllTemplates(): Observable<HttpResponse<ReportsTemplateModel[]>> {
        const url = this.clientUrl + '/GetAllTemplates';
        return this.http.get<ReportsTemplateModel[]>(url, {
            observe: 'response',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting all templates',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    addTemplate(model: ReportsTemplateModel): Observable<ReportsTemplateModel> {
        const url = this.clientUrl + '/AddReportTemplate';
        return this.http.post<ReportsTemplateModel>(url, model, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error adding template',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    saveTemplate(model: ReportsTemplateModel): Observable<any> {
        const url = this.clientUrl + '/SaveReportTemplate';
        return this.http.post<ReportsModel[]>(url, model, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error saving template',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    saveMarkdownDocument(formData: FormData) {
        const url = this.clientUrl + '/SaveMarkdownDocument';
        return this.http.post<any>(url, formData).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error saving markdown document',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public saveTemplateDocument(formData: FormData): Observable<any> {
        const url = this.clientUrl + '/SaveTemplateDocument';
        return this.http.post<any>(url, formData).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error saving template document',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public getTemplateDocument(templateId: any): Observable<ReportsTemplateModel> {
        const url = this.clientUrl + `/GetTemplateDocument?id=${templateId}`;
        return this.http.post<ReportsTemplateModel>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting template document',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public deleteTemplateDocumentById(templateId: any): Observable<ReportsTemplateModel[]> {
        const url = this.clientUrl + `/DeleteReportTemplate?id=${templateId}`;
        return this.http.post<ReportsTemplateModel[]>(url, {}).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deleting template document',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    public updateReportStatusStaffAsync(reportsModel: any): Observable<any> {
        const url = this.clientUrl + '/UpdateReportStatusStaffAsync';
        return this.http.post<ReportsModel[]>(url, reportsModel, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error updating report status',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    downloadForm(templatForm: ImageForm): Observable<any> {
        const url = this.clientUrl + '/DownloadFile';
        return this.http.post(url, templatForm, {
            responseType: 'blob',
        }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error downloading form',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    getCaseTypes(): Observable<any> {
        const url = this.clientUrl + '/GetCaseTypes';
        return this.http.get(url, { observe: 'response' }).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error getting case types',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }

    convertHtmlToSfdt(content: string): Observable<{ sfdt: string; wasConverted: boolean }> {
        const url = `${this.clientUrl}/ConvertHtmlToSfdt`;
        return this.http.post<{ sfdt: string; wasConverted: boolean }>(url, { content }, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error converting HTML to SFDT',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                    page: 'ReportService'
                });
                throw error;
            })
        );
    }
}
