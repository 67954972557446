import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../../login/service/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

let isRefreshing = false;

const skipTokenForUrl = (url: string): boolean => {
    const skipUrls = [
        'auth/login',
        'token/refresh'
    ];
    return skipUrls.some(skipUrl => url.includes(skipUrl));
};

const getTokenWithRetry = async (afAuth: AngularFireAuth, retries = 3): Promise<string | null> => {
    isRefreshing = true;
    try {
        for (let i = 0; i < retries; i++) {
            try {
                const user = await afAuth.currentUser;
                if (user) {
                    const token = await user.getIdToken(true);
                    isRefreshing = false;
                    return token;
                }
                if (i < retries - 1) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                }
            } catch (error) {
                if (i === retries - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
        isRefreshing = false;
        return null;
    } catch (error) {
        console.error('🔴 JWT Interceptor - Error getting token:', error);
        isRefreshing = false;
        return null;
    }
};

export const jwtInterceptor: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn
): Observable<any> => {
    const authService = inject(AuthService);
    const afAuth = inject(AngularFireAuth);

    console.log('🔵 JWT Interceptor - Starting request interception');

    if (skipTokenForUrl(request.url)) {
        return next(request);
    }

    return from(getTokenWithRetry(afAuth)).pipe(
        switchMap(token => {
            if (token) {
                console.log('🔵 JWT Interceptor - Token obtained, adding to request');
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token}`
                    }
                });
            }

            return next(request).pipe(
                tap({
                    error: (error: HttpErrorResponse) => {
                        if (error.status === 401 && !isRefreshing) {
                            console.log('🔴 JWT Interceptor - 401 error, handling session expiry');
                            authService.handleInvalidSession();
                        }
                    }
                })
            );
        })
    );
};