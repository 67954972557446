export const environment = {
    production: false,
    apiUrl:  'https://staffapidev.myworkatcornerstone.com/api', //'https://localhost:7048/api',
    firebase: {
        apiKey: 'AIzaSyDonu29K0mxCWj2JIfZshoh7kuQ_xYZ83U',
        authDomain: 'cornerstonestaffportal.firebaseapp.com',
        projectId: 'cornerstonestaffportal',
        storageBucket: 'cornerstonestaffportal.firebasestorage.app',
        messagingSenderId: '101570043652',
        appId: '1:101570043652:web:fbff389e2b59776ecf7a51',
        measurementId: 'G-0J2K27FMZK',
    },
};
