import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, tap } from 'rxjs';
import { StaffModel } from '../model/staff.model';
import { MessageService } from '../../../../src/app/common/message.service';
import { environment } from '../../../../src/environments/environment';
import { AppointmentModel } from 'src/app/appointment/model/appointment.model';
import { UtilityService } from 'src/app/common/utility';
import { ActivityService } from 'src/app/services/activity.service';
import { AuthService } from 'src/app/login/service/auth.service';
import { DeleteImageRequest } from '../model/delete-image-request.model';
import { ImageUploadRequest } from '../model/image-upload-request.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
interface NotificationSettings {
    emailNotifications: boolean;
    appointmentAlerts: boolean;
    messageNotifications: boolean;
    systemUpdates: boolean;
    themePreference?: 'dark' | 'light';
}
@Injectable({
    providedIn: 'root',
})
export class StaffService {
    http: HttpClient;
    attachmentUrl: string = environment.apiUrl + '/Attachment';
    staffUrl: string = environment.apiUrl + '/Staff';
    staffSettingsUrl: string = environment.apiUrl + '/StaffSettings';
    private currentStaffSubject = new BehaviorSubject<StaffModel | null>(null);
    private settingsSubject = new BehaviorSubject<NotificationSettings | null>(null);

    currentStaff$ = this.currentStaffSubject.asObservable();
    settings$ = this.settingsSubject.asObservable();
    constructor(
        httpClient: HttpClient,
        private messageService: MessageService,
        private utility: UtilityService,
        private activityService: ActivityService,
        private authService: AuthService
    ) {
        this.http = httpClient;
    }

    deleteAvatar(uri: string): Observable<boolean> {
        const url = `${this.attachmentUrl}/DeleteImage`;
        console.log('deleting avatar', uri);
        const request: DeleteImageRequest = { uri };
        return this.http.post<boolean>(url, request, httpOptions).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error deleting avatar',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'POST',
                });
                return of(false);
            })
        );
    }

    getAllStaff(): Observable<HttpResponse<StaffModel[]>> {
        const url = `${this.staffUrl}/GetAll`;
        return this.http.get<StaffModel[]>(url, { observe: 'response' }).pipe(
            catchError((error) => {
                this.handleError('getAllStaff', error);
                return of(new HttpResponse<StaffModel[]>({ body: [] }));
            })
        );
    }

    uploadAvatarAttachment(files: File[], clientId: string, staffId: string): Observable<any> {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file);
        });

        const request: ImageUploadRequest = {
            clientId: clientId,
            staffId: staffId,
        };
        formData.append('request.clientId', request.clientId || '');
        formData.append('request.staffId', request.staffId || '');

        return this.http.post<any>(`${this.attachmentUrl}/UploadImages`, formData).pipe(
            catchError((error) => {
                this.handleError('uploadImages', error);
                return of(null);
            })
        );
    }

    getAvatar(staffId: any): Observable<any> {
        const url = `${this.attachmentUrl}/GetReferralAttachment?id=${staffId}`;
        return this.http.post<FormData>(url, {}).pipe(
            catchError((error) => {
                this.handleError('getAvatar', error);
                return of(null);
            })
        );
    }

    addStaff(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/AddStaff`;
        return this.http.post<StaffModel[]>(url, staff, httpOptions).pipe(
            catchError((error) => {
                this.handleError('addStaff', error);
                return of([]);
            })
        );
    }

    updateStaff(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/UpdateStaff?id=${staff.staffId}`;
        return this.http.post<StaffModel[]>(url, staff, httpOptions).pipe(
            catchError((error) => {
                this.handleError('updateStaff', error);
                return of([]);
            })
        );
    }

    deactivateClient(staff: StaffModel): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/DeactivateStaff?id=${staff.staffId}`;
        return this.http.delete<StaffModel[]>(url, httpOptions).pipe(
            catchError((error) => {
                this.handleError('deactivateClient', error);
                return of([]);
            })
        );
    }
    // Settings related methods
    getSettings(): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/current/${staffId}`;
        return this.http.get<NotificationSettings>(url).pipe(
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error fetching settings',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'GET',
                });
                return of(null);
            })
        );
    }
    updateTheme(theme: string): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/${staffId}/theme`;
        return this.http.put<NotificationSettings>(url, { theme }).pipe(
            tap((settings) => this.settingsSubject.next(settings)),
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error updating theme',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'PUT',
                });
                return of(null);
            })
        );
    }

    updateNotificationSettings(settings: NotificationSettings): Observable<NotificationSettings | null> {
        const staffId = this.authService.getStaffId();
        const url = `${this.staffSettingsUrl}/${staffId}/notifications`;
        return this.http.put<NotificationSettings>(url, settings).pipe(
            tap((updatedSettings) => this.settingsSubject.next(updatedSettings)),
            catchError((error) => {
                this.activityService.logError({
                    message: error.error?.message || error.message || 'Error updating notification settings',
                    stack: error.error?.stackTrace || error.stack,
                    status: error.status,
                    statusText: error.statusText,
                    url,
                    method: 'PUT',
                });
                return of(null);
            })
        );
    }
    getStaffById(id: string): Observable<StaffModel> {
        const url = `${this.staffUrl}/GetStaff?id=${id}`;
        return this.http.get<StaffModel>(url).pipe(
            catchError((error) => {
                this.handleError('getStaffById', error);
                // Return a default StaffModel object to maintain type safety
                return of({
                    staffId: '',
                    staffNumber: '',
                    createdDate: new Date().toISOString(),
                    clients: [],
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobilePhone: '',
                    officePhone: '',
                    staffTitle: '',
                    titlesCertifications: '',
                    titlesName: '',
                    middleName: '',
                    dateofBirth: null,
                    hireDate: null,
                    staffColor: '',
                    profileImage: '',
                    isActive: 'false',
                    staffClientId: '',
                } as StaffModel);
            })
        );
    }

    resetStaffClients(id: string): Observable<StaffModel> {
        const url = `${this.staffUrl}/ResetStaffClients?id=${id}`;
        return this.http.get<StaffModel>(url).pipe(
            catchError((error) => {
                this.handleError('resetStaffClients', error);
                // Return a default StaffModel object to maintain type safety
                return of({
                    staffId: '',
                    staffNumber: '',
                    createdDate: new Date().toISOString(),
                    clients: [],
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    mobilePhone: '',
                    officePhone: '',
                    staffTitle: '',
                    titlesCertifications: '',
                    titlesName: '',
                    middleName: '',
                    dateofBirth: null,
                    hireDate: null,
                    staffColor: '',
                    profileImage: '',
                    isActive: 'false',
                    staffClientId: '',
                } as StaffModel);
            })
        );
    }

    updateMigrationStatus(staffId: string, status: string, firebaseUid?: string): Observable<boolean> {
        const url = `${this.staffUrl}/migration-status`;
        return this.http
            .post<boolean>(url, {
                staffId,
                status,
                firebaseUid,
            })
            .pipe(
                catchError((error) => {
                    this.handleError('updateMigrationStatus', error);
                    return of(false);
                })
            );
    }

    getStaffByMigrationStatus(status: string): Observable<StaffModel[]> {
        const url = `${this.staffUrl}/by-migration-status/${status}`;
        return this.http.get<StaffModel[]>(url).pipe(
            catchError((error) => {
                this.handleError('getStaffByMigrationStatus', error);
                return of([]);
            })
        );
    }

    private log(message: string) {
        this.messageService.add(`StaffService: ${message}`);
    }

    private handleError<T>(operation: string, error: any): void {
        this.activityService.logError({
            message: error.error?.message || error.message || `Error in ${operation}`,
            stack: error.error?.stackTrace || error.stack,
            status: error.status,
            statusText: error.statusText,
            url: error.url,
            method: error.method || 'unknown',
        });

        this.log(`${operation} failed: ${error.message}`);
    }
}
