import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from '../../../../src/app/common/message.service';
import { environment } from '../../../../src/environments/environment';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { TaskModel } from '../model/task.model';
import { Guid } from 'guid-typescript';
import { UtilityService } from 'src/app/common/utility';
import { AttachmentModel } from 'src/app/common/models/attachment.model';
import { id } from 'date-fns/locale';
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class TaskService {

    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/Task';

    constructor(
        httpClient: HttpClient,
        private messageService: MessageService,
        private utility: UtilityService
    ) {
        this.http = httpClient;
    }

    getAllTask(): Observable<HttpResponse<TaskModel[]>> {
        return this.http
            .get<TaskModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    if (response['body']?.length > 0) {
                        let ret = this.utility.toCamelCase(response['body']);
                        return ret;
                    } else {
                        return response;
                    }
                }),
                catchError((err) => {
                    this.handleError<TaskModel[]>('Updloading Form', err);
                    throw 'Error in getAllTasks. Details: ' + err;
                })
            );
    }

    public AddTask(TaskModel: TaskModel): Observable<any> {
        return this.http.post<TaskModel[]>(this.clientUrl + '/AddTask', TaskModel, httpOptions).pipe(
            catchError((err) => {
                this.handleError<TaskModel[]>('Add', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public deleteTask(TaskId: Guid): Observable<any> {
        return this.http.post<TaskModel[]>(this.clientUrl + '/DeleteTask?id=' + TaskId, httpOptions).pipe(
            catchError((err) => {
                this.handleError<TaskModel[]>('Delete', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    public updateTask(TaskModel: TaskModel): Observable<any> {
        return this.http.post<TaskModel[]>(this.clientUrl + '/UpdateTask', TaskModel, httpOptions).pipe(
            map((response) => {
                if (response?.length > 0) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),
            catchError((err) => {
                this.handleError<TaskModel[]>('Update', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public deactivateClient(TaskModel: TaskModel): Observable<TaskModel[]> {
        return this.http
            .delete<TaskModel[]>(`${this.clientUrl} /DeactivateTask?id=${TaskModel.taskId}`, httpOptions)
            .pipe(
                catchError((err) => {
                    this.handleError<TaskModel[]>('Delete', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    getTaskById(id: string): Observable<any> {
        const url = `${this.clientUrl}/GetTask?id=${id}`;
        return this.http.get<TaskModel>(url).pipe(
            map((response) => {
                if (response) {
                    let ret = this.utility.toCamelCase(response);
                    return ret;
                } else {
                    return response;
                }
            }),

            catchError(this.handleError<TaskModel>(`getClient id=${id}`))
        );
    }
    private log(message: string) {
        this.messageService.add(`ClientReferralService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
