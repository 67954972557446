import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertService } from 'src/app/alerts/alert.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtHelper: JwtHelperService,
        private alertService: AlertService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentRole = this.authService.getRole();

        // First check if user is logged in
        if (!this.authService.isLoggedIn()) {
            console.log('Not logged in, redirecting to login');
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        // Then check role-based access
        if (route.data.role && !route.data.role.includes(currentRole)) {
            this.alertService.warn('You do not have permission to access that page');
            this.router.navigate(['/dashboard']);
            return false;
        }

        // Check token validity - let JWT interceptor handle refresh if needed
        const token = this.authService.getJwt();
        if (!token) {
            console.error('No token found, redirecting to login');
            this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        // If token is valid, allow access
        if (!this.jwtHelper.isTokenExpired(token)) {
            return true;
        }

        // If token is expired but we have a refresh token, let the JWT interceptor handle refresh
        const refreshToken = this.authService.getRefreshToken();
        if (refreshToken) {
            // The JWT interceptor will handle the token refresh
            return true;
        }

        // No valid token or refresh token
        console.error('No valid tokens, redirecting to login');
        this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
