import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { filter, catchError, timeout, retry, takeUntil, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../login/service/auth.service';
import { Subject, Observable, of, timer, Subscription, firstValueFrom } from 'rxjs';
import { BrowserDetectionService } from './browser-detection.service';
import { HttpParams } from '@angular/common/http';
import { ApiService } from '../core/services/api.service';
import { IActivity, ActivityAction, ActivityMetadata, BrowserInfo as ActivityBrowserInfo } from '../reports/models/activity.model';

@Injectable({
    providedIn: 'root',
})
export class ActivityService extends ApiService implements OnDestroy {
    private readonly API_PATH = '/activity';
    private readonly IP_STORAGE_KEY = 'user_ip_address';
    private readonly BUFFER_SIZE = 10;
    private readonly DEFAULT_FLUSH_INTERVAL = 30000; // 30 seconds
    private readonly LOGIN_FLUSH_INTERVAL = 300000; // 5 minutes
    private flushInterval: number = 30000;
    private lastPageVisitTime: Date | null = null;
    private currentPage: string | null = null;
    private activityBuffer: IActivity[] = [];
    private errorBuffer: any[] = [];
    private isProcessing: boolean = false;
    private isProcessingErrors: boolean = false;
    private lastFlushTime: number = Date.now();
    private lastErrorFlushTime: number = Date.now();
    private flushTimer: any;
    private errorFlushTimer: any;
    private destroy$ = new Subject<void>();
    private routerSubscription: Subscription;

    constructor(
        http: HttpClient,
        private authService: AuthService,
        private router: Router,
        private browserDetection: BrowserDetectionService
    ) {
        super(http, null as any); // Pass null for activityService since we are the ActivityService
        this.updateFlushInterval();
        this.setupPageTracking();
        this.setupPeriodicFlush();

        // Subscribe to router events to update flush interval
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.updateFlushInterval();
            });

        // Initialize buffer from localStorage if exists
        const savedBuffer = localStorage.getItem('activityBuffer');
        if (savedBuffer) {
            this.activityBuffer = JSON.parse(savedBuffer);
        }

        // Subscribe to auth service logout events
        this.authService.logout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cleanup();
        });

        // Set up periodic flush
        this.flushTimer = setInterval(() => this.checkAndFlush(), this.flushInterval);
        this.errorFlushTimer = setInterval(() => this.checkAndProcessErrors(), this.flushInterval);

        // Handle page unload
        window.addEventListener('beforeunload', () => {
            if (this.activityBuffer.length > 0) {
                const blob = new Blob([JSON.stringify(this.activityBuffer)], {
                    type: 'application/json',
                });
                navigator.sendBeacon(`${environment.apiUrl}/activity/batch`, blob);
            }
        });
    }

    private updateFlushInterval() {
        const newInterval = this.isLoginRelatedPage() ? this.LOGIN_FLUSH_INTERVAL : this.DEFAULT_FLUSH_INTERVAL;

        if (this.flushInterval !== newInterval) {
            this.flushInterval = newInterval;

            // Clear existing timer
            if (this.flushTimer) {
                clearInterval(this.flushTimer);
            }

            // Set up new timer with updated interval
            this.flushTimer = setInterval(() => this.checkAndFlush(), this.flushInterval);
        }
    }

    private setupPageTracking(): void {
        // Clean up existing subscription if it exists
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        // Only track pages if logged in
        if (!this.authService.isLoggedIn()) {
            return;
        }

        this.routerSubscription = this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                if (this.lastPageVisitTime && this.currentPage) {
                    const timeSpentMs = new Date().getTime() - this.lastPageVisitTime.getTime();
                    // Only log if spent more than 5 seconds on the page
                    if (timeSpentMs > 5000) {
                        this.bufferActivity(ActivityAction.PageExit, {
                            page: this.currentPage,
                            timeSpentSeconds: Math.round(timeSpentMs / 1000),
                        });
                    }
                }

                this.currentPage = event.urlAfterRedirects;
                this.lastPageVisitTime = new Date();
            });
    }

    private setupPeriodicFlush() {
        timer(0, this.flushInterval)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.flushBuffer();
            });
    }

    // Helper method to get current page information
    private getCurrentPageInfo(): string {
        return this.currentPage || this.router.url || 'Unknown Page';
    }

    private async fetchAndStoreIpAddress(): Promise<string | null> {
        try {
            const response = await firstValueFrom(
                this.http.get('https://api.ipify.org?format=json').pipe(
                    timeout(5000), // 5 second timeout
                    catchError(() => of(null))
                )
            );

            if (response && response['ip']) {
                localStorage.setItem(this.IP_STORAGE_KEY, response['ip']);
                return response['ip'];
            }
            return null;
        } catch {
            return null;
        }
    }

    private getStoredIpAddress(): string | null {
        return localStorage.getItem(this.IP_STORAGE_KEY);
    }

    private clearStoredIpAddress(): void {
        localStorage.removeItem(this.IP_STORAGE_KEY);
    }

    private async bufferActivity(action: ActivityAction, metadata: Partial<ActivityMetadata> = {}) {
        if (!this.authService.isLoggedIn()) {
            return;
        }

        const userId = this.authService.getStaffId();
        const sessionId = this.authService.getSessionID();
        if (!userId || !sessionId) {
            return;
        }

        const currentPage = this.getCurrentPageInfo();
        const ipAddress = await this.getIpAddress();

        const enrichedMetadata: ActivityMetadata = {
            ...metadata,
            page: metadata.page || currentPage,
            ipAddress,
            routeParams: this.getRouteParams(),
            queryParams: this.getQueryParams()
        };

        const activity: IActivity = {
            activityId: '',
            userId,
            sessionId,
            action,
            timestamp: new Date(),
            metadata: enrichedMetadata,
            pageName: currentPage
        };

        this.activityBuffer.push(activity);
        localStorage.setItem('activityBuffer', JSON.stringify(this.activityBuffer));

        // Check if we should flush
        this.checkAndFlush();
    }

    private async checkAndFlush() {
        const now = Date.now();
        const timeSinceLastFlush = now - this.lastFlushTime;

        // Flush if:
        // 1. Buffer is full OR
        // 2. It's been more than flushInterval since last flush AND we have activities
        if (
            this.activityBuffer.length >= this.BUFFER_SIZE ||
            (timeSinceLastFlush >= this.flushInterval && this.activityBuffer.length > 0)
        ) {
            await this.flushBuffer();
        }
    }

    private async flushBuffer(isUnload: boolean = false) {
        if (this.isProcessing || this.activityBuffer.length === 0) {
            return;
        }

        this.isProcessing = true;
        const activities = [...this.activityBuffer];

        // Transform activities to match backend model
        const backendActivities = activities.map(activity => ({
            logID: 0, // New activities always have logID 0
            userID: activity.userId,
            action: activity.action,
            pageName: activity.pageName || '',
            timestamp: activity.timestamp,
            sessionID: activity.sessionId,
            metadata: typeof activity.metadata === 'string'
                ? activity.metadata
                : JSON.stringify(activity.metadata),
            ipAddress: null // Will be set by the server
        }));

        if (backendActivities.length === 0) {
            console.warn('No valid activities to send');
            this.isProcessing = false;
            return;
        }

        // Clear the buffer before sending
        this.activityBuffer = [];
        localStorage.setItem('activityBuffer', JSON.stringify(this.activityBuffer));

        try {
            if (isUnload) {
                // Use sendBeacon for unload events
                const blob = new Blob([JSON.stringify(backendActivities)], { type: 'application/json' });
                navigator.sendBeacon(`${environment.apiUrl}/activity/batch`, blob);
            } else {
                try {
                    await this.post(`${this.API_PATH}/batch`, backendActivities).toPromise();
                } catch (error: any) {
                    // Check if it's an activity array validation error
                    if (error?.error?.errors?.activities || error?.error?.errors?.['$[0].metadata']) {
                        // Just log to console, don't show alert
                        console.error('Activity validation error:', error);
                        return;
                    }
                    // For other errors, rethrow to be handled by the outer try-catch
                    throw error;
                }
            }
            this.lastFlushTime = Date.now();
        } catch (error) {
            // On error, add activities back to buffer only if not on login-related pages
            if (!this.isLoginRelatedPage()) {
                this.activityBuffer = [...activities];
                localStorage.setItem('activityBuffer', JSON.stringify(this.activityBuffer));
            }
            // Log error but don't show to user
            console.error('Activity flush error:', error);
        } finally {
            this.isProcessing = false;
        }
    }

    private isLoginRelatedPage(): boolean {
        const currentUrl = this.router.url.toLowerCase();
        return currentUrl.includes('/login') ||
               currentUrl.includes('/forgot-password') ||
               currentUrl.includes('/reset-password');
    }

    private async processErrorBuffer() {
        if (this.isProcessingErrors || this.errorBuffer.length === 0) {
            return;
        }

        this.isProcessingErrors = true;
        try {
            // Enhanced error deduplication logic
            const uniqueErrors = this.errorBuffer.reduce((acc, error) => {
                let errorKey = '';

                // Handle complex validation error objects
                if (error?.message?.errors) {
                    // Combine all validation errors into a single string
                    const validationErrors = Object.entries(error.message.errors)
                        .map(([field, msgs]) => `${field}: ${Array.isArray(msgs) ? msgs.join(', ') : msgs}`)
                        .join('; ');
                    errorKey = validationErrors.toLowerCase().trim();
                }
                // Handle case where message is an object with specific error structure
                else if (error?.message?.type && error?.message?.title) {
                    // For standard API validation responses
                    const validationMessages = [];
                    Object.entries(error.message).forEach(([key, value]) => {
                        if (Array.isArray(value)) {
                            validationMessages.push(`${key}: ${value.join(', ')}`);
                        }
                    });
                    errorKey = validationMessages.join('; ').toLowerCase().trim();
                }
                // Fallback to simple message string
                else {
                    errorKey = (error?.message?.toString() || error?.toString() || 'unknown error')
                        .toLowerCase()
                        .trim();
                }

                if (!acc.has(errorKey)) {
                    acc.set(errorKey, error);
                }
                return acc;
            }, new Map());

            const errors = Array.from(uniqueErrors.values());

            // Process each unique error
            for (const error of errors) {
                const staffId = this.authService.getStaffId();
                const sessionID = this.authService.getSessionID();
                if (!staffId || !sessionID) continue;

                const activity = {
                    userID: staffId,
                    sessionID: sessionID,
                    action: 'Error',
                    metadata: JSON.stringify(error),
                    timestamp: new Date(),
                };

                // Only log errors if not on login-related pages
                if (!this.isLoginRelatedPage()) {
                    await this.post(`${this.API_PATH}/log`, activity).toPromise();
                }
            }

            // Clear the buffer after processing
            this.errorBuffer = [];
            this.lastErrorFlushTime = Date.now();
        } catch (err) {
            console.error('Error processing error buffer:', err);
        } finally {
            this.isProcessingErrors = false;
        }
    }

    private checkAndProcessErrors() {
        const now = Date.now();
        const timeSinceLastFlush = now - this.lastErrorFlushTime;

        if (timeSinceLastFlush >= this.flushInterval && this.errorBuffer.length > 0) {
            this.processErrorBuffer();
        }
    }

    private getBrowserInfo(): ActivityBrowserInfo {
        const info = this.browserDetection.getBrowserInfo();
        return {
            name: info.browser || 'Unknown',
            version: info.version || 'Unknown',
            os: info.os || 'Unknown',
            device: info.device || 'Unknown',
            screenResolution: info.screenResolution || 'Unknown'
        };
    }

    // Report Activity Logging
    logReportActivity(action: ActivityAction, reportId: string, metadata: any = {}) {
        const enrichedMetadata: ActivityMetadata = {
            ...metadata,
            reportId,
            page: this.getCurrentPageInfo(),
            browser: this.getBrowserInfo()
        };
        this.bufferActivity(action, enrichedMetadata);
    }

    // Report Lock Activities
    logReportLock(reportId: string, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportLock, reportId, metadata);
    }

    logReportUnlock(reportId: string, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportUnlock, reportId, metadata);
    }

    logReportLockOverride(reportId: string, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportLockOverride, reportId, metadata);
    }

    // Report Draft Activities
    logReportDraftCreate(reportId: string, draftNumber: number, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportDraftCreate, reportId, {
            ...metadata,
            draftNumber
        });
    }

    logReportDraftDelete(reportId: string, draftNumber: number, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportDraftDelete, reportId, {
            ...metadata,
            draftNumber
        });
    }

    logReportDraftExpired(reportId: string, draftNumber: number, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportDraftExpired, reportId, {
            ...metadata,
            draftNumber
        });
    }

    // Report Version Activities
    logReportVersionCreate(reportId: string, versionNumber: number, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportVersionCreate, reportId, {
            ...metadata,
            versionNumber
        });
    }

    logReportVersionRestore(reportId: string, versionNumber: number, metadata: any = {}) {
        this.logReportActivity(ActivityAction.ReportVersionRestore, reportId, {
            ...metadata,
            versionNumber
        });
    }

    async logLogin() {
        const metadata: ActivityMetadata = {
            page: this.getCurrentPageInfo(),
            browser: this.getBrowserInfo()
        };
        await this.bufferActivity(ActivityAction.Login, metadata);
    }

    async logLogout(isAutoLogout: boolean = false, reason?: string) {
        const metadata: ActivityMetadata = {
            page: this.getCurrentPageInfo(),
            lastPageVisitTime: this.lastPageVisitTime,
            action: isAutoLogout ? 'auto_logout' : 'manual_logout',
            reason: reason
        };
        await this.bufferActivity(ActivityAction.Logout, metadata);
        await this.flushBuffer();
        this.cleanup();
    }

    async logFailedLogin(email: string) {
        const metadata: ActivityMetadata = {
            email,
            page: this.getCurrentPageInfo(),
            browser: this.getBrowserInfo()
        };
        await this.bufferActivity(ActivityAction.FailedLogin, metadata);
    }

    // Critical events that should be logged immediately
    async logError(error: any) {
        // Don't log errors on login-related pages
        if (this.isLoginRelatedPage()) {
            console.warn('Error on login page:', error);
            return;
        }

        const browserInfo = this.browserDetection.getBrowserInfo();
        const ipAddress = await this.getIpAddress();
        const errorData: any = {
            message: error.message,
            stack: error.stack,
            status: error.status,
            statusText: error.statusText,
            url: error.url,
            method: error.method,
            ipAddress: ipAddress,
            page: error.page || this.getCurrentPageInfo(),
            browser: error.browser || {
                name: browserInfo.browser,
                version: browserInfo.version,
                os: browserInfo.os,
                device: browserInfo.device,
                screenResolution: browserInfo.screenResolution,
            },
        };

        // Add to error buffer for batch processing
        this.errorBuffer.push(errorData);

        // Check if we should process errors
        this.checkAndProcessErrors();
    }

    // Non-critical events use buffering
    logFormSubmission(formName: string, success: boolean) {
        this.bufferActivity(ActivityAction.FormSubmission, {
            formName,
            success,
            page: this.getCurrentPageInfo(),
            lastPageVisitTime: this.lastPageVisitTime,
        });
    }

    logDownload(fileInfo: any) {
        this.bufferActivity(ActivityAction.Download, {
            ...fileInfo,
            page: this.getCurrentPageInfo(),
        });
    }

    logUpload(fileInfo: any) {
        this.bufferActivity(ActivityAction.Upload, {
            ...fileInfo,
            page: this.getCurrentPageInfo(),
        });
    }

    logSearch(searchTerm: string, resultsCount: number) {
        this.bufferActivity(ActivityAction.Search, {
            searchTerm,
            resultsCount,
            page: this.getCurrentPageInfo(),
        });
    }

    archiveOldLogs(monthsOld: number): Observable<any> {
        const params = new HttpParams().set('monthsOld', monthsOld.toString());
        return this.post(`${this.API_PATH}/archive`, {}, params);
    }

    isAdmin(): boolean {
        return this.authService.getRole()?.toUpperCase() === 'ADMIN';
    }

    getArchivedActivityLogs(params: any): Observable<any> {
        return this.get(`${this.API_PATH}/archive/logs`, new HttpParams({ fromObject: params }));
    }

    exportArchivedActivityLogs(filterParams: any): Observable<Blob> {
        return this.downloadFile(`${this.API_PATH}/archive/export`, new HttpParams({ fromObject: filterParams }));
    }

    getEarliestLogDate(): Observable<{ earliestDate: string | null; hasLogs: boolean }> {
        return this.get(`${this.API_PATH}/earliest-date`);
    }

    ngOnDestroy() {
        this.cleanup();
    }

    private getRouteParams(): { [key: string]: string } {
        const urlTree = this.router.parseUrl(this.router.url);
        const segments = urlTree.root.children['primary']?.segments || [];
        return segments.reduce((acc, segment, index) => {
            if (segment.path.match(/^[0-9a-fA-F-]+$/)) {
                acc[`param${index}`] = segment.path;
            }
            return acc;
        }, {});
    }

    private getQueryParams(): { [key: string]: string } {
        const urlTree = this.router.parseUrl(this.router.url);
        return urlTree.queryParams;
    }

    private async getIpAddress(): Promise<string | null> {
        let ipAddress = localStorage.getItem(this.IP_STORAGE_KEY);
        if (!ipAddress) {
            ipAddress = await this.fetchAndStoreIpAddress();
        }
        return ipAddress;
    }

    cleanup(): void {
        if (this.flushTimer) {
            clearInterval(this.flushTimer);
        }
        if (this.errorFlushTimer) {
            clearInterval(this.errorFlushTimer);
        }
        this.destroy$.next();
        this.destroy$.complete();

        // Clear tracking state
        this.currentPage = null;
        this.lastPageVisitTime = null;
        this.activityBuffer = [];
        this.errorBuffer = [];
        localStorage.removeItem(this.IP_STORAGE_KEY);
        localStorage.removeItem('activityBuffer');
    }
}
