import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef, OnDestroy, ElementRef, NgZone } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationService } from '../services/navigation.service';
import { AuthService } from '../login/service/auth.service';
import { AccountService } from '../login/service/account.service';
import { Observable, Subject, takeUntil, fromEvent, BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter, distinctUntilChanged, map, take } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LayoutService } from '../services/layout.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DeviceService } from '../services/device.service';

@Component({
    selector: 'app-main-navigation',
    templateUrl: './main-navigation.component.html',
    styleUrls: ['./main-navigation.component.scss'],
    animations: [
      trigger('slideInOut', [
            state('in', style({
                transform: 'translateX(0)'
            })),
            state('out', style({
                transform: 'translateX(-100%)'
            })),
            transition('in => out', [
                animate('400ms ease-in-out')
            ]),
            transition('out => in', [
                animate('400ms ease-in-out')
            ])
        ])
    ],
    standalone: false
})
export class MainNavigationComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  @ViewChild('mobileBottomNav') mobileBottomNav!: ElementRef;

  private readonly MOBILE_BREAKPOINT = '(max-width: 1366.98px)';
  isMobile$: Observable<boolean>;
  isOpen$: Observable<boolean>;
  sidenavState = 'out';
  isMoreSheetVisible$ = new BehaviorSubject<boolean>(false);
  isMobileMode = false;
  private destroy$ = new Subject<void>();

  constructor(
    public navigationService: NavigationService,
    private authService: AuthService,
    private accountService: AccountService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService,
    private breakpointObserver: BreakpointObserver,
    private ngZone: NgZone,
    private deviceService: DeviceService
  ) {
    this.isMobile$ = this.deviceService.isMobile$;

    this.isMobile$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isMobile => {
      console.log('Navigation mobile state:', isMobile);
      this.ngZone.run(() => {
        this.updateMobileMode(isMobile);
      });
    });

    this.isOpen$ = this.navigationService.getSidenavState();

    // Subscribe to sidenav state changes
    this.isOpen$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(isOpen => {
      this.ngZone.run(() => {
        if (this.sidenav) {
          if (isOpen) {
            this.sidenav.open();
            this.sidenavState = 'in';
          } else {
            this.sidenav.close();
            this.sidenavState = 'out';
          }
        }
      });
    });

    // Subscribe to logout events
    this.authService.logout$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.closeMoreSheet();
        this.closeSidenav();
      });

    // Subscribe to navigation events
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.checkMobileMode();
    });

    // Listen for window resize events
    fromEvent(window, 'resize')
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.checkMobileMode();
      });
  }

  ngOnInit() {
    // Initial check on component creation
    this.checkMobileMode();
    this.closeSidenav();

    // Handle page visibility changes (for tab switches/page refresh)
    fromEvent(document, 'visibilitychange')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (document.visibilityState === 'visible') {
          this.checkMobileMode();
          this.closeSidenav();
        }
      });
  }

  private checkMobileMode() {
    this.breakpointObserver.observe([this.MOBILE_BREAKPOINT])
      .pipe(
        take(1),
        map(result => result.matches)
      )
      .subscribe(isMobile => {
        if (this.isMobileMode !== isMobile) {
          this.updateMobileMode(isMobile);
        }
      });
  }

  private updateMobileMode(isMobile: boolean) {
    this.ngZone.run(() => {
      this.isMobileMode = isMobile;

      if (isMobile && this.mobileBottomNav) {
        setTimeout(() => {
          const height = this.mobileBottomNav.nativeElement.offsetHeight;
          this.layoutService.updateBottomNavHeight(height);
          this.cdr.detectChanges();
        });
      } else {
        this.layoutService.updateBottomNavHeight(0);
      }
    });
  }

  toggleSidenav() {
    this.navigationService.toggleSidenav();
  }

  closeSidenav() {
    if (this.sidenav?.opened) {
      this.navigationService.toggleSidenav();
      this.sidenavState = 'out';
    }
  }

  hasRole(requiredRoles: string[]): boolean {
    const currentRole = this.authService.getRole();
    return requiredRoles.includes(currentRole);
  }

  async logout() {
    await this.accountService.logout();
  }

  openMoreSheet() {
    this.isMoreSheetVisible$.next(true);
  }

  closeMoreSheet() {
    this.isMoreSheetVisible$.next(false);
  }

  @HostListener('document:keydown.escape')
  onEscapePress() {
    if (this.sidenav?.opened && !this.isMobileMode) {
      this.closeSidenav();
    }
  }

  get sidenavOpened(): boolean {
    return this.sidenav?.opened ?? false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.isMoreSheetVisible$.complete();
  }
}
