import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AlertService } from 'src/app/alerts/alert.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService,
        private jwtHelper: JwtHelperService,
        private alertService: AlertService
    ) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentRole = this.authService.getRole();

        // First check if user is logged in

        // Then check role-based access
        if (route.data.role && !route.data.role.includes(currentRole)) {
            this.alertService.warn('You do not have permission to access that page');
            this.router.navigate(['/dashboard']);
            return false;
        }


            // The JWT interceptor will handle the token refresh
            return true;

    }
}
