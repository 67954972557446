import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { VendorComponent } from '../vendorList/vendorlist.component';
import { NgZorroAntdModule } from '../zorro.module';
import { AngularMaterialModule } from '../material.module';
import {
    GridModule,
    PdfExportService,
    ExcelExportService,
    PageService,
    SortService,
    FilterService,
    GroupService,
    SearchService,
    ToolbarService,
    ResizeService,
    ColumnChooserService,
    SelectionService
} from '@syncfusion/ej2-angular-grids';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [
        VendorComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgZorroAntdModule,
        AngularMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        ButtonModule,
        DialogModule,
        TooltipModule,
        DropDownListModule,
        DatePickerModule,
        NumericTextBoxModule,
        TextBoxModule,
        CheckBoxModule,
        RadioButtonModule,
        SwitchModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule
    ],
    providers: [
        PdfExportService,
        ExcelExportService,
        PageService,
        SortService,
        FilterService,
        GroupService,
        SearchService,
        ToolbarService,
        ResizeService,
        ColumnChooserService,
        SelectionService
    ],
    exports: [
        VendorComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class VendorModule { }
