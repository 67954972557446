import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { AnyKindOfDictionary } from 'lodash';

@Component({
    selector: 'btn-cell-archive-renderer',
    styles: [
        `
            :host ::ng-deep.anticon:hover {
                cursor: pointer !important;
            }
            :host ::ng-deep.anticon.anticon-delete {
                color: red !important;
            }
        `,
    ],
    template: `
        <span
            class="me-4"
            nzTooltipTitle="Edit this row"
            nz-tooltip
            (click)="btnClickedHandler($event, 'Edit')"
            ><i class="fas fa-pencil font-awesome-primary"></i
        ></span>
        <span
            nz-popconfirm
            nzOkText="Yes"
            nzCancelText="No"
            nzTooltipTitle="Delete this row"
            nz-tooltip
            nzPopconfirmTitle="Are you sure you want to delete this row?"
            (nzOnConfirm)="btnClickedHandler($event, 'Archive')"
            nzPopconfirmPlacement="top"
            [nzIcon]="iconTpl"
            ><i class="fas fa-trash-can font-awesome-danger"></i
        ></span>
        <ng-template #iconTpl>
            <span nz-icon nzType="question-circle-o" style="color: red;"></span>
        </ng-template>
    `,
    standalone: false
})
export class BtnCellArchiveRenderer implements ICellRendererAngularComp {
    private params: any;

    refresh(params: any): boolean {
        this.params = params;
        // As we have updated the params we return true to let AG Grid know we have handled the refresh.
        // So AG Grid will not recreate the cell renderer from scratch.
        return true;
    }

    agInit(params: any) {
        this.params = params;
    }

    btnClickedHandler(event: any, mode: any) {
        this.params.clicked(this.params['data'], mode);
    }
}
